/* eslint-disable no-shadow */
import React from 'react';
import { useLocation, useParams, NavLink, useNavigate, createSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MdOutlineWaterDrop, MdOutlineDone, MdOutlineFileUpload } from 'react-icons/md';
import { HiOutlineChip } from 'react-icons/hi';
import '../style/sideBar.scss';

function SideBar() {
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();
  const { chipState } = useSelector(state => state.chips);
  const isfrommycart = new URLSearchParams(window.location.search).get('isfrommycart');

  const navigate = useNavigate();
  return (
    !location.pathname.includes('selected-chips') && (
      <div className="w-100 d-flex justify-content-between align-items-center">
        <div className="page_header wrapper" onClick={() => navigate(-1)}>
          <i className="fa-solid fa-chevron-left gobackheader me-3 text_primary" />
          {location.pathname.includes('logo') ? (
            <h4 className="mb-0 ospt-1 ">Logo</h4>
          ) : location.pathname?.includes('color') ? (
            <h4 className="mb-0 ospt-1 ">Colors</h4>
          ) : location.pathname?.includes('my-chips') ? (
            <h4 className="mb-0 ospt-1 ">My Tags</h4>
          ) : null}
        </div>
        <ul className="wrapper mb-0  list-unstyled justify-content-around chip_edit_sideBar">
          {!location.pathname.includes('selected-chips') &&
            location.pathname.includes('editchip') && (
              <li>
                <div className="d-flex align-items-center">
                  <MdOutlineDone
                    style={{
                      color: location.pathname.includes('selected-chips')
                        ? 'var(--active-color)'
                        : 'var(--chip-breadcrums-color)',
                    }}
                  />
                  <NavLink
                    className="wrapper ms-1 ospt-2"
                    to={{ pathname: '/buy/editchip/selected-chips', state }}
                  >
                    Tag Design
                  </NavLink>
                </div>
              </li>
            )}
          {chipState &&
            location.pathname !== '/buy/editchip/selected-chips' &&
            location.pathname !== '/buy/editchip/final-chip-design' && (
              <div className=" mx-2">
                <i className="fa-solid fa-chevron-right fs12" />
              </div>
            )}
          {chipState && (
            <li className="wrapper">
              {location.pathname !== '/buy/editchip/selected-chips' &&
                location.pathname !== '/buy/editchip/final-chip-design' && (
                  <>
                    <div className="d-flex align-items-center">
                      <MdOutlineFileUpload
                        style={{
                          color: location.pathname.includes('logo')
                            ? 'var(--active-color)'
                            : 'var(--chip-breadcrums-color)',
                        }}
                      />
                      <NavLink
                        className="wrapper ospt-2 ms-1"
                        to={{
                          pathname: `/buy/editchip/logo/${id}`,
                          state,
                          search: isfrommycart
                            ? `?${createSearchParams({
                                isfrommycart: 'true',
                              })}`
                            : '',
                        }}
                      >
                        Upload Logo
                      </NavLink>
                    </div>
                    <div className=" mx-2">
                      <i className="fa-solid fa-chevron-right fs12" />
                    </div>
                  </>
                )}
            </li>
          )}
          {chipState && (
            <li className="wrapper">
              {location.pathname !== '/buy/editchip/selected-chips' &&
                location.pathname !== '/buy/editchip/final-chip-design' && (
                  <>
                    <div className="d-flex align-items-center">
                      <MdOutlineWaterDrop
                        style={{
                          color: location.pathname.includes('color')
                            ? 'var(--active-color)'
                            : 'var(--chip-breadcrums-color)',
                        }}
                      />
                      <NavLink
                        className="wrapper ms-1 ospt-2"
                        to={{
                          pathname: `/buy/editchip/color/${id}`,
                          state,
                          search: isfrommycart
                            ? `?${createSearchParams({
                                isfrommycart: 'true',
                              })}`
                            : '',
                        }}
                      >
                        Product Colors
                      </NavLink>
                    </div>
                    <div className=" mx-2">
                      <i className="fa-solid fa-chevron-right fs12" />
                    </div>
                  </>
                )}
            </li>
          )}
          {chipState && (
            <li className="wrapper">
              {location.pathname !== '/buy/editchip/selected-chips' &&
                location.pathname !== '/buy/editchip/final-chip-design' && (
                  <>
                    {' '}
                    <div className="d-flex align-items-center">
                      <HiOutlineChip
                        style={{
                          color: location.pathname.includes('my-chips')
                            ? 'var(--active-color)'
                            : 'var(--chip-breadcrums-color)',
                        }}
                      />
                      <NavLink
                        className="wrapper ms-1 ospt-2"
                        to={{
                          pathname: `/buy/editchip/my-chips/${id}`,
                          state,
                          search: isfrommycart
                            ? `?${createSearchParams({
                                isfrommycart: 'true',
                              })}`
                            : '',
                        }}
                      >
                        <p>My Tags</p>
                      </NavLink>
                    </div>
                  </>
                )}
            </li>
          )}
        </ul>
      </div>
    )
  );
}

export default React.memo(SideBar);
