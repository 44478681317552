import { Html } from '@react-three/drei';
import React from 'react';
import { Spinner } from 'reactstrap';

const CanvasLoader = () => (
  <Html>
    <Spinner size="sm" />
  </Html>
);

export default CanvasLoader;
