import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLoading: {
    getSubscriptionCharge: false,
    cancelSubscription: false,
    reactiveSubscription: false,
  },
  subscriptionList: null,
  subscriptionCharge: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // // reducer for get subscription charge
    case actionLabels.GET_SUBSCRIPTION_CHARGE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSubscriptionCharge: true,
        },
      };

    case actionLabels.GET_SUBSCRIPTION_CHARGE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSubscriptionCharge: false,
        },
        subscriptionCharge: payload,
      };
    case actionLabels.GET_SUBSCRIPTION_CHARGE_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSubscriptionCharge: false,
        },
        subscriptionCharge: null,
      };

    // // reducer for cancel subscription
    case actionLabels.CANCEL_SUBSCRIPTION:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          cancelSubscription: true,
        },
      };
    case actionLabels.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          cancelSubscription: false,
        },
      };
    case actionLabels.CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          cancelSubscription: false,
        },
      };

    // // reducer for reactive subscription
    case actionLabels.REACTIVE_SUBSCRIPTION:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          reactiveSubscription: true,
        },
      };
    case actionLabels.REACTIVE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          reactiveSubscription: false,
        },
      };
    case actionLabels.REACTIVE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          reactiveSubscription: false,
        },
      };

    default:
      return state;
  }
};
