// // set site view layout action label
export const SET_SITE_VIEW_LAYOUT = 'SET_SITE_VIEW_LAYOUT';

// // add site list action label
export const ADD_SITE_START = 'ADD_SITE_START';
export const ADD_SITE = 'ADD_SITE';
export const ADD_SITE_SUCCESS = 'ADD_SITE_SUCCESS';
export const ADD_SITE_FAIL = 'ADD_SITE_FAIL';

// // delete site action label
export const DELETE_SITE_START = 'DELETE_SITE_START';
export const DELETE_SITE = 'DELETE_SITE';
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';
export const DELETE_SITE_FAIL = 'DELETE_SITE_FAIL';

// // enable disable site action label
export const ENABLE_DISABLE_SITE_START = 'ENABLE_DISABLE_SITE_START';
export const ENABLE_DISABLE_SITE = 'ENABLE_DISABLE_SITE';
export const ENABLE_DISABLE_SITE_SUCCESS = 'ENABLE_DISABLE_SITE_SUCCESS';
export const ENABLE_DISABLE_SITE_FAIL = 'DELETE_SITE_FAIL';
export const VIEW_SITE_DATA = 'VIEW_SITE_DATA'