import { put, takeLatest } from 'redux-saga/effects';
import {
  getTrafficGADataStart,
  getTrafficGADataSuccess,
  getTrafficGADataFail,
  getTrafficLastMonthGADataStart,
  getTrafficLastMonthGADataSuccess,
  getTrafficLastMonthGADataFail,
  getGeographyCountryGAListStart,
  getGeographyCountryGAListSuccess,
  getGeographyCountryGAListFail,
  getRegionGeographyGAListStart,
  getRegionGeographyGAListSuccess,
  getRegionGeographyGAListFail,
  getGaCountryGeographyMapStart,
  getGaCountryGeographyMapSuccess,
  getGaCountryGeographyMapFail,
} from '../../../actions';
import apiHandler from '../../../../utils/apiHandler';
import { handelGAData, handelGAMapData, handleToastfunction } from '../../../../utils/helpers';
import * as actionLabels from '../../../actionLabels';

export function* getTrafficGADataSaga({ payload }) {
  const { propertyId } = payload;
  yield put(getTrafficGADataStart());
  yield apiHandler({
    endpoint: `user/v1/googleAnalytics/getAnalyticsData?propertyId=${propertyId}`,
    successHandler: yield function* (response) {
      yield put(getTrafficGADataSuccess(response?.data?.rows[0]?.metricValues));
    },
    failHandler: yield function* (response) {
      yield put(getTrafficGADataFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    payload: {
      queryData: {
        metrics: [
          {
            name: 'sessions',
          },
          {
            name: 'totalUsers',
          },
          {
            name: 'screenPageViews',
          },
          {
            name: 'bounceRate',
          },
        ],
        dateRanges: [{ startDate: '30daysAgo', endDate: 'yesterday' }],
      },
    },
    apiType: 'post',
  });
}

export function* getTrafficLastMonthGADataSaga({ payload }) {
  const { propertyId } = payload;
  yield put(getTrafficLastMonthGADataStart());
  yield apiHandler({
    endpoint: `user/v1/googleAnalytics/getAnalyticsData?propertyId=${propertyId}`,
    successHandler: yield function* (response) {
      yield put(getTrafficLastMonthGADataSuccess(response?.data?.rows[0]?.metricValues));
    },
    failHandler: yield function* (response) {
      yield put(getTrafficLastMonthGADataFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    payload: {
      queryData: {
        metrics: [
          {
            name: 'sessions',
          },
          {
            name: 'totalUsers',
          },
          {
            name: 'screenPageViews',
          },
          {
            name: 'bounceRate',
          },
        ],
        dateRanges: [{ startDate: '60daysAgo', endDate: '30daysAgo' }],
      },
    },
    apiType: 'post',
  });
}

export function* getCountryGeographyGAListSaga({ payload }) {
  const { offset, limit, searchValue, propertyId } = payload;
  yield put(getGeographyCountryGAListStart());
  yield apiHandler({
    endpoint: `user/v1/googleAnalytics/getAnalyticsData?propertyId=${propertyId}`,
    successHandler: yield function* (response) {
      yield put(getGeographyCountryGAListSuccess(handelGAData(response)));
    },
    failHandler: yield function* (response) {
      yield put(getGeographyCountryGAListFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    payload: {
      queryData: {
        dimensions: [
          {
            name: 'country',
          },
        ],
        metrics: [
          {
            name: 'sessions',
          },
          {
            name: 'totalUsers',
          },
          {
            name: 'screenPageViews',
          },
          {
            name: 'userEngagementDuration',
          },
        ],
        dateRanges: [{ startDate: '30daysAgo', endDate: 'yesterday' }],
        dimensionFilter: {
          filter: {
            fieldName: 'country',
            stringFilter: {
              matchType: 'BEGINS_WITH',
              value: searchValue,
            },
          },
        },
        limit,
        offset: offset - 1,
      },
    },
    apiType: 'post',
  });
}

export function* getRegionGeographyGAListSaga({ payload }) {
  const { selectedCountry, searchValue, offset, limit, propertyId } = payload;
  yield put(getRegionGeographyGAListStart());
  yield apiHandler({
    endpoint: `user/v1/googleAnalytics/getAnalyticsData?propertyId=${propertyId}`,
    successHandler: yield function* (response) {
      yield put(getRegionGeographyGAListSuccess(handelGAData(response)));
    },
    failHandler: yield function* (response) {
      yield put(getRegionGeographyGAListFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    payload: {
      queryData: {
        dimensions: [
          {
            name: 'country',
          },
          {
            name: 'region',
          },
        ],
        metrics: [
          {
            name: 'sessions',
          },
          {
            name: 'totalUsers',
          },
          {
            name: 'screenPageViews',
          },
          {
            name: 'userEngagementDuration',
          },
        ],
        dateRanges: [{ startDate: '30daysAgo', endDate: 'yesterday' }],
        dimensionFilter: {
          andGroup: {
            expressions: [
              {
                filter: {
                  fieldName: 'country',
                  stringFilter: {
                    matchType: 'CONTAINS',
                    value: selectedCountry,
                    caseSensitive: false,
                  },
                },
              },
              {
                filter: {
                  fieldName: 'region',
                  stringFilter: {
                    matchType: 'BEGINS_WITH',
                    value: searchValue,
                    caseSensitive: false,
                  },
                },
              },
            ],
          },
        },
        limit,
        offset: offset - 1,
      },
    },
    apiType: 'post',
  });
}

export function* getGACountryGeographyMapSaga({ payload }) {
  const { filterValue, propertyId } = payload;
  yield put(getGaCountryGeographyMapStart());
  yield apiHandler({
    endpoint: `user/v1/googleAnalytics/getAnalyticsData?propertyId=${propertyId}`,
    successHandler: yield function* (response) {
      yield put(getGaCountryGeographyMapSuccess(handelGAMapData(response)));
    },
    failHandler: yield function* (response) {
      yield put(getGaCountryGeographyMapFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    payload: {
      queryData: {
        dimensions: [
          {
            name: 'country',
          },
          {
            name: 'countryId',
          },
        ],
        metrics: [
          {
            name: filterValue,
          },
        ],
        dateRanges: [{ startDate: '30daysAgo', endDate: 'yesterday' }],
      },
    },
    apiType: 'post',
  });
}

function* watcher() {
  yield takeLatest(actionLabels.GET_TRAFFIC_GA_DATA, getTrafficGADataSaga);
  yield takeLatest(actionLabels.GET_TRAFFIC_LAST_MONTH_GA_DATA, getTrafficLastMonthGADataSaga);
  yield takeLatest(actionLabels.GET_GEOGRAPHY_COUNTRY_GA_LIST, getCountryGeographyGAListSaga);
  yield takeLatest(actionLabels.GET_REGION_GEOGRAPHY_GA_LIST, getRegionGeographyGAListSaga);
  yield takeLatest(actionLabels.GET_GA_GEOGRAPHY_COUNTRY_MAP, getGACountryGeographyMapSaga);
}
export default watcher;
