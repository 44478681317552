const ChipCheckoutSvg = ({ color }) => (
  <svg
    width="18"
    height="16"
    style={{ color }}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <g id="Group_2">
        <g id="Group_3">
          <path
            id="Vector"
            d="M16.6885 3.60824C16.2323 3.50296 15.776 3.78371 15.6708 4.23994L14.1968 10.3639L6.82701 10.3639L4.72136 1.95881C4.63362 1.57278 4.28268 1.30957 3.89664 1.30957L1.51023 1.30957C1.03646 1.30957 0.667969 1.69561 0.667969 2.15183C0.667969 2.60806 1.05401 2.99409 1.51023 2.99409L3.2123 2.99409L5.31796 11.3992C5.40569 11.7852 5.75664 12.0484 6.14267 12.0484L14.846 12.0484C15.2321 12.0484 15.583 11.7852 15.6708 11.3992L17.3026 4.62598C17.4255 4.16975 17.1447 3.71353 16.6885 3.60824Z"
            stroke={color}
            fill="#949494"
          />
          <path
            id="Vector_2"
            d="M7.31765 15.8384C8.15107 15.8384 8.8267 15.1628 8.8267 14.3294C8.8267 13.4959 8.15107 12.8203 7.31765 12.8203C6.48422 12.8203 5.80859 13.4959 5.80859 14.3294C5.80859 15.1628 6.48422 15.8384 7.31765 15.8384Z"
            stroke={color}
            fill="#949494"
          />
          <path
            id="Vector_3"
            d="M13.2512 15.8384C14.0847 15.8384 14.7603 15.1628 14.7603 14.3294C14.7603 13.4959 14.0847 12.8203 13.2512 12.8203C12.4178 12.8203 11.7422 13.4959 11.7422 14.3294C11.7422 15.1628 12.4178 15.8384 13.2512 15.8384Z"
            stroke={color}
            fill="#949494"
          />
          <path
            id="Vector_4"
            d="M8.59947 3.92402L9.47683 3.04666L9.47683 7.31061C9.47683 7.78438 9.86286 8.15287 10.3191 8.15287C10.7929 8.15287 11.1614 7.76684 11.1614 7.31061L11.1614 3.04666L12.0387 3.92401C12.1966 4.08194 12.4247 4.16967 12.6353 4.16967C12.8459 4.16967 13.074 4.08194 13.2319 3.92401C13.5653 3.59062 13.5653 3.04666 13.2319 2.71326L10.9157 0.397042C10.5998 0.0811939 10.0383 0.0811939 9.72249 0.397042L7.38872 2.73081C7.05532 3.06421 7.05532 3.60817 7.38872 3.94156C7.72211 4.25741 8.26608 4.25741 8.59947 3.92402Z"
            stroke={color}
            fill="#949494"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ChipCheckoutSvg;
