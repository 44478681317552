export const GET_SIGNED_LOGO_URL = 'GET_SIGNED_LOGO_URL';
export const GET_SIGNED_LOGO_URL_SUCCESS = 'GET_SIGNED_LOGO_URL_SUCCESS';
export const GET_SIGNED_LOGO_URL_FAIL = 'GET_SIGNED_LOGO_URL_FAIL';

export const UPLOAD_LOGO = 'UPLOAD_LOGO';
export const UPLOAD_LOGO_SUCCESS = 'UPLOAD_LOGO_SUCCESS';
export const UPLOAD_LOGO_FAIL = 'UPLOAD_LOGO_FAIL';

export const GET_CHIP_STATE = 'GET_CHIP_STATE';
export const SET_LOGO_TAB = 'SET_LOGO_TAB';

export const GET_LOGO_PRESIGNED_URL = 'GET_LOGO_PRESIGNED_URL';
export const GET_LOGO_PRESIGNED_URL_SUCCESS = 'GET_LOGO_PRESIGNED_URL_SUCCESS';
export const GET_LOGO_PRESIGNED_URL_FAIL = 'GET_LOGO_PRESIGNED_URL_FAIL';

export const SET_LOGO_URL = 'SET_LOGO_URL';
export const SET_LOGO_URL_SUCCESS = 'SET_LOGO_URL_SUCCESS';
export const SET_LOGO_URL_FAIL = 'SET_LOGO_URL_FAIL';

export const GET_LIST_MYLOGO = 'GET_LIST_MYLOGO';
export const GET_LIST_MYLOGO_SUCCESS = 'GET_LIST_MYLOGO_SUCCESS';
export const GET_LIST_MYLOGO_FAIL = 'GET_LIST_MYLOGO_FAIL';
export const SET_CURRENT_SELECTED_LOGO = 'SET_CURRENT_SELECTED_LOGO';
export const LOGO_DELETE = 'LOGO_DELETE';
export const LOGO_DELETE_SUCCESS = 'LOGO_DELETE_SUCCESS';
export const LOGO_DELETE_FAIL = 'LOGO_DELETE_FAIL';

export const SET_SELECTED_CHIP_COLOR = 'SET_SELECTED_CHIP_COLOR';
export const SET_SELECTED_MODAL = 'SET_SELECTED_MODAL';
export const SET_SELECTED_CHIP_STATE = 'SET_SELECTED_CHIP_STATE';
export const SET_CHIP_ROTATION = 'SET_CHIP_ROTATION';
export const SET_CHIP_DESIGN = 'SET_CHIP_DESIGN';
export const SET_CHIP_DESIGN_SUCCESS = 'SET_CHIP_DESIGN_SUCCESS';
export const SET_CHIP_DESIGN_FAIL = 'SET_CHIP_DESIGN_FAIL';

export const GET_CHIP_DESIGN = 'GET_CHIP_DESIGN';
export const GET_CHIP_DESIGN_SUCCESS = 'GET_CHIP_DESIGN_SUCCESS';
export const GET_CHIP_DESIGN_FAIL = 'GET_CHIP_DESIGN_FAIL';

export const DELETE_CHIP_DESIGN = 'DELETE_CHIP_DESIGN';
export const DELETE_CHIP_DESIGN_SUCCESS = 'DELETE_CHIP_DESIGN_SUCCESS';
export const DELETE_CHIP_DESIGN_FAIL = 'DELETE_CHIP_DESIGN_FAIL';

export const SET_CAMERA_POSITION = 'SET_CAMERA_POSITION';
export const GET_CHIP_ORDER_STATE = 'GET_CHIP_ORDER_STATE';
export const UPDATE_LOGO_NAME = 'UPDATE_LOGO_NAME';
export const UPDATE_LOGO_NAME_FAIL = 'UPDATE_LOGO_NAME_FAIL';
export const UPDATE_LOGO_NAME_SUCCESS = 'UPDATE_LOGO_NAME_SUCCESS';

export const VIEW_CHIPS = 'VIEW_CHIPS';
export const VIEW_CHIPS_SUCCESS = 'VIEW_CHIPS_SUCCESS';
export const VIEW_CHIPS_FAIL = 'VIEW_CHIPS_FAIL';
export const CLEAR_VIEW_CHIP = 'CLEAR_VIEW_CHIP';

export const DONE_CHIP_DESIGN = 'DONE_CHIP_DESIGN';
export const DONE_CHIP_QUANT = 'DONE_CHIP_QUANT';

export const SET_FINGER_ANIMATION = 'SET_FINGER_ANIMATION';
