import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import BuyCrumbs from './BuyCrumbs';
import SiteTitleExperience from './ChipSolutions/SiteTitleExperience';

// Container component for the buy flow
const BuyContainer = () => {
  // Get the current location from React Router
  const loacation = useLocation();

  return (
    <div className="wrapper flex-column mx-3">
      {/* Render the BuyCrumbs component */}
      <BuyCrumbs />

      {/* Render the SiteTitleExperience component if not on the editchip or mycart page */}
      {loacation.pathname.includes('editchip') || loacation.pathname.includes('mycart') ? null : (
        <div className="wrapper w-100 justify-content-start chipnamewrapper">
          <SiteTitleExperience classname="chipsolutiontext" />
        </div>
      )}

      {/* Render the nested routes */}
      <div className="w-100 overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
};

export default BuyContainer;
