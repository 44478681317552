import React from 'react';

export const guestRoutes = [
  {
    path: '/signin',
    name: 'Signin',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/Login')),
  },
  {
    path: 'cms/:id',
    name: 'cms',
    exact: true,
    component: React.lazy(() => import('../../views/user/cms/Cms')),
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    exact: true,
    component: React.lazy(() => import('../../views/user/Support/Contact/ContactUs')),
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/ForgotPassword')),
  },
  {
    path: '/support',
    name: 'Support',
    exact: true,
    component: React.lazy(() => import('../../views/user/Support/Support')),
  },
  {
    path: '/signup',
    name: 'Signup',
    exact: true,
    component: React.lazy(() => import('../../views/auth/register/Signup')),
  },
  {
    path: '/twofactorAuth',
    name: 'TwoFactorAuth',
    exact: true,
    component: React.lazy(() => import('../../views/auth/register/TwoFactorAuth')),
  },
  {
    path: '/otp',
    name: 'OTPVERIFY',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/OtpScreen')),
  },
  {
    path: '/experience',
    name: 'Experience',
    exact: true,
    component: React.lazy(() => import('../../views/user/Experience/Experience')),
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    exact: true,
    component: React.lazy(() => import('../../views/user/Onboarding/Onboarding')),
  },
  {
    redirectRoute: true,
    name: 'Signin',
    path: '/signin',
  },
];

export const userRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
    component: React.lazy(() => import('../../views/user/Dashboard/Dashboard')),
  },

  {
    path: '/experience',
    name: 'Experience',
    exact: true,
    component: React.lazy(() => import('../../views/user/Experience/Experience')),
  },
  {
    path: '/myorders',
    name: 'MyOrders',
    exact: true,
    component: React.lazy(() => import('../../views/user/Orders/MyOrders')),
  },

  {
    path: '/orderdetail/:id',
    name: 'Orderdetail',
    exact: true,
    component: React.lazy(() => import('../../views/user/Orders/OrderDetail/OrderDetail')),
  },
  {
    path: '/dashboard/new-experience',
    name: 'New Experience',
    exact: true,
    component: React.lazy(() => import('../../views/user/newExperience/NewExperience')),
  },
  {
    path: '/manage-payment',
    name: 'ManagePayment',
    exact: true,
    component: React.lazy(() => import('../../views/user/ManagePayment/ManagePayment')),
  },
  {
    path: '/site-management/:id',
    name: 'Site Management',
    exact: true,
    component: React.lazy(() => import('../../views/user/SiteManagement/SiteManagement')),
  },
  {
    path: '/site-management',
    name: 'Site Management',
    exact: true,
    component: React.lazy(() => import('../../views/user/SiteManagement/SiteManagement')),
  },

  {
    path: '/contact-us',
    name: 'ContactUs',
    exact: true,
    component: React.lazy(() => import('../../views/user/Support/Contact/ContactUs')),
  },
  {
    path: '/chip-solutions',
    name: 'Solutions',
    exact: true,
    component: React.lazy(() => import('../../views/user/BuyChips/ChipSolutions/ChipSolutions')),
  },
  {
    path: '/site-management/sites/:id/analytics',
    name: 'Site Analytics',
    exact: true,
    component: React.lazy(() =>
      import(
        '../../views/user/SiteManagement/SiteManagementComponents/SiteSpecificComponents/Analytics/Analytics'
      ),
    ),
  },
  {
    path: '/viewchip/:sitename/:id',
    name: 'View Chip Management',
    exact: true,
    component: React.lazy(() => import('../../views/user/ViewChip/ViewChips')),
  },
  {
    path: '/profile',
    name: 'Profile',
    exact: true,
    component: React.lazy(() => import('../../views/user/Profile/Profile')),
  },
  {
    path: '/edit',
    name: 'Editprofile',
    exact: true,
    component: React.lazy(() => import('../../views/user/Profile/EditProfile/EditProfile')),
  },
  {
    path: '/notification',
    name: 'Notification',
    exact: true,
    component: React.lazy(() => import('../../views/user/Notification/Notification')),
  },
  {
    path: '/support',
    name: 'Support',
    exact: true,
    component: React.lazy(() => import('../../views/user/Support/Support')),
  },

  {
    path: '/visitor-management',
    name: 'Visitor Management',
    exact: true,
    component: React.lazy(() => import('../../views/user/VisitorManageMent/VisitorManageMent')),
  },
  {
    path: '/subscription',
    name: 'Subscription',
    exact: true,
    component: React.lazy(() => import('../../views/user/Subscriptions/Subscriptions')),
  },
  {
    path: '/microsites',
    name: 'Microsites',
    exact: true,
    component: React.lazy(() => import('../../views/user/Microsites/Microsites')),
  },

  {
    path: 'cms/:id',
    name: 'cms',
    exact: true,
    component: React.lazy(() => import('../../views/user/cms/Cms')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/dashboard',
  },
];
export const editchipsroutes = [
  {
    path: '/buy/editchip',
    name: 'Chips-Management',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/chipsManagement/mainContainer/EditContainer'),
    ),
  },
  {
    path: 'selected-chips',
    name: 'ChipsEditSelectedChips',
    exact: true,
    component: React.lazy(() => import('../../views/user/chipsManagement/chipEdit/SelectChips')),
  },
  {
    path: 'logo/:id',
    name: 'ChipsLogoEdit',
    exact: true,
    component: React.lazy(() => import('../../views/user/chipsManagement/chipEdit/LogoManagement')),
  },
  {
    path: 'color/:id',
    name: 'ChipsLogoColorUpdate',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/chipsManagement/chipEdit/ColorManagement'),
    ),
  },
  {
    path: 'my-chips/:id',
    name: 'MyChips',
    exact: true,
    component: React.lazy(() => import('../../views/user/chipsManagement/chipEdit/MyChips')),
  },
  {
    redirectRoute: true,
    name: 'Chips-Management',
    path: '/dashboard',
  },
];
export const buychipsroutes = [
  {
    path: '/buy',
    name: 'BuyChips',
    exact: true,
    component: React.lazy(() => import('../../views/user/BuyChips/BuyContainer')),
  },
  {
    path: 'chipstyle',
    name: 'BuychipsStyle',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/BuyChips/ChipSolutions/SelectChipPackages'),
    ),
  },
  {
    path: 'editchip',
    name: 'ChipsLogoEdit',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/chipsManagement/mainContainer/EditContainer'),
    ),
  },
  {
    path: 'chippackage',
    name: 'BuyChipsPackages',
    exact: true,
    component: React.lazy(() => import('../../views/user/BuyChips/ChipsPackages/ChipsManagement')),
  },
  {
    path: 'mycart',
    name: 'cart',
    exact: true,
    component: React.lazy(() => import('../../views/user/BuyChips/MyCart/MyCart')),
  },
  {
    redirectRoute: true,
    name: 'Chips-Management',
    path: '/dashboard',
  },
];
