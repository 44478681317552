import { combineReducers } from 'redux';
import auth from './auth/auth';
import modal from './modal/modal';
import promocode from './promocodes/index';
import payment from './payment';
import siteReducers from './siteManagement/site';
import siteSpecificAnalysisReducer from './siteManagement/siteSpecificAnalysis';
import siteOverallAnalysisReducer from './siteManagement/overallAnalysis';
import chips from './chips/index';
import visitorsReducers from './visitorManagement';
import subscriptionReducers from './subscription/subscription';
import cmsReducers from './cms';
import camera from './camera/index';
import ordersReducers from './order/index';

const allReducers = combineReducers({
  auth,
  modal,
  promocode,
  subscriptionReducers,
  camera,
  payment,
  siteReducers,
  siteSpecificAnalysisReducer,
  siteOverallAnalysisReducer,
  chips,
  visitorsReducers,
  cmsReducers,
  ordersReducers,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
