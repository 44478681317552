/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { GlobalLogout, ChangeThemeDark, ChangeThemeLight } from 'utils/helpers';
import useUserAccountContext from 'Context/UserAccountContext/useUserAccountContext';
import useModalContext from 'Context/ModalContext/useModalContext';
import { USERPROFILEDUMMY, MOON, SUN } from 'assets/images';
import { REACT_APP_SLLABSIMAGEURL_STATIC } from 'utils/envConfig';

import './prfilemenu.css';

const ProfileMenu = () => {
  const { openModal } = useModalContext();
  const [Notificationdropdown, setNotification] = useState(false);
  const { Theme } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { UserData } = useUserAccountContext();
  const [Menu, setMenu] = useState(false);
  const userDetails = useSelector(
    state => state?.auth?.userData?.userSubscriptionData?.userDetails,
  );
  const handleLogout = async () => {
    GlobalLogout(Theme);
  };
  const handleSwitch = () => {
    if (Theme === 'light') {
      ChangeThemeDark(dispatch);
    } else {
      ChangeThemeLight(dispatch);
    }
  };
  const handleLogoutClick = () => {
    openModal({
      title: 'Logout',
      open: true,
      message: 'Are you sure you want to logout?',
      handleClick: handleLogout,
      btntext: 'Logout',
      showPrimaryButton: true,
    });
  };
  return (
    <>
      <Dropdown
        isOpen={Notificationdropdown}
        toggle={() => {
          setNotification(!Notificationdropdown);
        }}
        className="d-inline-block d-none"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="notification-wrapper">
            <i className="fa-regular fa-bell" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="wrapper justify-content-between px-2 notification-wrapper-inner">
            <div className="wrapper">
              <Link to="/notification">
                <Button className="p-1" onClick={() => setNotification(false)}>
                  View All
                </Button>
              </Link>
            </div>
            <div className="wrapper">
              <Button className="p-1">Mark all as read</Button>
            </div>
          </div>
          <div className="notifications-view mx-2">
            <div className="wrapper justify-content-start my-2">
              <p className="mb-0">New Report Added By Admin {new Date().toLocaleDateString()}</p>
            </div>
            <div className="notification-divider" />
            <div className="wrapper justify-content-start my-2">
              <p className="mb-0">New Report Added By Admin {new Date().toLocaleDateString()}</p>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
      <div className="wrapper">
        <div className="pointer themetoggle" onClick={() => handleSwitch()}>
          <img
            src={
              Theme === 'dark'
                ? `${REACT_APP_SLLABSIMAGEURL_STATIC}${SUN}`
                : `${REACT_APP_SLLABSIMAGEURL_STATIC}${MOON}`
            }
            alt="darkimg"
            className="cp"
          />
        </div>
        <Dropdown
          isOpen={Menu}
          toggle={() => {
            setMenu(!Menu);
          }}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item pe-0 waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            <div className="wrapper ">
              <div className="header-profile-user-container d-flex justify-content-center align-items-center">
                <img
                  className="rounded-circle header-profile-user"
                  src={
                    userDetails?.logoUrl ||
                    `${REACT_APP_SLLABSIMAGEURL_STATIC}${USERPROFILEDUMMY}`
                  }
                  alt="Header Avatar"
                />
              </div>
              {UserData && (
                <p className="topbarname ospt-1">{`${UserData?.firstName} ${UserData?.lastName}`}</p>
              )}
              <div>
                <i className="uil-angle-down d-none d-xl-inline-block font-size-25 text_primary" />
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-end second_primary_background"
            id="profile_dropdown_menu"
          >
            <Link to="/profile">
              <DropdownItem
                tag="span"
                className="text_primary profile_dropdown_menu_item wrapper justify-content-start"
              >
                <i className="uil uil-user-circle font-size-18 align-middle me-2" />
                <p className="">Profile</p>
              </DropdownItem>
            </Link>
            <Link to="/support">
              <DropdownItem
                tag="span"
                className="cp text_primary profile_dropdown_menu_item wrapper justify-content-start"
              >
                <i className="uil uil-comment-alt-question font-size-18 align-middle me-2" />
                <p className="">Support</p>
              </DropdownItem>
            </Link>
            <Link to="/notification" className="d-none">
              <DropdownItem
                tag="span"
                className="cp text_primary profile_dropdown_menu_item wrapper justify-content-start"
              >
                <i className="uil uil-bell font-size-18 align-middle me-2" />
                <p className="">Notification</p>
              </DropdownItem>
            </Link>
            <button
              type="button"
              onClick={handleLogoutClick}
              className="dropdown-item text_primary profile_dropdown_menu_item wrapper justify-content-start"
            >
              <i className="uil uil-sign-out-alt font-size-18 align-middle me-2" />
              <p className="">Logout</p>
            </button>
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
};

export default ProfileMenu;
