import { CAMZ, CAMY, CAMX } from 'utils/envConfig';
import * as actionLabels from '../../actionLabels';

export const initialState = {
    cameraposition: { x: CAMX, y: CAMY, z: CAMZ },
    rotation: { x: 0, y: 0, z: 0 },
  };
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      
      case actionLabels.SET_CAMERA_POSITION:
        return { ...state, cameraposition: payload };
      
      default:
        return state;
    }
  };
  