/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import useUserAccountContext from 'Context/UserAccountContext/useUserAccountContext';
import { REACT_APP_SLLABSIMAGEURL_STATIC } from 'utils/envConfig';
import { dataItems } from '../../../statics/sidebardata';
import { EDIT_ICON, USERPROFILEDUMMY } from '../../../assets/images';
import './style.scss';

function ResponsiveSidebar({ openMobileSidebar, setOpenMobileSidebar }) {
  const outsideRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { UserData } = useUserAccountContext();
  const { isPaymentUnderProcess } = useSelector(state => state.payment);
  const { Theme } = useSelector(state => state.auth);

  // Function to handle click outside sidebar to close it
  const handelToggle = e => {
    if (!outsideRef?.current?.contains(e?.target)) {
      setOpenMobileSidebar(false);
    }
  };

  // Effect to add click event listener for closing sidebar
  useEffect(() => {
    document.addEventListener('click', handelToggle, true);
  }, []);

  // Function to determine if a route is active
  const isRouteActive = useCallback(
    values => {
      let isactive = '';
      for (let i = 0; i < values.length; i++) {
        const element = values[i];
        if (pathname.includes(element)) {
          isactive = 'activesidebar';
          break;
        }
      }
      return isactive;
    },
    [pathname],
  );

  return (
    <div
      className={`${
        isPaymentUnderProcess ? 'ispaymentprocess' : ''
      } d-lg-none responsive_sidebar_hero`}
      ref={outsideRef}
      style={{ left: openMobileSidebar ? 0 : '-100%' }}
    >
      {/* Top bar with user profile */}
      <div className="top_bar">
        <div className="profile_pic_container">
          <img
            src={UserData?.userProfile || `${REACT_APP_SLLABSIMAGEURL_STATIC}${USERPROFILEDUMMY}`}
            alt="profile pic"
            className="img-fluid profile_pic"
          />
          {/* Edit icon for profile */}
          <img
            src={`${REACT_APP_SLLABSIMAGEURL_STATIC}${EDIT_ICON}`}
            alt="edit icon"
            className="img-fluid edit_icon"
            onClick={() => {
              navigate('/edit');
              setOpenMobileSidebar(false);
            }}
          />
        </div>
        {/* User information */}
        <div className="me-3">
          <h5 className="font-medium text-black">
            {UserData && `${UserData?.firstName} ${UserData?.lastName}`}
          </h5>
          <h6 className="text-black text-break">{UserData && UserData?.email}</h6>
          <h6 className="text-black">{UserData && UserData?.countryCode + UserData?.phone}</h6>
        </div>
      </div>
      {/* Sidebar navigation links */}
      <Row>
        <Col>
          <ul className="">
            {/* Mapping through sidebar data */}
            {dataItems.map(item => (
              <li
                className={`${isRouteActive(
                  item.otherActiveRoutes ? [...item?.otherActiveRoutes, item?.link] : [item.link],
                )}`}
                key={item.name}
                onClick={() => setOpenMobileSidebar(false)}
              >
                <Link
                  to={item.link}
                  className="color-black ressidebaranchor justify-content-start wrapper"
                >
                  {/* Render icon or image based on type */}
                  {item.type !== 'image' ? (
                    <i className={`${item.logo} `} />
                  ) : (
                    <div className="me-2 ressidebarimage wrapper">
                      {Theme === 'dark' ? item.logo : item.logowhite}
                    </div>
                  )}
                  <span className="ospt-3">{item.name}</span>
                </Link>
              </li>
            ))}
            {/* Logout link */}
            <li>
              <span
                className="cp ressidebaranchor color-black justify-content-start wrapper"
                onClick={() => {
                  localStorage.clear();
                  sessionStorage.clear();
                  window.location.reload();
                }}
              >
                <FiLogOut className="me-2" />
                Logout
              </span>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default ResponsiveSidebar;
