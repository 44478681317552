import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLogin: false,
  Theme: localStorage.getItem('theme')
    ? localStorage.getItem('theme') === 'null'
      ? 'dark'
      : localStorage.getItem('theme')
    : 'dark',
  isLoading: false,
  userData: null,
  authToken: '',
  errorMsg: '',
  tempUserforotpverification: null,
  isEmailverified: false,
  authenticatorErrmsg: '',
  fcmToken: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.LOGIN_START:
      return { ...state, isLoading: true, isLogin: false };
    case actionLabels.SET_USER_DATA_SUCCESS:
      return { ...state, userData: payload };
    case actionLabels.UPDATE_USER_SUB:
      return {
        ...state,
        userData: {
          ...state.userData,
          userSubscriptionData: payload?.data?.data,
          paymentMethod: payload?.data?.paymentMethod,
        },
      };
    case actionLabels.FORGOT_AUTHENTICATOR:
      return { ...state, isLoading: true };
    case actionLabels.SET_THEME:
      return { ...state, Theme: payload };
    case actionLabels.RESET_AUTHENTICATOR:
      return { ...state, isLoading: true };
    case actionLabels.FORGOT_AUTHENTICATOR_FAIL:
      return { ...state, isLoading: false, authenticatorErrmsg: payload };
    case actionLabels.RESET_AUTHENTICATOR_FAIL:
      return { ...state, isLoading: false, authenticatorErrmsg: payload };
    case actionLabels.FORGOT_AUTHENTICATOR_SUCCESS:
      return { ...state, isLoading: false, isEmailverified: true };
    case actionLabels.RESETAUTHENTICAR_VALUE:
      return { ...state, isEmailverified: false };
    case actionLabels.RESET_AUTHENTICATOR_SUCCESS:
      return { ...state, isLoading: false };
    case actionLabels.SET_USER_OTP_VERIFY: {
      return {
        ...state,
        tempUserforotpverification: payload,
      };
    }
    case actionLabels.LOGIN_SUCCESS: {
      return {
        ...state,
        isLogin: true,
        isLoading: false,
        authToken: payload.cognitoAuthToken,
        // fcmToken: payload.deviceToken,
        userData: payload,
        errorMsg: '',
      };
    }
    case actionLabels.LOGIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }
    case actionLabels.CLEAR_AUTH: {
      return { ...state, isLogin: false, errorMsg: '' };
    }
    case actionLabels.OTP_VERIFY_SUCCESS: {
      return { ...state, authToken: 'dwhejbhrbjhfrbhjrf' };
    }
    default:
      return state;
  }
};
