import { REACT_APP_SLLABSIMAGEURL_STATIC } from 'utils/envConfig';

export const ICON_INSTAGRAM = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_INSTAGRAM.svg`
export const ICON_YOUTUBE = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_YOUTUBE.svg`
export const ICON_TWITTER = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_TWITTER.svg`
export const ICON_SUCCESS = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_SUCCESS.svg`
export const ICON_SUCCESSTOAST = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_SUCCESSTOAST.svg`
export const ICON_ERRORTOAST = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_ERRORTOAST.svg`
export const ICON_INFOTOAST = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_INFOTOAST.svg`
export const ICON_CROSS = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_CROSS.svg`
export const ICON_CAMERA = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_CAMERA.png`

