import {
  APPLY_PROMO_CODE_FAIL,
  APPLY_PROMO_CODE_SUCCESS,
  APPLY_PROMO_CODE_START,
  GET_ARTICLES,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAIL,
  REMOVE_PROMOCODE,
  SET_PROMOCODE_VALUE,
  SET_ISMANUAL_PAYMENT,
} from '../../actionLabels';

export const applyPromoCode = payload => ({
  type: APPLY_PROMO_CODE_START,
  payload,
});

export const applyPromoCodeSuccess = payload => ({
  type: APPLY_PROMO_CODE_SUCCESS,
  payload,
});
export const setPromocodeValue = payload => ({
  type: SET_PROMOCODE_VALUE,
  payload,
});

export const setIsManualPayment = payload => ({
  type: SET_ISMANUAL_PAYMENT,
  payload,
});

export const removePromocode = payload => ({
  type: REMOVE_PROMOCODE,
  payload,
});
export const applyPromoCodeFail = payload => ({
  type: APPLY_PROMO_CODE_FAIL,
  payload,
});
export const getArticles = payload => ({
  type: GET_ARTICLES,
  payload,
});

export const getArticlessuccess = payload => ({
  type: GET_ARTICLES_SUCCESS,
  payload,
});
export const getArticlesfail = payload => ({
  type: GET_ARTICLES_FAIL,
  payload,
});
