/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-await */
import React, { useState } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import {
  createUserAction,
  otpSignupRequest,
  verifyotpSignupRequest,
  LoginUserAction,
  setMFArequestOtp,
  setVerify2faOtpAction,
  setMFArequest,
  resetPasswordAction,
  changePasswordAction,
  verifyforgotPasswordOtp,
  availabilityCheckpreSignup,
} from 'apiservices/api';
import * as actions from 'store/actions';
import { handleToastfunction } from 'utils/helpers';
import { auth } from 'utils/envConfig';
import UserAccountContext from './UserAccountContext';

const UserAccountState = ({ children }) => {
  const [UserData, setUserSuccess] = useState({});
  const dispatch = useDispatch();
  const { mutate: changePasswordmutation } = useMutation({
    mutationFn: val => changePasswordAction(val),
  });

  const { mutate: createuserMutation } = useMutation({
    mutationFn: val => createUserAction(val),
  });
  const { mutate: sendOtpRequestmutation } = useMutation({
    mutationFn: val => otpSignupRequest(val),
  });
  const { mutate: checkAvailability } = useMutation({
    mutationFn: val => availabilityCheckpreSignup(val),
  });
  const { mutate: verifyOtpRequestmutation } = useMutation({
    mutationFn: val => verifyotpSignupRequest(val),
  });
  const { mutate: loginMutation } = useMutation({
    mutationFn: val => LoginUserAction(val),
  });
  const { mutate: setMFAenabledrequest } = useMutation({
    mutationFn: val => setMFArequest(val),
  });
  const { mutate: setMFAenabledOtpmutation } = useMutation({
    mutationFn: val => setMFArequestOtp(val),
  });
  const { mutate: setVerify2faOtpmutation } = useMutation({
    mutationFn: val => setVerify2faOtpAction(val),
  });
  const { mutate: verifyforgotPasswordOtpmutation } = useMutation({
    mutationFn: val => verifyforgotPasswordOtp(val),
  });
  const { mutate: resetPasswordmutation } = useMutation({
    mutationFn: val => resetPasswordAction(val),
  });
  // const getSession = async () =>
  //   await new Promise((resolve, reject) => {
  //     Auth.currentAuthenticatedUser()
  //       .then(current => {
  //         Auth.currentSession()
  //           .then(session => {
  //             resolve({ session, user: current });
  //           })
  //           .catch(err => {
  //             reject(err);
  //           });
  //       })
  //       .catch(err => {
  //         console.log(err);
  //         reject(err);
  //       });
  //   });

  const authenticate = async payload =>
    await new Promise((resolve, reject) => {
      loginMutation(payload, {
        onSuccess(data) {
          localStorage.setItem('sessionidentifier', data?.data?.data?.items[0]?.sessionIdentifier);
          resolve(data);
        },
        onError(err) {
          reject(err);
        },
      });
    });

  const createUserSignup = async (values, isgoogle) =>
    new Promise((resolve, reject) => {
      const {
        emailId,
        isNewsLetterSubscribed,
        phoneNumber,
        password,
        firstName,
        lastName,
        country,
        brandName,
        countryCode,
        googletoken,
      } = values;
      const usergoogle = isgoogle && JSON.parse(localStorage.getItem('usergoogleid'));
      createuserMutation(
        isgoogle
          ? {
              isgoogle: true,
              email: usergoogle?._tokenResponse?.email,
              phone: phoneNumber,
              countryCode: countryCode ? `+${countryCode}` : undefined,
              accountType: 'USER',
              firstName: usergoogle?._tokenResponse?.firstName,
              lastName: usergoogle?._tokenResponse?.lastName,
              brandName,
              country,
              isNewsLetterSubscribed,
              accessToken: googletoken,
              provider: 'google',
              refreshToken: usergoogle?._tokenResponse?.refreshToken,
            }
          : {
              email: emailId,
              phone: phoneNumber,
              countryCode: `+${countryCode}`,
              isNewsLetterSubscribed,
              accountType: 'USER',
              brandName,
              country,
              password,
              firstName,
              lastName,
              middleName: '',
            },
        {
          onSuccess: data => {
            resolve(data);
          },
          onError: err => {
            reject(err);
          },
        },
      );
    });
  const confirmOtp = async (otpid, otp) =>
    await new Promise((resolve, reject) => {
      verifyOtpRequestmutation(
        {
          _codeVerification: otpid,
          code: otp,
        },
        {
          onSuccess(data) {
            resolve(data);
          },
          onError(err) {
            reject(err);
          },
        },
      );
    });

  const setMfaenabled = async () =>
    await new Promise((resolve, reject) => {
      setMFAenabledrequest(
        {
          twoFactorAuthentication: {
            authenticationType: 'AuthenticatorApp',
          },
        },
        {
          onSuccess(data) {
            resolve(data);
          },
          onError(err) {
            reject(err);
          },
        },
      );
    });
  const setUserAuthsuccess = async user => {
    setUserSuccess(user || JSON.parse(localStorage.getItem('userData')));
  };
  const sendOtpRequest = async payload =>
    new Promise((resolve, reject) => {
      sendOtpRequestmutation(
        {
          ...payload,
        },
        {
          onSuccess(data) {
            resolve(data);
          },
          onError(err) {
            reject(err);
          },
        },
      );
    });
  const setMFARequestOtp = async payload =>
    new Promise((resolve, reject) => {
      setMFAenabledOtpmutation(
        {
          ...payload,
        },
        {
          onSuccess(data) {
            resolve(data);
          },
          onError(err) {
            reject(err);
          },
        },
      );
    });
  const verify2FAaction = async payload =>
    new Promise((resolve, reject) => {
      setVerify2faOtpmutation(
        {
          ...payload,
        },
        {
          onSuccess(data) {
            resolve(data);
          },
          onError(err) {
            reject(err);
          },
        },
      );
    });
  const forGotpassword = async (userid, otp, pass) =>
    new Promise((resolve, reject) => {
      verifyforgotPasswordOtpmutation(
        {
          _codeVerification: userid,
          code: otp,
        },
        {
          onSuccess(_data) {
            console.log(_data);
            resetPasswordmutation(
              {
                _codeVerification: userid,
                password: pass,
              },
              {
                onSuccess(data) {
                  console.log(data);
                  resolve(data);
                },
                onError(er) {
                  reject(er);
                },
              },
            );
          },
          onError(er) {
            reject(er);
          },
        },
      );
    });
  const checkCondition = (condition, message) => {
    if (!condition) {
      handleToastfunction({
        status: 'error',
        title: message,
      });
      return true;
    }
    return false;
  };
  const GoogleSignin = async type =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        const provider = new GoogleAuthProvider();
        const googleresponse = await signInWithPopup(auth, provider);

        if (!type) {
          // eslint-disable-next-line no-use-before-define
          const availability = await CheckAvailability({
            email: googleresponse?._tokenResponse?.email,
          });
          if (
            checkCondition(
              availability?.data?.data?.items[0]?.existingUserWithSocialLogin?.available,
              'This account is associated with existing Spatial iD, please try logging in via google social sign in',
            ) ||
            checkCondition(
              availability?.data?.data?.items[0]?.email?.available,
              'Email already exist.',
            )
          ) {
            reject();
          }
        }
        resolve(googleresponse);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });

  const CheckAvailability = async val =>
    new Promise((resolve, reject) => {
      checkAvailability(val, {
        onSuccess(data) {
          resolve(data);
        },
        onError(er) {
          reject(er);
        },
      });
    });
  const changePassword = async val =>
    new Promise((resolve, reject) => {
      changePasswordmutation(val, {
        onSuccess(data) {
          resolve(data);
        },
        onError(er) {
          reject(er);
        },
      });
    });

  const handleGoogleSignupSuccess = (user, token, isfromsignup) => {
    localStorage.setItem('userData', JSON.stringify(user?.user));
    localStorage.setItem('sessionidentifier', user?.sessionIdentifier);
    localStorage.setItem('theme', 'light');
    if (isfromsignup) {
      isfromsignup();
      return;
    }
    dispatch(
      actions.setUserData({
        handleSuccess: _data => dispatch(actions.setUserDataSuccess(_data)),
      }),
    );
    setUserAuthsuccess(user);
    localStorage.setItem('authToken', token);
  };
  return (
    <UserAccountContext.Provider
      value={{
        // logout,
        authenticate,
        createUserSignup,
        sendOtpRequest,
        GoogleSignin,
        confirmOtp,
        handleGoogleSignupSuccess,
        CheckAvailability,
        setMfaenabled,
        setMFARequestOtp,
        UserData,
        changePassword,
        forGotpassword,
        verify2FAaction,
        setUserAuthsuccess,
      }}
    >
      {children}
    </UserAccountContext.Provider>
  );
};

export { UserAccountState };
