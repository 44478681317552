// // get traffic google analysis data action label
export const GET_TRAFFIC_GA_DATA_START = 'GET_TRAFFIC_GA_DATA_START';
export const GET_TRAFFIC_GA_DATA = 'GET_TRAFFIC_GA_DATA';
export const GET_TRAFFIC_GA_DATA_SUCCESS = 'GET_TRAFFIC_GA_DATA_SUCCESS';
export const GET_TRAFFIC_GA_DATA_FAIL = 'GET_TRAFFIC_GA_DATA_FAIL';

// // get traffic last month's google analysis data action label
export const GET_TRAFFIC_LAST_MONTH_GA_DATA_START = 'GET_TRAFFIC_LAST_MONTH_GA_DATA_START';
export const GET_TRAFFIC_LAST_MONTH_GA_DATA = 'GET_TRAFFIC_LAST_MONTH_GA_DATA';
export const GET_TRAFFIC_LAST_MONTH_GA_DATA_SUCCESS = 'GET_TRAFFIC_LAST_MONTH_GA_DATA_SUCCESS';
export const GET_TRAFFIC_LAST_MONTH_GA_DATA_FAIL = 'GET_TRAFFIC_LAST_TWO_GA_DATA_FAIL';

// // get geography country google analysis data action label
export const GET_GEOGRAPHY_COUNTRY_GA_LIST_START = 'GET_GEOGRAPHY_COUNTRY_GA_LIST_START';
export const GET_GEOGRAPHY_COUNTRY_GA_LIST = 'GET_GEOGRAPHY_COUNTRY_GA_LIST';
export const GET_GEOGRAPHY_COUNTRY_GA_LIST_SUCCESS = 'GET_GEOGRAPHY_COUNTRY_GA_LIST_SUCCESS';
export const GET_GEOGRAPHY_COUNTRY_GA_LIST_FAIL = 'GET_GEOGRAPHY_COUNTRY_GA_LIST_FAIL';

// // get geography region google analysis data action label
export const GET_REGION_GEOGRAPHY_GA_LIST_START = 'GET_REGION_GEOGRAPHY_GA_LIST_START';
export const GET_REGION_GEOGRAPHY_GA_LIST = 'GET_REGION_GEOGRAPHY_GA_LIST';
export const GET_REGION_GEOGRAPHY_GA_LIST_SUCCESS = 'GET_REGION_GEOGRAPHY_GA_LIST_SUCCESS';
export const GET_REGION_GEOGRAPHY_GA_LIST_FAIL = 'GET_REGION_GEOGRAPHY_GA_LIST_FAIL';

// // action label google analysis geography country map
export const GET_GA_GEOGRAPHY_COUNTRY_MAP_START = 'GET_GA_GEOGRAPHY_COUNTRY_MAP_START';
export const GET_GA_GEOGRAPHY_COUNTRY_MAP = 'GET_GA_GEOGRAPHY_COUNTRY_MAP';
export const GET_GA_GEOGRAPHY_COUNTRY_MAP_SUCCESS = 'GET_GA_GEOGRAPHY_COUNTRY_MAP_SUCCESS';
export const GET_GA_GEOGRAPHY_COUNTRY_MAP_FALL = 'GET_GA_GEOGRAPHY_COUNTRY_MAP_FALL';

// statemanagement for analytics page

export const SET_GLOBAL_DATE_FILTER = 'SET_GLOBAL_DATE_FILTER';
