import React, { useState, useEffect } from 'react';

function SetTokenHeader(Component, axios) {
  // WrappedComponent function to set authorization token header
  function WrappedComponent(props) {
    // State to store interceptor
    const [interceptor] = useState(
      // Setting up interceptor to modify request config
      axios.interceptors.request.use(config => {
        // Adding authorization header with token from localStorage
        const configObject = config;
        configObject.headers.Authorization = `Bearer ${localStorage.getItem('authToken')}`;
        return configObject;
      }),
    );

    // Cleanup function to remove interceptor on unmount
    useEffect(
      () => () => {
        axios.interceptors.request.eject(interceptor);
      },
      [], // Empty dependency array ensures cleanup only happens once
    );

    // Rendering the original component with its props
    return (
      <>
        <Component {...props} />
      </>
    );
  }

  // Returning the wrapped component
  return WrappedComponent;
}

// Exporting the HOC
export default SetTokenHeader;
