import { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axiosMain from 'http/axios/axios_main';
import SetTokenHeader from 'hoc/SetTokenHeader/SetTokenHeader';
import ProfileMenu from 'components/ProfileMenu/ProfileMenu';
import { CONTINUITY_DARK, CONTINUITY_LIGHT, MENU_DARK, MENU_LIGHT } from 'assets/images';
import { REACT_APP_SLLABSIMAGEURL_STATIC } from 'utils/envConfig';
import { Spinner } from 'components';
import '../../assets/scss/custom/components/_table.scss';
import Sidebar from './SideBar';
import ResponsiveSidebar from './responsiveSidebar/ResponsiveSidebar';
// import Footer from './Footer';

import './layout.css';

const Layout = props => {
  // // initial state
  const { children } = props;
  const isIframe = new URLSearchParams(window.location.search).get('isIframe');
  // // redux state
  const { Theme } = useSelector(state => state.auth);
  const location = useLocation();
  // // local state
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openMobileSidebar, setOpenMobileSidebar] = useState(false);
  const [MobileSidebar, setMobileSidebar] = useState(false);
  const { isPaymentUnderProcess } = useSelector(state => state.payment);

  // Render layout based on whether it's an iframe or not
  return location.pathname !== '/experience' ? (
    <>
      <div>
        {/* Render Sidebar and ResponsiveSidebar if it's not an iframe */}
        {!isIframe && (
          <>
            <Sidebar
              MobileSidebar={MobileSidebar}
              setMobileSidebar={() => setMobileSidebar(false)}
              openSidebar={openSidebar}
              setOpenSidebar={setOpenSidebar}
            />
            <ResponsiveSidebar
              openMobileSidebar={openMobileSidebar}
              setOpenMobileSidebar={setOpenMobileSidebar}
            />
          </>
        )}
        <div
          className={` ${isIframe && 'nomarginleft'} maincontent ${openSidebar && 'largecontent'}`}
          id="style-1"
        >
          {/* Render top navigation and profile menu */}
          {!isIframe && (
            <div
              className={`mx-3 mt-3 top_nav_wrapper ${isPaymentUnderProcess ? 'ispaymentprocess' : ''
                }`}
            >
              <img
                className="d-none d-lg-block cp"
                src={Theme === 'dark' ? MENU_DARK : MENU_LIGHT}
                onClick={() => setOpenSidebar(!openSidebar)}
                alt=""
              />
              <img
                className="d-lg-none cp"
                src={Theme === 'dark' ? MENU_DARK : MENU_LIGHT}
                onClick={() => setOpenMobileSidebar(!openMobileSidebar)}
                alt=""
              />
              <img
                src={
                  Theme === 'dark'
                    ? `${REACT_APP_SLLABSIMAGEURL_STATIC}${CONTINUITY_DARK}`
                    : `${REACT_APP_SLLABSIMAGEURL_STATIC}${CONTINUITY_LIGHT}`
                }
                alt="brandLogo"
                className=" d-lg-none img-fluid"
              />
              <ProfileMenu
                MobileSidebar={MobileSidebar}
                setMobileSidebar={() => setMobileSidebar(false)}
              />
            </div>
          )}
          {/* Render children components */}
          <div
            className={
              !location?.pathname?.includes('/buy/mycart') &&
              `scrollbar ${location.pathname === '/dashboard' && 'scrollbardashboard'}`
            }
          >
            {/* Render children components with suspense fallback */}
            <Suspense fallback={<Spinner islayoutloader={!isIframe} />}>{children}</Suspense>
          </div>
        </div>
      </div>
      {/* {location.pathname === '/dashboard' && !isIframe && <Footer openSidebar={openSidebar} />} */}
    </>
  ) : (
    // Render children components directly if it's an iframe
    <>{children}</>
  );
};

export default SetTokenHeader(Layout, axiosMain);
