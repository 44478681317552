/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import ModalState from 'Context/ModalContext/ModalState';
import { UserAccountState } from 'Context/UserAccountContext/UserAccountState';
import rootReducer from './store/reducer';
import { rootWatcher } from './store/sagas';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Enhancers for Redux DevTools
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : null || compose;

// Create saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create Redux store with middleware
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

// Run sagas
sagaMiddleware.run(rootWatcher);

// Create a new instance of QueryClient
const queryClient = new QueryClient();

// Render the application
ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {/* Wrapping the App component with ModalState and UserAccountState contexts */}
      <ModalState>
        <UserAccountState>
          <App />
        </UserAccountState>
      </ModalState>
    </QueryClientProvider>
  </Provider>,
  // </React.StrictMode>,
);

// Measure performance of the application
reportWebVitals();
