import { CHIP_DEFAULTCOLOR } from 'utils/envConfig';
import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLoading: false,
  packagesData: null,
  chipState: '',
  myLogoList: [],
  logotab: 'upload',
  chipOrderstate: '',
  viewSiteChips: null,
  selectedChipState: {},
  logouploading: false,
  chipColor: CHIP_DEFAULTCOLOR,
  selectedModal: '',
  selectedLogo: {},
  donechipdesign: !!sessionStorage.getItem('chipdesigndone'),
  chipquantdone: !!sessionStorage.getItem('chipquantdone'),
  chipDesignList: {},
  rotation: { x: 0, y: 0, z: 0 },
  uploadLogoLoading: false,
  logoDeleteLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_CHIP_STATE:
      return { ...state, chipState: JSON.parse(localStorage.getItem('chipState')) };

    case actionLabels.GET_CHIP_ORDER_STATE:
      return { ...state, chipOrderstate: JSON.parse(localStorage.getItem('chipCart')) };
    case actionLabels.GET_LOGO_PRESIGNED_URL_SUCCESS:
      return { ...state, logouploading: false };
    case actionLabels.VIEW_CHIPS:
      return { ...state, isLoading: true };
    case actionLabels.CLEAR_VIEW_CHIP:
      return { ...state, viewSiteChips: null };
    case actionLabels.VIEW_CHIPS_SUCCESS:
      return { ...state, isLoading: false, viewSiteChips: payload };
    case actionLabels.SET_CHIP_ROTATION:
      return { ...state, rotation: payload };
    case actionLabels.DONE_CHIP_DESIGN:
      return { ...state, donechipdesign: true };
    case actionLabels.DONE_CHIP_QUANT:
      return { ...state, chipquantdone: true };
    case actionLabels.SET_SELECTED_MODAL:
      return { ...state, selectedModal: payload };
    case actionLabels.SET_SELECTED_CHIP_COLOR:
      return {
        ...state,
        chipColor: payload,
      };
    case actionLabels.SET_SELECTED_CHIP_STATE:
      return { ...state, selectedChipState: payload };
    case actionLabels?.GET_CHIP_DESIGN:
      return { ...state, isLoading: true };
    case actionLabels?.GET_CHIP_DESIGN_SUCCESS:
      return { ...state, isLoading: false, chipDesignList: payload?.data };
    case actionLabels.SET_CURRENT_SELECTED_LOGO: {
      return {
        ...state,
        selectedLogo: payload,
      };
    }
    case actionLabels.GET_LOGO_PRESIGNED_URL:
      return { ...state, logouploading: true };
    case actionLabels.SET_LOGO_URL_SUCCESS:
      return { ...state, logouploading: false };
    case actionLabels.SET_LOGO_URL_FAIL:
      return { ...state, logouploading: false };

    case actionLabels.LOGO_DELETE: {
      return { ...state, logoDeleteLoading: true };
    }
    case actionLabels.LOGO_DELETE_SUCCESS:
      return {
        ...state,
        logoDeleteLoading: false,
        myLogoList: {
          ...state?.myLogoList,
          list: state.myLogoList.list.filter(elem => elem.logoId !== payload.logoid),
        },
      };
    case actionLabels.DELETE_CHIP_DESIGN: {
      return { ...state, logoDeleteLoading: true };
    }
    case actionLabels.DELETE_CHIP_DESIGN_SUCCESS:
      return {
        ...state,
        logoDeleteLoading: false,
        chipDesignList: {
          ...state?.chipDesignList,
          list: state.chipDesignList.list.filter(
            elem => elem.chipDesignId !== payload.chipDesignId,
          ),
        },
      };
    case actionLabels.DELETE_CHIP_DESIGN_FAIL:
      return {
        ...state,
        logoDeleteLoading: false,
      };
    case actionLabels.GET_LIST_MYLOGO: {
      return { ...state, isLoading: true };
    }
    case actionLabels.GET_LIST_MYLOGO_SUCCESS: {
      return { ...state, myLogoList: payload?.data, isLoading: false };
    }

    case actionLabels.SET_LOGO_TAB: {
      return { ...state, logotab: payload };
    }
    default:
      return state;
  }
};
