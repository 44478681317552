/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import './modal.css';
import './authenticationmodals/authenticationModal.css';
import { REACT_APP_SLLABSIMAGEURL_STATIC } from 'utils/envConfig';
import { NEW_CAMPAIGN_MODAL_IMAGE } from 'assets/images';

const CustomModal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  handleCLick,
  btntext,
  hideclose,
  titleBottom = true,
  isBtn,
  modalBodyClassname,
  onBackclick,
  headerclass,
  width,
  size,
  className,
  CustomHeader,
  isCreateCampaign = false,
}) => {
  const { Theme } = useSelector(state => state.auth);
  return (
    <Modal
      size={size || 'md'}
      data-theme={Theme}
      style={{ width }}
      backdrop="static"
      isOpen={isOpen}
      centered
      scrollable
      className={className}
    >
      <div className="flex flex-col lg:flex-row bg-[--primary-background-color] items-center justify-center">
        {isCreateCampaign && (
          <img
            className="w-[360px] object-contain object-bottom pt-20"
            src={`${REACT_APP_SLLABSIMAGEURL_STATIC}${NEW_CAMPAIGN_MODAL_IMAGE}`}
            alt="A hand holding a phone with a template campaign that is tapping a Spatial Tag"
          />
        )}
        <ModalBody
          className={`position-relative bg-[--primary-background-color] ${modalBodyClassname} `}
        >
          {onBackclick && (
            <p className="mb-0 goBackIcon" onClick={onBackclick}>
              {' '}
              <i className="fa-solid fa-chevron-left me-2" />
              Back{' '}
            </p>
          )}
          {!CustomHeader ? (
            <div
              className={`header2famethod flex flex-col gap-2 mb-8 position-relative ${headerclass} justify-start`}
            >
              <h5 className="text-[--text-primary] m-0 text-4xl font-medium">{title}</h5>
              {subtitle ? <p className="text-[#6B6B6B]">{subtitle}</p> : null}
            </div>
          ) : (
            <CustomHeader />
          )}
          {onClose && !hideclose && (
            <div onClick={onClose} className="closemark cp">
              <i class="fa-solid fa-xmark" />
            </div>
          )}
          {children}
        </ModalBody>
        {isBtn && (
          <ModalFooter className="footer2famethod">
            <Button className="w-50" color="primary" onClick={handleCLick}>
              {btntext}
            </Button>
          </ModalFooter>
        )}
      </div>
    </Modal>
  );
};

export default CustomModal;
