// // get all visitors list action label
export const GET_ALL_VISITORS_LIST_START = 'GET_ALL_VISITORS_LIST_START';
export const GET_ALL_VISITORS_LIST = 'GET_ALL_VISITORS_LIST';
export const GET_ALL_VISITORS_LIST_SUCCESS = 'GET_ALL_VISITORS_LIST_SUCCESS';
export const GET_ALL_VISITORS_LIST_FAIL = 'GET_ALL_VISITORS_LIST_FAIL';

// // get visitors details action label
export const GET_VISITORS_DETAILS_START = 'GET_VISITORS_DETAILS_START';
export const GET_VISITORS_DETAILS = 'GET_VISITORS_DETAILS';
export const GET_VISITORS_DETAILS_SUCCESS = 'GET_VISITORS_DETAILS_SUCCESS';
export const GET_VISITORS_DETAILS_FAIL = 'GET_VISITORS_DETAILS_FAIL';

// // get visitors site list action label
export const GET_VISITORS_SITE_LIST_START = 'GET_VISITORS_SITE_LIST_START';
export const GET_VISITORS_SITE_LIST = 'GET_VISITORS_SITE_LIST';
export const GET_VISITORS_SITE_LIST_SUCCESS = 'GET_VISITORS_SITE_LIST_SUCCESS';
export const GET_VISITORS_SITE_LIST_FAIL = 'GET_VISITORS_DETAILS_FAIL';
