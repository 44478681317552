// Import necessary dependencies and components
import React, { Suspense, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import CanvasLoader from 'components/Loader/Loader';
import { useSelector } from 'react-redux';
import { PerspectiveCamera, Center, Preload, Environment } from '@react-three/drei';
import ThreeDViewer from 'components/ThreeDViewer/ThreeDViewer';
import THREEdModelExportPoc from './CapModel';
import './3dmodel.scss';

// Define the functional component T3dEditor
const T3dEditor = () => {
  // Extract selectedModal and chipColor from Redux store using useSelector hook
  const { selectedModal, chipColor } = useSelector(state => state.chips);
  const { cameraposition } = useSelector(state => state.camera);
  // State to track dragging state
  const [isDragging, setIsDragging] = useState(false);

  // Event handler for pointer down event
  const handlePointerDown = () => {
    setIsDragging(true);
  };

  // Event handler for pointer up event
  const handlePointerUp = () => {
    setIsDragging(false);
  };

  // Return the JSX structure of the component
  return (
    <div className={`scene ${isDragging ? 'dragging' : ''} modelwrapper`}>
      {/* Render 3D viewer if selectedModal exists */}
      {selectedModal?.model && selectedModal?.isDefault ? (
        <div id="canvas" className="wrapper h-100">
          <ThreeDViewer url={selectedModal?.model} />
        </div>
      ) : (
        /* Render Canvas for 3D rendering */
        <Canvas
          shadows
          id="canvas"
          gl={{ preserveDrawingBuffer: true }}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
        >
          {/* Suspense for loading fallback */}
          <Suspense fallback={<CanvasLoader />}>
            {/* Perspective camera */}
            <PerspectiveCamera
              makeDefault
              fov={30}
              position={[cameraposition.x, cameraposition.y, cameraposition.z]}
            />
            {/* Render ambient light or environment based on conditions */}
            {(!selectedModal.model && !selectedModal.isDefault) ||
            chipColor !== 'rgb(17, 17, 17)' ? (
              <ambientLight intensity={7} />
            ) : (
              <Environment files="/asset/potsdamer_platz_1k.hdr" />
            )}
            {/* Center the content */}
            <Center>
              {/* Render 3D model if selectedModal exists */}
              {selectedModal.model && !selectedModal.isDefault && (
                <THREEdModelExportPoc selectedModal={selectedModal.model} />
              )}
            </Center>
          </Suspense>
          {/* Preload all resources */}
          <Preload all />
        </Canvas>
      )}
    </div>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default React.memo(T3dEditor);
