import * as actionLabels from '../../actionLabels';

// // action for get visitors
export const getAllVisitorsListStart = () => ({
  type: actionLabels.GET_ALL_VISITORS_LIST_START,
});
export const getAllVisitorsList = payload => ({
  type: actionLabels.GET_ALL_VISITORS_LIST,
  payload,
});
export const getAllVisitorsListSuccess = payload => ({
  type: actionLabels.GET_ALL_VISITORS_LIST_SUCCESS,
  payload,
});
export const getAllVisitorsListFail = payload => ({
  type: actionLabels.GET_ALL_VISITORS_LIST_FAIL,
  payload,
});
export const setWidgetInfoOpen = payload => ({
  type: actionLabels.SET_WIDGET_INFOOPEN,
  payload,
});

// // action for get visitors details
export const getVisitorsDetailsStart = () => ({
  type: actionLabels.GET_VISITORS_DETAILS_START,
});
export const getVisitorsDetails = payload => ({
  type: actionLabels.GET_VISITORS_DETAILS,
  payload,
});
export const getVisitorsDetailsSuccess = payload => ({
  type: actionLabels.GET_VISITORS_DETAILS_SUCCESS,
  payload,
});
export const getVisitorsDetailsFail = payload => ({
  type: actionLabels.GET_VISITORS_DETAILS_FAIL,
  payload,
});

// // action for get visitors site list
export const getVisitorsSiteStart = () => ({
  type: actionLabels.GET_VISITORS_SITE_LIST_START,
});
export const getVisitorsSite = payload => ({
  type: actionLabels.GET_VISITORS_SITE_LIST,
  payload,
});
export const getVisitorsSiteSuccess = payload => ({
  type: actionLabels.GET_VISITORS_SITE_LIST_SUCCESS,
  payload,
});
export const getVisitorsSiteFail = payload => ({
  type: actionLabels.GET_VISITORS_SITE_LIST_FAIL,
  payload,
});
