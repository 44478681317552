import { put, takeLatest } from 'redux-saga/effects';
import {
  getOverallGeographyCountryGAListStart,
  getOverallGeographyCountryGAListSuccess,
  getOverallGeographyCountryGAListFail,
  getOverallRegionGeographyGAListStart,
  getOverallRegionGeographyGAListSuccess,
  getOverallRegionGeographyGAListFail,
  getOverallGaCountryGeographyMapStart,
  getOverallGaCountryGeographyMapSuccess,
  getOverallGaCountryGeographyMapFail,
} from '../../../actions';
import apiHandler from '../../../../utils/apiHandler';
import {
  handelOverallGAData,
  handelOverallCountryMapData,
  handleToastfunction,
} from '../../../../utils/helpers';
import * as actionLabels from '../../../actionLabels';

export function* getOverallCountryGeographyGAListSaga({ payload }) {
  const { offset, limit, searchValue } = payload;
  yield put(getOverallGeographyCountryGAListStart());
  yield apiHandler({
    endpoint: `user/v1/googleAnalytics/getAnalyticsData`,
    successHandler: yield function* (response) {
      yield put(getOverallGeographyCountryGAListSuccess(handelOverallGAData(response?.data)));
    },
    failHandler: yield function* (response) {
      yield put(getOverallGeographyCountryGAListFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    payload: {
      queryData: {
        dimensions: [
          {
            name: 'country',
          },
        ],
        metrics: [
          {
            name: 'sessions',
          },
          {
            name: 'totalUsers',
          },
          {
            name: 'screenPageViews',
          },
          {
            name: 'userEngagementDuration',
          },
        ],
        dateRanges: [{ startDate: '30daysAgo', endDate: 'yesterday' }],
        dimensionFilter: {
          filter: {
            fieldName: 'country',
            stringFilter: {
              matchType: 'BEGINS_WITH',
              value: searchValue,
            },
          },
        },
        limit,
        offset: offset - 1,
      },
    },
    apiType: 'post',
  });
}

export function* getOverallRegionGeographyGAListSaga({ payload }) {
  const { selectedCountry, searchValue, offset, limit } = payload;
  yield put(getOverallRegionGeographyGAListStart());
  yield apiHandler({
    endpoint: `user/v1/googleAnalytics/getAnalyticsData`,
    successHandler: yield function* (response) {
      yield put(getOverallRegionGeographyGAListSuccess(handelOverallGAData(response?.data)));
    },
    failHandler: yield function* (response) {
      yield put(getOverallRegionGeographyGAListFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    payload: {
      queryData: {
        dimensions: [
          {
            name: 'country',
          },
          {
            name: 'region',
          },
        ],
        metrics: [
          {
            name: 'sessions',
          },
          {
            name: 'totalUsers',
          },
          {
            name: 'screenPageViews',
          },
          {
            name: 'userEngagementDuration',
          },
        ],
        dateRanges: [{ startDate: '30daysAgo', endDate: 'yesterday' }],
        dimensionFilter: {
          andGroup: {
            expressions: [
              {
                filter: {
                  fieldName: 'country',
                  stringFilter: {
                    matchType: 'CONTAINS',
                    value: selectedCountry,
                    caseSensitive: false,
                  },
                },
              },
              {
                filter: {
                  fieldName: 'region',
                  stringFilter: {
                    matchType: 'BEGINS_WITH',
                    value: searchValue,
                    caseSensitive: false,
                  },
                },
              },
            ],
          },
        },
        limit,
        offset: offset - 1,
      },
    },
    apiType: 'post',
  });
}

export function* getOverallGACountryGeographyMapSaga({ payload }) {
  const { filterValue } = payload;
  yield put(getOverallGaCountryGeographyMapStart());
  yield apiHandler({
    endpoint: `user/v1/googleAnalytics/getAnalyticsData`,
    successHandler: yield function* (response) {
      yield put(
        getOverallGaCountryGeographyMapSuccess(handelOverallCountryMapData(response?.data)),
      );
    },
    failHandler: yield function* (response) {
      yield put(getOverallGaCountryGeographyMapFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    payload: {
      queryData: {
        dimensions: [
          {
            name: 'country',
          },
          {
            name: 'countryId',
          },
        ],
        metrics: [
          {
            name: filterValue,
          },
        ],
        dateRanges: [{ startDate: '30daysAgo', endDate: 'yesterday' }],
      },
    },
    apiType: 'post',
  });
}

function* watcher() {
  yield takeLatest(
    actionLabels.GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST,
    getOverallCountryGeographyGAListSaga,
  );
  yield takeLatest(
    actionLabels.GET_REGION_OVERALL_GEOGRAPHY_GA_LIST,
    getOverallRegionGeographyGAListSaga,
  );
  yield takeLatest(
    actionLabels.GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP,
    getOverallGACountryGeographyMapSaga,
  );
}
export default watcher;
