import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLoading: {
    getCmsData: false,
    getSocialMediaLink: false,
  },
  cmsData: null,
  socialMediaLink: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // // reducer for get all cms
    case actionLabels.GET_CMS_DATA:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCmsData: true,
        },
        cmsData: null,
      };
    case actionLabels.GET_CMS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCmsData: false,
        },
        cmsData: payload,
      };
    case actionLabels.GET_CMS_DATA_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCmsData: false,
        },
        cmsData: null,
      };

    // // reducer for get social media link
    case actionLabels.GET_SOCIAL_MEDIA_LINK:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSocialMediaLink: true,
        },
        socialMediaLink: null,
      };
    case actionLabels.GET_SOCIAL_MEDIA_LINK_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSocialMediaLink: false,
        },
        socialMediaLink: payload,
      };
    case actionLabels.GET_SOCIAL_MEDIA_LINK_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSocialMediaLink: false,
        },
        socialMediaLink: null,
      };

    default:
      return state;
  }
};
