import React from 'react';
import { ICON_SUCCESSTOAST, ICON_ERRORTOAST, ICON_INFOTOAST } from 'assets/Icons';
import './toast.css';
// import { useSelector } from 'react-redux';

const ToastComponent = ({ status, title, subTitle, action = false }) => {
  // const { Theme } = useSelector(state => state.auth);

  const statusIcon = () => {
    switch (status) {
      case 'success':
        return <img src={ICON_SUCCESSTOAST} alt="success" />;
      case 'error':
        return <img src={ICON_ERRORTOAST} alt="error" />;
      case 'info':
        return <img src={ICON_INFOTOAST} alt="info" />;
      default:
        return '';
    }
  };

  return (
    <div className="custom-toast">
      <div className="d-flex gap-3 flex-row align-items-start">
        <div className="icon-toaster d-flex justify-content-start align-items-start">
          {statusIcon()}
        </div>
        <div className="d-flex gap-2 flex-column justify-content-start align-items-start">
          <span className="title-toast title-toast-black  ospt-2">{title}</span>
          {subTitle && <span className="subtitle-toast">{subTitle}</span>}
          {action && (
            <button
              className="action-toast"
              type="button"
              onClick={() => console.log('Button clicked!')}
            >
              Action
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToastComponent;
