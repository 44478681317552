import * as actionLabels from '../../actionLabels';

export const initialState = {
  ViewLayout: 'tabular',
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SET_ORDERS_VIEW_LAYOUT:
      return { ...state, ViewLayout: payload };
    default:
      return state;
  }
};
