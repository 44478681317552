/* eslint-disable import/no-unresolved */
import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MdOutlineDone } from 'react-icons/md';
import { ChipCheckoutSvg, ChipstyleSvg, ChipdesignSVG, ChipQuantitySvg } from 'assets/images/index';
import './Style/breadcrumbstopbar.scss';

function BuyCrumbs() {
  // Get the current location and state from React Router
  const location = useLocation();
  const { state } = location;

  // Get the necessary state values from the Redux store
  const { chipState, donechipdesign, chipquantdone } = useSelector(_state => _state.chips);
  const { isPaymentUnderProcess, isPaymentUnderprogressOrders } = useSelector(
    _state => _state.payment,
  );

  return (
    // Render the breadcrumb component if not on the edit chip page and payment is not in progress
    (!location.pathname.includes('editchip') || location.pathname.includes('selected-chips')) &&
    !isPaymentUnderProcess &&
    !isPaymentUnderprogressOrders && (
      <div className="w-100 d-flex justify-content-between align-items-center">
        {/* Render the page header */}
        <div className="page_header ">
          {location.pathname.includes('chipstyle') ? (
            <h4 className="mb-0">Tag Style</h4>
          ) : location.pathname.includes('selected-chips') ? (
            <h4 className="mb-0">Tag Design</h4>
          ) : location.pathname?.includes('chippackage') ? (
            <h4 className="mb-0">Tag Quantity</h4>
          ) : location.pathname?.includes('mycart') ? (
            <h4 className="mb-0">My Cart</h4>
          ) : null}
        </div>

        {/* Render the breadcrumb steps */}
        <ul className="wrapper mb-0  list-unstyled justify-content-around chip_edit_sideBar">
          {/* Tag Style step */}
          <li className="wrapper">
            <div className="d-flex align-items-center">
              {chipState?.packValue && !location.pathname.includes('chipstyle') ? (
                <MdOutlineDone
                  style={{
                    color: location.pathname.includes('chipstyle')
                      ? 'var(--active-color)'
                      : 'var(--chip-breadcrums-color)',
                  }}
                />
              ) : (
                <ChipstyleSvg
                  color={
                    location.pathname.includes('chipstyle')
                      ? 'var(--active-color)'
                      : 'var(--chip-breadcrums-color)'
                  }
                />
              )}
              <NavLink
                className={` ospt-2 wrapper fs14  ms-1 ${
                  location.pathname.includes('chipstyle') && 'selectedtabsidebar'
                }`}
                to={{ pathname: '/buy/chipstyle', state }}
              >
                Tag Style
              </NavLink>
            </div>
            <div className=" mx-2">
              <i className="fa-solid fa-chevron-right fs12" />
            </div>
          </li>

          {/* Tag Design step */}
          <li className="wrapper">
            <div className="d-flex align-items-center">
              {donechipdesign && !location.pathname.includes('editchip') ? (
                <MdOutlineDone
                  style={{
                    color: location.pathname.includes('editchip')
                      ? 'var(--active-color)'
                      : 'var(--chip-breadcrums-color)',
                  }}
                />
              ) : (
                <ChipdesignSVG
                  color={
                    location.pathname.includes('editchip')
                      ? 'var(--active-color)'
                      : 'var(--chip-breadcrums-color)'
                  }
                />
              )}

              <NavLink
                className={`wrapper ${!chipState?.packValue && 'pointer-none'} fs14  ms-1 ospt-2 ${
                  location.pathname.includes('editchip') && 'selectedtabsidebar'
                }`}
                to={{ pathname: `/buy/editchip/selected-chips`, state }}
              >
                Tag Design
              </NavLink>
            </div>
            <div className=" mx-2">
              <i className="fa-solid fa-chevron-right fs12" />
            </div>
          </li>

          {/* Tag Quantity step */}
          <li className="wrapper">
            <div className="d-flex align-items-center">
              {chipquantdone && !location.pathname.includes('chippackage') ? (
                <MdOutlineDone
                  style={{
                    color: location.pathname.includes('chippackage')
                      ? 'var(--active-color)'
                      : 'var(--chip-breadcrums-color)',
                  }}
                />
              ) : (
                <ChipQuantitySvg
                  color={
                    location.pathname.includes('chippackage')
                      ? 'var(--active-color)'
                      : 'var(--chip-breadcrums-color)'
                  }
                />
              )}
              <NavLink
                className={` ospt-2 wrapper fs14  ms-1 ${!chipState?.packValue && 'pointer-none'} ${
                  location.pathname.includes('chippackage') && 'selectedtabsidebar'
                }`}
                to={{ pathname: `/buy/chippackage`, state }}
              >
                Tag Quantity
              </NavLink>
            </div>
            <div className=" mx-2">
              <i className="fa-solid fa-chevron-right fs12" />
            </div>
          </li>

          {/* Checkout step */}
          <li className="wrapper">
            <div className="d-flex align-items-center">
              <ChipCheckoutSvg
                color={
                  location.pathname.includes('mycart')
                    ? 'var(--active-color)'
                    : 'var(--chip-breadcrums-color)'
                }
              />
              <NavLink
                className={`wrapper ospt-2 fs14 ${
                  !!localStorage.getItem('isCartActive') && !!chipState?.packValue ? '' : 'no-drop'
                }  ms-1 ${location.pathname.includes('mycart') && 'selectedtabsidebar'}`}
                to={{ pathname: `/buy/mycart/`, state }}
              >
                Checkout
              </NavLink>
            </div>
          </li>
        </ul>
      </div>
    )
  );
}

export default React.memo(BuyCrumbs);
