// // get all cms action label
export const GET_CMS_DATA_START = 'GET_CMS_DATA_START';
export const GET_CMS_DATA = 'GET_CMS_DATA';
export const GET_CMS_DATA_SUCCESS = 'GET_CMS_DATA_SUCCESS';
export const GET_CMS_DATA_FAIL = 'GET_CMS_DATA_FAIL';

// // get social media link action label
export const GET_SOCIAL_MEDIA_LINK_START = 'GET_SOCIAL_MEDIA_LINK_START';
export const GET_SOCIAL_MEDIA_LINK = 'GET_SOCIAL_MEDIA_LINK';
export const GET_SOCIAL_MEDIA_LINK_SUCCESS = 'GET_SOCIAL_MEDIA_LINK_SUCCESS';
export const GET_SOCIAL_MEDIA_LINK_FAIL = 'GET_SOCIAL_MEDIA_LINK_FAIL';
