import * as actionLabels from '../../../actionLabels';

// // action for get traffic google analysis data
export const getTrafficGADataStart = () => ({
  type: actionLabels.GET_TRAFFIC_GA_DATA_START,
});
export const getTrafficGAData = payload => ({
  type: actionLabels.GET_TRAFFIC_GA_DATA,
  payload,
});
export const getTrafficGADataSuccess = payload => ({
  type: actionLabels.GET_TRAFFIC_GA_DATA_SUCCESS,
  payload,
});
export const getTrafficGADataFail = payload => ({
  type: actionLabels.GET_TRAFFIC_GA_DATA_FAIL,
  payload,
});

// // action for get traffic last month's google analysis data
export const getTrafficLastMonthGADataStart = () => ({
  type: actionLabels.GET_TRAFFIC_LAST_MONTH_GA_DATA_START,
});
export const getTrafficLastMonthGAData = payload => ({
  type: actionLabels.GET_TRAFFIC_LAST_MONTH_GA_DATA,
  payload,
});
export const getTrafficLastMonthGADataSuccess = payload => ({
  type: actionLabels.GET_TRAFFIC_LAST_MONTH_GA_DATA_SUCCESS,
  payload,
});
export const getTrafficLastMonthGADataFail = payload => ({
  type: actionLabels.GET_TRAFFIC_LAST_MONTH_GA_DATA_FAIL,
  payload,
});

// // action for get country geography google analysis lst
export const getGeographyCountryGAListStart = () => ({
  type: actionLabels.GET_GEOGRAPHY_COUNTRY_GA_LIST_START,
});
export const getGeographyCountryGAList = payload => ({
  type: actionLabels.GET_GEOGRAPHY_COUNTRY_GA_LIST,
  payload,
});
export const getGeographyCountryGAListSuccess = payload => ({
  type: actionLabels.GET_GEOGRAPHY_COUNTRY_GA_LIST_SUCCESS,
  payload,
});
export const getGeographyCountryGAListFail = payload => ({
  type: actionLabels.GET_GEOGRAPHY_COUNTRY_GA_LIST_FAIL,
  payload,
});

// // action for get region geography google analysis lst
export const getRegionGeographyGAListStart = () => ({
  type: actionLabels.GET_REGION_GEOGRAPHY_GA_LIST_START,
});
export const getRegionGeographyGAList = payload => ({
  type: actionLabels.GET_REGION_GEOGRAPHY_GA_LIST,
  payload,
});
export const getRegionGeographyGAListSuccess = payload => ({
  type: actionLabels.GET_REGION_GEOGRAPHY_GA_LIST_SUCCESS,
  payload,
});
export const getRegionGeographyGAListFail = payload => ({
  type: actionLabels.GET_REGION_GEOGRAPHY_GA_LIST_FAIL,
  payload,
});

// // action for google analysis geography country map
export const getGaCountryGeographyMapStart = () => ({
  type: actionLabels.GET_GA_GEOGRAPHY_COUNTRY_MAP_START,
});
export const getGaCountryGeographyMap = payload => ({
  type: actionLabels.GET_GA_GEOGRAPHY_COUNTRY_MAP,
  payload,
});
export const getGaCountryGeographyMapSuccess = payload => ({
  type: actionLabels.GET_GA_GEOGRAPHY_COUNTRY_MAP_SUCCESS,
  payload,
});
export const getGaCountryGeographyMapFail = payload => ({
  type: actionLabels.GET_GA_GEOGRAPHY_COUNTRY_MAP_FALL,
  payload,
});

export const setGlobalFilter = payload => ({
  type: actionLabels.SET_GLOBAL_DATE_FILTER,
  payload,
});
