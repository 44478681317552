/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import T3DmodelContext from './3DmodelContext';

const T3DModelState = ({ children }) => {
  const [modeltexture, setModeltexture] = useState({
    logoDecal: ``,
    fullDecal: '',
  });
  const [currentlogoTab, setCurrentlogoTab] = useState('upload');
  const [ScaleFactor, setScaleFactor] = useState(1);
  const State = {
    setModeltexture,
    modeltexture,
    setScaleFactor,
    setCurrentlogoTab,
    currentlogoTab,
    ScaleFactor,
  };
  return <T3DmodelContext.Provider value={State}>{children}</T3DmodelContext.Provider>;
};
export default T3DModelState;
