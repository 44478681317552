import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_APP_END_POINT_AUTHURL } from 'utils/envConfig';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import LayoutWrapper from 'views/Layout/LayoutWrapper';
import AlertModal from 'components/AlertModal';
import * as actions from 'store/actions';
import useUserAccountContext from 'Context/UserAccountContext/useUserAccountContext';
import BuyContainer from 'views/user/BuyChips/BuyContainer';
import { setCssFontstyles } from 'utils/cssFontstyleschanges';
import EditContainer from 'views/user/chipsManagement/mainContainer/EditContainer';
import { setTheme } from 'store/actions';
import { buychipsroutes, editchipsroutes, guestRoutes, userRoutes } from './routes';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/theme.scss';
import './app.css';
import './assets/slick/slick.css';
import './assets/slick/slick-theme.css';

function App() {
  // Check if token is present
  const tokenPresent = localStorage.getItem('authToken');
  // Get theme from Redux state
  const { Theme } = useSelector(state => state.auth);
  // Get query parameters from URL
  const queryParameters = new URLSearchParams(window.location.search);
  const userId = queryParameters.get('userId');
  const SiteToken = queryParameters.get('SiteToken');
  const isIframe = new URLSearchParams(window.location.search).get('isIframe');
  const theme = new URLSearchParams(window.location.search).get('theme');
  // Check if token is expired
  const tokenExpire = !!useSelector(state => state.auth.isExpire);
  // Get user authentication context
  const { setUserAuthsuccess, UserData } = useUserAccountContext();
  const dispatch = useDispatch();

  // Function to make API call for authentication token
  const makeApiCallforAuthToken = async (id, token) => {
    try {
      const userobj = {
        userId: id,
        siteToken: token,
      };
      const res = await axios.post(
        `${REACT_APP_END_POINT_AUTHURL}/auth/v1/retrieveAuthToken`,
        userobj,
      );
      const response = res?.data?.response;
      localStorage.setItem('authToken', response.cognitoIdToken);
      // Dispatch actions to set user data and get subscription details
      dispatch(actions.setUserDataSuccess(response));
      dispatch(actions.getUserSubscriptionDetails());
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect to handle theme change and token presence
  useEffect(() => {
    // Set theme if provided in URL
    if (theme) {
      dispatch(setTheme(theme));
    }
    // Apply CSS font styles
    setCssFontstyles();
    // Check if token is present
    if (tokenPresent) {
      // Dispatch action to set user data
      dispatch(
        actions.setUserData({ handleSuccess: data => dispatch(actions.setUserDataSuccess(data)) }),
      );
      // Check if it's not an iframe and not in experience route
      if (!isIframe && window.location.pathname !== '/experience') {
        // Dispatch action to get user subscription details
        dispatch(actions.getUserSubscriptionDetails());
        // Set user authentication success
        setUserAuthsuccess();
      }
    } else if (isIframe) {
      // Make API call for authentication token in case of iframe
      makeApiCallforAuthToken(userId, SiteToken);
    }
  }, [tokenPresent, isIframe, theme]);

  // Determine routes based on token presence
  const routes =
    tokenPresent || UserData?.signInUserSession?.idToken?.jwtToken || isIframe
      ? userRoutes
      : guestRoutes;

  // Render routes based on token presence
  const mainContent = routes.map(route =>
    route.component ? (
      <>
        <Route
          key={route.name}
          path={route.path}
          exact={route.exact}
          name={route.name}
          element={<route.component />}
        />
        {(tokenPresent || UserData?.signInUserSession?.idToken?.jwtToken || isIframe) && (
          <Route path="buy" element={<BuyContainer />}>
            {buychipsroutes.map(elem =>
              elem.component ? (
                <>
                  <Route
                    index={elem.index}
                    key={elem.name}
                    path={elem.path}
                    exact={elem.exact}
                    name={elem.name}
                    element={<elem.component />}
                  />
                  {(tokenPresent || UserData?.signInUserSession?.idToken?.jwtToken || isIframe) && (
                    <Route path="editchip" element={<EditContainer />}>
                      {editchipsroutes.map(nestedelem =>
                        nestedelem.component ? (
                          <Route
                            index={nestedelem.index}
                            key={nestedelem.name}
                            path={nestedelem.path}
                            exact={nestedelem.exact}
                            name={nestedelem.name}
                            element={<nestedelem.component />}
                          />
                        ) : (
                          route.redirectRoute && (
                            <Route path="*" key={route.name} element={<Navigate to="/" />} />
                          )
                        ),
                      )}
                    </Route>
                  )}
                </>
              ) : (
                route.redirectRoute && (
                  <Route path="*" key={route.name} element={<Navigate to="/" />} />
                )
              ),
            )}
          </Route>
        )}
      </>
    ) : (
      route.redirectRoute && (
        <>
          {window.location?.pathname !== '/signin' &&
            !window.location?.pathname?.includes('/site-management') &&
            sessionStorage?.setItem('tempurl', window.location?.pathname + window.location.search)}
          <Route path="*" key={route.name} element={<Navigate to={route.path} />} />
        </>
      )
    ),
  );

  // Render session expiration modal if token is expired
  if (tokenExpire) {
    return (
      <Modal backdrop={false} centered fade={false} size="lg" isOpen>
        <ModalHeader className="justify-content-center">Session expired</ModalHeader>
        <ModalBody className="text-center">Session is expired please login again</ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            color="primary"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Login
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  // Render main content
  return (
    <>
      <div
        className="mainbody"
        data-theme={window.location.pathname === '/experience' ? 'dark' : Theme}
      >
        <Router>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="dark"
            progressClassName="toastProgress"
            bodyClassName="toastBody"
          />
          <AlertModal />
          {/* routes wrapper where the routes will be redirected  all routes will point to LayoutWrapper.jsx file for code splitting */}
          <Routes>
            <Route
              element={
                <LayoutWrapper
                  isAuthenticated={
                    tokenPresent || UserData?.signInUserSession?.idToken?.jwtToken || isIframe
                  }
                />
              }
            >
              {mainContent}
            </Route>
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
