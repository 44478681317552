import React from 'react';
import './chipsolutions.scss';

const SiteTitleExperience = ({ classname }) => (
  <p className={classname}>
    <span className="subtitlechip">Campaign:</span> {sessionStorage.getItem('sitename')}
  </p>
);

export default SiteTitleExperience;
