import { put } from 'redux-saga/effects';
import axiosMain, { setBaseUrl } from '../http/axios/axios_main';

export default function* errorHandler({
  endpoint,
  successHandler,
  failHandler,
  failHandlerType = '',
  payload = {},
  apiType = '',
  token = false,
  isLogoutCall = false,
  baseUrl,
  // showToast = "",
}) {
  setBaseUrl(baseUrl);
  if (apiType.trim() === '') {
    throw new Error('apiType is require');
  }
  try {
    let response;
    if (!token) {
      if (apiType === 'get') {
        response = yield axiosMain.get(endpoint);
      } else if (apiType === 'post') {
        response = yield axiosMain.post(endpoint, payload);
      } else if (apiType === 'put') {
        response = yield axiosMain.put(endpoint, payload);
      } else if (apiType === 'delete') {
        response = yield axiosMain.delete(endpoint);
      }
    } else {
      const authToken = yield localStorage.getItem('authToken');
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      // eslint-disable-next-line no-lonely-if
      if (apiType === 'get') {
        response = yield axiosMain.get(endpoint, config);
      } else if (apiType === 'post') {
        response = yield axiosMain.post(endpoint, payload, config);
      } else if (apiType === 'put') {
        response = yield axiosMain.put(endpoint, payload, config);
      } else if (apiType === 'delete') {
        response = yield axiosMain.delete(endpoint, config);
      }
    }
    if (response && (response.status === 200 || response.status === 201) && response.data) {
      yield successHandler(response.data);
      // showToast && successToast(response.data);
    } else if (response !== undefined && response.status !== undefined) {
      if (
        response.data.message !== undefined &&
        response.data.message !== '' &&
        typeof response.data.message === 'string'
      ) {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler(response.data.message);
        } else {
          yield put(failHandler(response.data.message));
        }
      } else if (failHandlerType === 'CUSTOM') {
        yield failHandler('Server error! Please try again.');
      } else {
        yield put(failHandler('Server error! Please try again.'));
      }
    } else if (failHandlerType === 'CUSTOM') {
      yield failHandler('Something went wrong! Please try again.');
    } else {
      yield put(failHandler('Something went wrong! Please try again.'));
    }
  } catch (error) {
    console.log(error);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler(error.response.data.errorMessage);
        } else {
          yield put(failHandler(error.response.data.errorMessage));
        }
      } else if (error.response.status === 401) {
        if (isLogoutCall) {
          successHandler({});
        } else {
          // yield put(logout({ logoutType: "manual" }));
        }
      } else if (error.response.status === 403) {
        localStorage.clear();
        // window.location.reload();
      } else if (error.response.status === 500) {
        if (failHandlerType === 'CUSTOM') {
          console.log(error.response.data.errorMessage);
          yield failHandler(error.response.data.errorMessage);
        } else {
          yield put(failHandler(error.response.data.errorMessage));
        }
      } else if (
        error.response.data.errorMessage !== undefined &&
        error.response.data.errorMessage !== '' &&
        typeof error.response.data.errorMessage === 'string'
      ) {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler(error.response.data.errorMessage);
        } else {
          yield put(failHandler(error.response.data.errorMessage));
        }
      } else if (failHandlerType === 'CUSTOM') {
        yield failHandler('Server error! Please try again.');
      } else {
        yield put(failHandler('Server error! Please try again.'));
      }
    } else if (failHandlerType === 'CUSTOM') {
      yield failHandler('Something went wrong! Please try again.');
    } else {
      yield put(failHandler('Something went wrong! Please try again.'));
    }
  }
}
