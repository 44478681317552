/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import SiteTitleExperience from 'views/user/BuyChips/ChipSolutions/SiteTitleExperience';
import { useLocation, Outlet, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import use3Dmodelcontext from 'Context/3DModelContext/use3Dmodelcontext';
import { CHIP_DEFAULTCOLOR } from 'utils/envConfig';
import { setChipModel, setModelColor, setCurrentlogotab } from 'store/actions';
import T3DModelState from 'Context/3DModelContext/3DModelState';
import SideBar from './SideBar';
import T3dEditor from '../chipEdit/3DEditor/T3dEditor';
import ScaleSlider from './ScaleSlider';
import '../chipEdit/style.css';

function EditContainer() {
  return (
    <div className="wrapper flex-column">
      <SideBar />
      <div className="wrapper w-100  justify-content-start chipnamewrapper">
        <SiteTitleExperience classname="chipsolutiontext" />
      </div>
      <div className="w-100  overflow-hidden">
        <div>
          <T3DModelState>
            <EditComponent />
          </T3DModelState>
        </div>
      </div>
    </div>
  );
}
export default EditContainer;

const EditComponent = () => {
  const location = useLocation();
  const { chipState } = useSelector(state => state.chips);
  const { state } = location;
  const { setModeltexture, setCurrentlogoTab, currentlogoTab } = use3Dmodelcontext();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    myLogoList: { list: logolist },
    isLoading: logoloading,
  } = useSelector(_state => _state?.chips);

  useEffect(() => {
    if (!location.pathname.includes('selected-chips')) {
      if (chipState) {
        const { chipColor, logoUrl, chipModel, chipModelDefault } = chipState?.packValue[id];
        dispatch(setModelColor(chipColor || CHIP_DEFAULTCOLOR));
        dispatch(setChipModel({ model: chipModel, isDefault: false }));
        if (logoUrl) {
          setModeltexture(prev => ({
            ...prev,
            logoDecal: `${logoUrl}?cacheblock=true` || '',
          }));
        } else {
          setModeltexture(prev => ({
            ...prev,
            logoDecal: '',
          }));
        }
      }
    }
  }, [chipState]);
  return (
    <Row>
      {location.pathname.includes('logo') && chipState && chipState?.packValue[id]?.customLogo && (
        <div className="logo-tab">
          <ul className="mb-3 d-flex tab-ul ">
            <li
              className={`pb-2 px-3 cp ${currentlogoTab === 'upload' ? 'tab-border' : ''}`}
              onClick={() => {
                setCurrentlogoTab('upload');
                dispatch(setCurrentlogotab('upload'));
              }}
            >
              Upload Logo
            </li>
            {(logoloading || logolist?.length > 0) && (
              <li
                className={`pb-2 px-3 cp ${currentlogoTab === 'logo' ? 'tab-border' : ''}`}
                onClick={() => {
                  if (!logoloading) {
                    setCurrentlogoTab('logo');
                    dispatch(setCurrentlogotab('logo'));
                  }
                }}
              >
                My Logos
              </li>
            )}
          </ul>
        </div>
      )}
      {location.pathname?.includes('color') && (
        <div className="logo-tab">
          <h5 className="text-start choose-color">Choose your Tag color</h5>
        </div>
      )}
      {location.pathname?.includes('my-chips') && (
        <div className="logo-tab">
          <h5 className="text-start choose-color my-chip-sub-heading mb-3">{id}</h5>
          <h5 className="text-capitalize border-0">{state?.packType}</h5>
        </div>
      )}
      <Col className="" xs={12} md={7}>
        <Outlet />
      </Col>
      <Col xs={12} md={5} className="my-3 my-md-0 position-relative">
        <T3dEditor />
        {location.pathname.includes('logo') && <ScaleSlider chipkey={id} />}
      </Col>
    </Row>
  );
};
