/* eslint-disable no-case-declarations */
import * as actionLabels from '../../actionLabels';

export const initialState = {
  cardLoading: {
    currentactivestep: 1,
    addCard: false,
    getCard: false,
    deleteCard: false,
    paymentInitiates: false,
    tempOrder: false,
    publishing: false,
  },
  cartTotal: 0,
  cardListData: null,
  isPaymentUnderProcess: false,
  subdetaildummy: [],
  isPaymentUnderprogressOrders: false,
  savedcard: null,
  subtotal: 0,
  chiptotal: 0,
  prorateAmount: 0,
  isManualPayment: 0,
  finalProrateAmount: 0,
  finalsubAmount: 0,
  tempOrderData: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // // reducer for add card details
    case actionLabels.SET_SAVED_CARD:
      return {
        ...state,
        savedcard: payload,
      };

    // // reducer for add card details
    case actionLabels.SET_DUMMY_SUBDETAIL:
      return {
        ...state,
        subdetaildummy: state?.subdetaildummy?.length > 0 ? state.subdetaildummy : payload,
      };

    case actionLabels.SET_FINAL_SUBTOTAL:
      return {
        ...state,
        finalsubAmount: state.subtotal,
      };
    case actionLabels.SET_FINAL_PRORATED:
      return {
        ...state,
        finalProrateAmount: state.prorateAmount,
      };
    case actionLabels?.SET_ORDERSMODAL_OPEN:
      return {
        ...state,
        isPaymentUnderprogressOrders: payload,
      };
    case actionLabels.SETCARTACTIVETEST:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          currentactivestep: payload,
        },
      };
    case actionLabels.STOP_LOADING_PAYMENT:
      return {
        ...state,
        cardLoading: {
          addCard: false,
          getCard: false,
          deleteCard: false,
          paymentInitiates: false,
          tempOrder: false,
        },
      };
    case actionLabels.SET_SUB_TOTAL:
      return {
        ...state,
        subtotal: payload,
      };
    case actionLabels.PAYMENT_INITIATES_FALSE:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          paymentInitiates: false,
        },
      };
    case actionLabels.PUBLISHING_LOADING:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          currentactivestep: 4,
          publishing: true,
        },
      };
    case actionLabels.PAYMENT_PROCESS_ONGOING:
      return {
        ...state,
        isPaymentUnderProcess: payload,
      };
    case actionLabels.PUBLISHING_LOADING_FALSE:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,

          publishing: false,
        },
      };
    case actionLabels.SET_PRORATED_TOTAL:
      return {
        ...state,
        prorateAmount: payload,
      };
    case actionLabels.SET_CHIP_TOTAL:
      return {
        ...state,
        chiptotal: payload,
      };
    case actionLabels.ADD_CARD_DETAILS:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          currentactivestep: 1,
          addCard: true,
        },
      };
    case actionLabels?.SET_ISMANUAL_PAYMENT:
      return {
        ...state,
        isManualPayment: payload,
      };
    case actionLabels.SET_CARD_TOTAL:
      const value =
        payload.type === 'clear'
          ? 0
          : payload.type === 'incremental'
          ? payload.amount
          : (payload.amount || 0) + state.cartTotal;
      return {
        ...state,
        cartTotal: value,
        isManualPayment: value,
      };
    case actionLabels.ADD_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          addCard: false,
        },
      };
    case actionLabels.ADD_CARD_DETAILS_FAIL:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          addCard: false,
        },
      };

    // // reducer for get card details
    case actionLabels.GET_CARD_DETAILS:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          getCard: true,
          currentactivestep: 1,
        },
      };
    case actionLabels.GET_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          getCard: false,
        },
        cardListData: payload,
      };
    case actionLabels.GET_CARD_DETAILS_FAIL:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          getCard: false,
        },
        cardListData: null,
      };

    // // reducer for delete card details
    case actionLabels.DELETE_CARD_DETAILS:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          deleteCard: true,
        },
      };
    case actionLabels.DELETE_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          deleteCard: false,
        },
      };
    case actionLabels.DELETE_CARD_DETAILS_FAIL:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          deleteCard: false,
        },
      };

    // // reducer for payment initiates
    case actionLabels.PAYMENT_INITIATES:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          currentactivestep: 3,
          paymentInitiates: true,
        },
      };
    case actionLabels.PAYMENT_INITIATES_SUCCESS:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          paymentInitiates: false,
        },
      };
    case actionLabels.PAYMENT_INITIATES_FAIL:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          paymentInitiates: false,
        },
      };
    // // reducer for temp order
    case actionLabels.CREATE_TEMP_ORDER:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          tempOrder: true,
          currentactivestep: 2,
        },
      };
    case actionLabels.CREATE_TEMP_ORDER_SUCCESS:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          tempOrder: false,
        },
        tempOrderData: payload,
      };
    case actionLabels.CREATE_TEMP_ORDER_FAIL:
      return {
        ...state,
        cardLoading: {
          ...state.cardLoading,
          tempOrder: false,
        },
        tempOrderData: null,
      };
    default:
      return state;
  }
};
