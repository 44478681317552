/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
// eslint-disable-next-line camelcase
import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { smallSlLabLogo, smallSlLabLogodark } from 'assets/images';
import { REACT_APP_SLLABSIMAGEURL_STATIC } from 'utils/envConfig';
import { dataItems } from '../../statics/sidebardata';
import './layout.css';

function Sidebar({ openSidebar, setMobileSidebar }) {
  // Redux state
  const { Theme } = useSelector(state => state.auth);
  const { isPaymentUnderProcess } = useSelector(state => state.payment);
  // Location
  const { pathname } = useLocation();

  // Close mobile sidebar when location changes
  useEffect(() => {
    setMobileSidebar(false);
  }, [pathname]);

  // Check if the route is active
  const isRouteActive = useCallback(
    values => {
      let isactive = '';
      for (let i = 0; i < values.length; i++) {
        const element = values[i];
        if (pathname.includes(element)) {
          isactive = 'activesidebar';
          break;
        }
      }
      return isactive;
    },
    [pathname],
  );

  return (
    <nav
      className={`d-none d-lg-block sidebarNav ${isPaymentUnderProcess ? 'ispaymentprocess' : ''} ${
        openSidebar && 'hide_sidebar'
      }`}
    >
      <div className="sidebar_wrapper">
        <div className={` ${openSidebar ? 'text-center respsidebarstart' : 'logo-start'} `}>
          {openSidebar ? (
            <NavLink to="/">
              {/* Render logo with appropriate theme */}
              <img
                src={
                  openSidebar && Theme === 'dark'
                    ? `${REACT_APP_SLLABSIMAGEURL_STATIC}${smallSlLabLogodark}`
                    : `${REACT_APP_SLLABSIMAGEURL_STATIC}${smallSlLabLogo}`
                }
                width={!openSidebar ? 'auto' : '20'}
                alt="brandLogo"
                className="img-fluid"
              />
            </NavLink>
          ) : (
            <div className="logo-text">Continuity Studio</div>
          )}
        </div>
        <ul className="flex flex-col gap-2 px-2">
          {/* Map through data items to render sidebar items */}
          {dataItems.map(item => (
            <li
              className={`sidebar-nav-item nonActive position-relative ${isRouteActive(
                item.otherActiveRoutes ? [...item?.otherActiveRoutes, item?.link] : [item.link],
              )} `}
              key={item.name}
            >
              <Link
                to={item.link}
                className={`color-black ${
                  openSidebar ? 'justify-content-center' : 'justify-content-start'
                } wrapper  `}
              >
                <div className="w-8 h-8 flex p-2 rounded-lg bg-[--sidebar-icon-bg] fill-[--label-text]">
                  {item.logo}
                </div>
                <p className={`${openSidebar && 'd-none'} linkname ospt-2`}>{item.name}</p>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default Sidebar;
