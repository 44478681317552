import {
  SET_CHIP_DESIGN_FAIL,
  UPDATE_LOGO_NAME_SUCCESS,
  UPDATE_LOGO_NAME_FAIL,
  VIEW_CHIPS_SUCCESS,
  VIEW_CHIPS,
  CLEAR_VIEW_CHIP,
  VIEW_CHIPS_FAIL,
  DONE_CHIP_QUANT,
  SET_CHIP_DESIGN_SUCCESS,
  SET_FINGER_ANIMATION,
  SET_CHIP_DESIGN,
  DONE_CHIP_DESIGN,
  UPDATE_LOGO_NAME,
  DELETE_CHIP_DESIGN_FAIL,
  DELETE_CHIP_DESIGN,
  DELETE_CHIP_DESIGN_SUCCESS,
  SET_SELECTED_CHIP_STATE,
  SET_SELECTED_CHIP_COLOR,
  LOGO_DELETE_SUCCESS,
  SET_SELECTED_MODAL,
  GET_CHIP_ORDER_STATE,
  LOGO_DELETE,
  GET_LIST_MYLOGO_SUCCESS,
  GET_LIST_MYLOGO_FAIL,
  GET_LIST_MYLOGO,
  SET_LOGO_URL_FAIL,
  SET_LOGO_URL_SUCCESS,
  SET_LOGO_URL,
  GET_LOGO_PRESIGNED_URL_SUCCESS,
  GET_LOGO_PRESIGNED_URL_FAIL,
  LOGO_DELETE_FAIL,
  SET_CHIP_ROTATION,
  GET_LOGO_PRESIGNED_URL,
  GET_CHIP_DESIGN_SUCCESS,
  GET_CHIP_DESIGN_FAIL,
  SET_LOGO_TAB,
  GET_CHIP_STATE,
  GET_CHIP_DESIGN,
  SET_CURRENT_SELECTED_LOGO,
} from 'store/actionLabels';

export const getChipState = () => ({
  type: GET_CHIP_STATE,
});

export const setCurrentlogotab = payload => ({
  type: SET_LOGO_TAB,
  payload,
});

export const getlogopresignedUrl = payload => ({
  type: GET_LOGO_PRESIGNED_URL,
  payload,
});

export const getlogopresignedUrlsuccess = payload => ({
  type: GET_LOGO_PRESIGNED_URL_SUCCESS,
  payload,
});

export const getlogopresignedUrlfail = payload => ({
  type: GET_LOGO_PRESIGNED_URL_FAIL,
  payload,
});

export const setuploadlogoUrl = payload => ({
  type: SET_LOGO_URL,
  payload,
});

export const setuploadlogoUrlsuccess = payload => ({
  type: SET_LOGO_URL_SUCCESS,
  payload,
});

export const setuploadlogoUrlfail = payload => ({
  type: SET_LOGO_URL_FAIL,
  payload,
});

export const getListLogo = payload => ({
  type: GET_LIST_MYLOGO,
  payload,
});

export const getListLogosuccess = payload => ({
  type: GET_LIST_MYLOGO_SUCCESS,
  payload,
});

export const getListLogofail = payload => ({
  type: GET_LIST_MYLOGO_FAIL,
  payload,
});

export const setCurrentSelectedLogo = payload => ({
  type: SET_CURRENT_SELECTED_LOGO,
  payload,
});

export const logoDelete = payload => ({
  type: LOGO_DELETE,
  payload,
});
export const logoDeletesuccess = payload => ({
  type: LOGO_DELETE_SUCCESS,
  payload,
});
export const logoDeletefail = payload => ({
  type: LOGO_DELETE_FAIL,
  payload,
});
export const chipdesignDelete = payload => ({
  type: DELETE_CHIP_DESIGN,
  payload,
});
export const setrotation = payload => ({
  type: SET_CHIP_ROTATION,
  payload,
});
export const chipdesignDeletesuccess = payload => ({
  type: DELETE_CHIP_DESIGN_SUCCESS,
  payload,
});

export const chipdesignDeletefail = payload => ({
  type: DELETE_CHIP_DESIGN_FAIL,
  payload,
});

export const setModelColor = payload => ({
  type: SET_SELECTED_CHIP_COLOR,
  payload,
});
export const setChipModel = payload => ({
  type: SET_SELECTED_MODAL,
  payload,
});
export const getChiporderstate = payload => ({
  type: GET_CHIP_ORDER_STATE,
  payload,
});

export const setSelectedChipType = payload => ({
  type: SET_SELECTED_CHIP_STATE,
  payload,
});

export const setChipDesign = payload => ({
  type: SET_CHIP_DESIGN,
  payload,
});

export const setChipDesignsuccess = payload => ({
  type: SET_CHIP_DESIGN_SUCCESS,
  payload,
});

export const setChipDesignfail = payload => ({
  type: SET_CHIP_DESIGN_FAIL,
  payload,
});

export const getChipDesign = payload => ({
  type: GET_CHIP_DESIGN,
  payload,
});

export const getChipDesignsuccess = payload => ({
  type: GET_CHIP_DESIGN_SUCCESS,
  payload,
});

export const getChipDesignfail = payload => ({
  type: GET_CHIP_DESIGN_FAIL,
  payload,
});

export const updateLogonameaction = payload => ({
  type: UPDATE_LOGO_NAME,
  payload,
});

export const updateLogonameSuccess = payload => ({
  type: UPDATE_LOGO_NAME_SUCCESS,
  payload,
});

export const updateLogonamefail = payload => ({
  type: UPDATE_LOGO_NAME_FAIL,
  payload,
});

export const viewChipsAction = payload => ({
  type: VIEW_CHIPS,
  payload,
});

export const viewChipsActionSuccess = payload => ({
  type: VIEW_CHIPS_SUCCESS,
  payload,
});

export const viewChipsActionFail = payload => ({
  type: VIEW_CHIPS_FAIL,
  payload,
});

export const clearViewChips = payload => ({
  type: CLEAR_VIEW_CHIP,
  payload,
});

export const setDonechipquant = payload => ({
  type: DONE_CHIP_QUANT,
  payload,
});

export const setDonechipDesign = payload => ({
  type: DONE_CHIP_DESIGN,
  payload,
});

export const setFingeranimation = payload => ({
  type: SET_FINGER_ANIMATION,
  payload,
});
