/* eslint-disable camelcase */

import { REACT_APP_SLLABSIMAGEURL_STATIC } from 'utils/envConfig';

// images(png/jpg)
export const NEW_CAMPAIGN_MODAL_IMAGE = 'website_assets/static-images/new-campaign.webp';
export const USERDUMMY = 'website_assets/static-images/avatar-3.jpg';
export const GRAPH_DOWN_SVG = 'website_assets/static-images/graph_down.svg';
export const GRAPH_UP_SVG = 'website_assets/static-images/graph_up.svg';
export const EDIT_IMG = 'website_assets/static-images/upload_img.svg';
export const EDIT_ICON = 'website_assets/static-images/Edit.png';
export const TOKEN = 'website_assets/static-images/token.jpg';
export const USERPROFILEDUMMY = 'website_assets/static-images/userdummy.jpg';
export const SUN = 'website_assets/static-images/sun.svg';
export const MOON = 'website_assets/static-images/moon.svg';
export const ACCOUNT_CREATED_USER = 'website_assets/static-images/usercreated.svg';
export const toasterclose = 'website_assets/static-images/toasterclose.svg';
export const FULLIPHONEIMAGENOTI = 'website_assets/static-images/FullIphoneFrame.svg';
export const CONTINUITY_DARK = 'website_assets/static-images/new_continuity_logo light.svg';
export const CONTINUITY_LIGHT = 'website_assets/static-images/new_continuity_logo.svg';
export const smallSlLabLogo = 'website_assets/static-images/new_continuity_small_logo.svg';
export const smallSlLabLogodark =
  'website_assets/static-images/new_continuity_small_logo light.svg';
export const ONBOARDING_BANNER_LIGHT = 'website_assets/static-images/onboarding_logo_light.svg';
export const ONBOARDING_BANNER_DARK = 'website_assets/static-images/onboarding_logo_dark.svg';
export const SUCCESS_ICON = 'website_assets/static-images/tick.png';
export const FAILURE_ICON = 'website_assets/static-images/close.png';
export const IPHONE_FRAME = 'website_assets/static-images/iPhone 14 Pro Maxdefault.svg';
export const IPHONE_BANNER_WHITE_BG =
  'editor-assets-images/phone-headers/phone_banner_white_bg.svg';
export const IPHONE_BANNER_BLACK_BG =
  'editor-assets-images/phone-headers/phone_banner_black_bg.svg';
export const LOADER_GIF = 'website_assets/static-images/loader.gif';
export const BLACK_LOADER_GIF = 'website_assets/static-images/black.gif';
export const WHITE_LOADER_GIF = 'website_assets/static-images/white.gif';
export const GREY_BG_LOADER = 'website_assets/static-images/greyBG.gif';

export const ICON_SEARCH_DARK = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_SEARCH.svg`;
export const ICON_SEARCH_LIGHT = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_SEARCH_DARK.svg`;
export const ICON_INFOICON = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_INFOICON.svg`;
export const ICON_UPLOAD = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_UPLOAD.svg`;
export const ICON_ACTIVATE = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_ACTIVATE.svg`;
export const ICON_LIVE = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_LIVE.svg`;
export const ICON_INACTIVE = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_INACTIVE.svg`;
export const ICON_PROCESSING = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_PROCESSING.svg`;

export const ICON_FRAME1_LIGHT = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_FRAME1_LIGHT.svg`;
export const ICON_FRAME2_LIGHT = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_FRAME2_LIGHT.svg`;
export const ICON_FRAME3_LIGHT = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_FRAME3_LIGHT.svg`;
export const ICON_FRAME1_DARK = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_FRAME1_DARK.svg`;
export const ICON_FRAME2_DARK = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_FRAME2_DARK.svg`;
export const ICON_FRAME3_DARK = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_FRAME3_DARK.svg`;
export const ICON_MOBILE_BODY = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_MOBILE_BODY.svg`;
export const ICON_NIKE = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_NIKE.svg`;

export const Step1 = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_STEP1.svg`;
export const Step1dark = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_STEP1_DARK.svg`;
export const Step2 = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_STEP2.svg`;
export const Step3 = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_STEP3.svg`;
export const Step4 = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_STEP4.svg`;

export const ADD_ICON = 'website_assets/static-images/AddIcon.svg';
export const DEFAULT_DARK_ICON = 'website_assets/static-images/default_dark.svg';
export const DEFAULT_LIGHT_ICON = 'website_assets/static-images/default_light.svg';
export const ADD_CIRCLE_ICON = 'website_assets/static-images/Add_circle_icon.svg';

export const FingerIcon = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_FINGER.svg`;
export const SHARE = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_SHARE.svg`;
export const LOCK = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_LOCK.png`;
export const VISA = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_VISA.png`;
export const PAYMENTERR = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_ERROR_PAYMENT.png`;
export const MENU_DARK = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_MENU_DARK.svg`;
export const MENU_LIGHT = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_MENU_LIGHT.svg`;
export const BANNEREDIT_PROFILE_IMG = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_BANNER_EDIT.svg`;
export const CREATE_TEMPLATE = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_CREATE_TEMPLATE.svg`;
export const BANNEREDIT_PROFILE_IMG_LIGHT = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_BANNEREDIT_LIGHT.svg`;
export const CARTCROSSICON = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_CROSS_ICON_CART.svg`;

export const PRORATED_FEE = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_PRORATED.svg`;
export const BROKEN_DASHBOARD = `${REACT_APP_SLLABSIMAGEURL_STATIC}user-assets-images/ICON_BROKEN_DASHBOARD.svg`;

export { default as ChipCheckoutSvg } from './checkout';
export { default as ChipQuantitySvg } from './chipquantity';
export { default as ChipdesignSVG } from './chipdesign';
export { default as ChipstyleSvg } from './chipstyle';
