/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import T3DmodelContext from './3DmodelContext';

function use3Dmodelcontext() {
  return useContext(T3DmodelContext);
}

export default use3Dmodelcontext;
