/* eslint-disable react/button-has-type */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useModalContext from 'Context/ModalContext/useModalContext';
import { SUCCESS_ICON, FAILURE_ICON } from 'assets/images';
import { REACT_APP_SLLABSIMAGEURL_STATIC } from 'utils/envConfig';
import CustomModal from './UI/Model/CustomModal';

const AlertModal = () => {
  const navigate = useNavigate();
  const {
    modalState: {
      open,
      title,
      message,
      notifyType,
      isCardPayment,
      showPrimaryButton,
      handleClick,
      redirectURL,
      btntext,
    },
    closeModal,
  } = useModalContext();
  const handleCloseModal = () => {
    if (redirectURL) {
      navigate(redirectURL);
    }
    closeModal();
  };
  const handleOnCOnfirm = () => {
    if (handleClick) {
      handleClick();
      handleCloseModal();
    } else {
      handleCloseModal();
    }
  };
  return (
    <>
      {open && (
        <CustomModal width="35%" title={title} isOpen={open} onClose={handleCloseModal}>
          <div className="success-modal-content-container">
            <div className="wrapper justify-content-center">
              {notifyType && (
                <img
                  src={
                    notifyType === 'success'
                      ? `${REACT_APP_SLLABSIMAGEURL_STATIC}${SUCCESS_ICON}`
                      : notifyType === 'error'
                      ? `${REACT_APP_SLLABSIMAGEURL_STATIC}${FAILURE_ICON}`
                      : ''
                  }
                  style={{ marginTop: '30px', marginBottom: '24px !important' }}
                  className="d-flex mx-auto success-icon mb-4 img_successfl"
                  alt="successicon"
                />
              )}
            </div>
            {message && (
              <p className="text-center text_primary text-center alertmessage mb-3"> {message} </p>
            )}
            {isCardPayment && (
              <div className="wrapper justify-content-center">
                <button
                  type="button"
                  style={{ marginTop: '35px' }}
                  className=" button1  cancle-logout list_btn  py-2 "
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            )}
            {showPrimaryButton && !isCardPayment && (
              <div className="wrapper justify-content-center">
                <button
                  type="button"
                  className="bordernone me-2 py-2 logout-btn successalertbtn list_btn btn-background"
                  onClick={handleOnCOnfirm}
                >
                  <p className="ospt-2">{btntext || 'Ok'}</p>
                </button>
                <button
                  type="button"
                  className=" button1 ms-2 cancle-logout list_btn py-2 "
                  onClick={handleCloseModal}
                >
                  <p className="ospt-2">Cancel</p>
                </button>
              </div>
            )}
            {redirectURL && (
              <div
                className="wrapper justify-content-center"
                style={{ width: '90%', margin: 'auto' }}
              >
                <button
                  className="btn  button1  successalertbtn list_btn bordernone text-white px-4 btn-background"
                  onClick={handleCloseModal}
                >
                  {btntext || 'Ok'}
                </button>
              </div>
            )}
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default AlertModal;
