import React, { useState, useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import RestrictUser from 'components/RestrictUser/RestrictUser';
import { REACT_APP_SLLABSIMAGEURL_STATIC, REACT_APP_LOGIN_VIDEO } from 'utils/envConfig';
import { Spinner } from 'components';
import { setTheme } from 'store/actions';
import Layout from './Layout';

// Define routes that do not require authentication
const routes = ['/experience', '/onboarding'];
// Define routes that require authentication
const authRoutes = ['/signin', '/forgotpassword', '/signup', '/twofactorAuth', '/otp'];

function LayoutWrapper({ isAuthenticated }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const Location = useLocation();
  const dispatch = useDispatch();
  const Theme = localStorage.getItem('theme');

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Set theme for authenticated users
  useEffect(() => {
    if (!isAuthenticated && authRoutes.includes(Location?.pathname)) {
      if (Theme !== 'dark') {
        localStorage.setItem('theme', 'dark');
        dispatch(setTheme('dark'));
      }
    }
  }, [Location?.pathname]);

  // Render different content based on authentication status and window width
  if (isAuthenticated) {
    return (
      <>
        {windowWidth < 500 && Location.pathname !== '/experience' ? (
          <RestrictUser />
        ) : Location.pathname === '/experience' ? (
          <Outlet />
        ) : (
          <Suspense fallback={<Spinner />}>
            <Layout>
              <Outlet />
            </Layout>
          </Suspense>
        )}
      </>
    );
  }
  return (
    <>
      {windowWidth < 500 && Location.pathname !== '/experience' ? (
        <RestrictUser />
      ) : Location.pathname === '/experience' ? (
        <Outlet />
      ) : (
        <Suspense fallback={<Spinner />}>
          <div style={{ position: 'relative', height: '100%' }}>
            {!routes.includes(Location.pathname) && (
              <>
                {/* Render video background for unauthenticated routes */}
                <video autoPlay muted loop id="myVideo" className="video-background">
                  <source
                    src={`${REACT_APP_SLLABSIMAGEURL_STATIC}${REACT_APP_LOGIN_VIDEO}`}
                    type="video/mp4"
                  />
                </video>
                {/* Apply linear gradient on top of video background */}
                <div className="auth_linear_gradient" />
              </>
            )}
            <Outlet />
          </div>
        </Suspense>
      )}
    </>
  );
}

export default LayoutWrapper;
