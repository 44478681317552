export const setCssFontstyles = () => {
  if (navigator.userAgent.includes('Windows') || navigator.userAgent.includes('Linux')) {
    document.documentElement.style.setProperty('--pt-2', '2px');
    document.documentElement.style.setProperty('--pt-3', '3px');
    document.documentElement.style.setProperty('--pt-1', '1px');
    document.documentElement.style.setProperty('--pt-4', '4px');
    document.documentElement.style.setProperty('--pt-5', '5px');
    document.documentElement.style.setProperty('--pt-6', '6px');
    document.documentElement.style.setProperty('--pt-7', '7px');
    document.documentElement.style.setProperty('--pt-8', '8px');
    document.documentElement.style.setProperty('--pt-9', '9px');
    document.documentElement.style.setProperty('--bottomupdateosfont', '21px');
    document.documentElement.style.setProperty('--osfontheadersearch', '12.5px');
  }
};
