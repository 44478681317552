import { put, takeLatest } from 'redux-saga/effects';
import { handleToastfunction } from 'utils/helpers';
import {
  getCmsDataStart,
  getCmsDataSuccess,
  getCmsDataFail,
  getSocialMediaLinkStart,
  getSocialMediaLinkSuccess,
  getSocialMediaLinkFail,
} from '../../actions';
import apiHandler from '../../../utils/apiHandler';
import * as actionLabels from '../../actionLabels';

export function* getCmsDataSaga({ payload }) {
  const { cmsType } = payload;
  yield put(getCmsDataStart());
  yield apiHandler({
    endpoint: `user/v1/commonCms?type=${cmsType}`,
    successHandler: yield function* (response) {
      yield put(getCmsDataSuccess(response?.data));
    },
    failHandler: yield function* (response) {
      yield put(getCmsDataFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    apiType: 'get',
  });
}
export function* getSocialMediaLinkSaga() {
  yield put(getSocialMediaLinkStart());
  yield apiHandler({
    endpoint: `user/v1/commonCms?type=socialMedia`,
    successHandler: yield function* (response) {
      yield put(getSocialMediaLinkSuccess(response?.data));
    },
    failHandler: yield function* (response) {
      yield put(getSocialMediaLinkFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    apiType: 'get',
  });
}

function* watcher() {
  yield takeLatest(actionLabels.GET_CMS_DATA, getCmsDataSaga);
  yield takeLatest(actionLabels.GET_SOCIAL_MEDIA_LINK, getSocialMediaLinkSaga);
}
export default watcher;
