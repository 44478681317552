import * as actionLabels from '../../../actionLabels';

export const initialState = {
  isLoading: {
    getOverallCountryGeographyList: false,
    getOverallRegionGeographyList: false,
    getOverallCountryGeographyMap: false,
  },
  overallCountryGeographyData: null,
  overallRegionGeographyData: null,
  overallCountryGeographyMapData: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // // reducer for get overall country geography GA list
    case actionLabels.GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getOverallCountryGeographyList: true,
        },
        overallCountryGeographyData: null,
      };
    case actionLabels.GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getOverallCountryGeographyList: false,
        },
        overallCountryGeographyData: payload,
      };
    case actionLabels.GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getOverallCountryGeographyList: false,
        },
        overallCountryGeographyData: null,
      };

    // // reducer for get overall region geography GA list
    case actionLabels.GET_REGION_OVERALL_GEOGRAPHY_GA_LIST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getOverallRegionGeographyList: true,
        },
        overallRegionGeographyData: null,
      };
    case actionLabels.GET_REGION_OVERALL_GEOGRAPHY_GA_LIST_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getOverallRegionGeographyList: false,
        },
        overallRegionGeographyData: payload,
      };
    case actionLabels.GET_REGION_OVERALL_GEOGRAPHY_GA_LIST_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getOverallRegionGeographyList: false,
        },
        overallRegionGeographyData: null,
      };

    // // reducer for get google analysis geography country map
    case actionLabels.GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getOverallCountryGeographyMap: true,
        },
        overallCountryGeographyMapData: null,
      };
    case actionLabels.GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getOverallCountryGeographyMap: false,
        },
        overallCountryGeographyMapData: payload,
      };
    case actionLabels.GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP_FALL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getOverallCountryGeographyMap: false,
        },
        overallCountryGeographyMapData: null,
      };

    default:
      return state;
  }
};
