import { all } from 'redux-saga/effects';
import authSaga from './auth/auth';
import paymentSaga from './payment';
import promoCodeSaga from './promocode/promocodeSaga';
import siteSaga from './siteManagement/site';
import siteSpecificAnalysisSaga from './siteManagement/siteSpecificAnalysis';
import siteOverallAnalysisSaga from './siteManagement/overallAnalysis';
import Chips from './chips/chips';
import visitorsSaga from './visitorManagement';
import subSaga from './subscription/subscription';
import cmsSaga from './cms';

export function* rootWatcher() {
  yield all([
    authSaga(),
    promoCodeSaga(),
    paymentSaga(),
    siteSaga(),
    siteSpecificAnalysisSaga(),
    siteOverallAnalysisSaga(),
    Chips(),
    visitorsSaga(),
    subSaga(),
    cmsSaga(),
  ]);
}
