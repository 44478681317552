import { put, takeLatest } from 'redux-saga/effects';
import { handleToastfunction } from 'utils/helpers';
import {
  getAllVisitorsListStart,
  getAllVisitorsListSuccess,
  getAllVisitorsListFail,
  getVisitorsDetailsStart,
  getVisitorsDetailsSuccess,
  getVisitorsDetailsFail,
  getVisitorsSiteStart,
  getVisitorsSiteSuccess,
  getVisitorsSiteFail,
} from '../../actions';
import apiHandler from '../../../utils/apiHandler';
import * as actionLabels from '../../actionLabels';

export function* getVisitorsListSaga({ payload }) {
  const { searchUrl, filterUrl, limit, skip, siteId } = payload;
  yield put(getAllVisitorsListStart());
  yield apiHandler({
    endpoint: `user/v1/site/userSitesVisitors?sortOrder=DESC&limit=${limit}&skip=${skip}${filterUrl}${searchUrl}${siteId}`,
    successHandler: yield function* (response) {
      yield put(getAllVisitorsListSuccess(response?.data));
    },
    failHandler: yield function* (response) {
      yield put(getAllVisitorsListFail());
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    apiType: 'get',
  });
}
export function* getVisitorsDetailsSaga({ payload }) {
  yield put(getVisitorsDetailsStart());
  yield apiHandler({
    endpoint: `user/v1/site/visitor/${payload}`,
    successHandler: yield function* (response) {
      yield put(getVisitorsDetailsSuccess(response?.data));
    },
    failHandler: yield function* (response) {
      yield put(getVisitorsDetailsFail());
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    apiType: 'get',
  });
}
export function* getVisitorsSiteSaga({ payload }) {
  const { URL, visitorId } = payload;
  yield put(getVisitorsSiteStart());
  yield apiHandler({
    endpoint: `user/v1/site/siteVisitor/${visitorId}?sortOrder=DESC${URL}`,
    successHandler: yield function* (response) {
      yield put(getVisitorsSiteSuccess(response?.data));
    },
    failHandler: yield function* (response) {
      yield put(getVisitorsSiteFail());
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    apiType: 'get',
  });
}

function* watcher() {
  yield takeLatest(actionLabels.GET_ALL_VISITORS_LIST, getVisitorsListSaga);
  yield takeLatest(actionLabels.GET_VISITORS_DETAILS, getVisitorsDetailsSaga);
  yield takeLatest(actionLabels.GET_VISITORS_SITE_LIST, getVisitorsSiteSaga);
}
export default watcher;
