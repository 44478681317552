import * as actionLabels from '../../actionLabels';

// // action for get subscription list

// // action for get subscription charge
export const getSubscriptionChargeStart = () => ({
  type: actionLabels.GET_SUBSCRIPTION_CHARGE_START,
});
export const getSubscriptionCharge = payload => ({
  type: actionLabels.GET_SUBSCRIPTION_CHARGE,
  payload,
});
export const getSubscriptionChargeSuccess = payload => ({
  type: actionLabels.GET_SUBSCRIPTION_CHARGE_SUCCESS,
  payload,
});
export const getSubscriptionChargeFail = payload => ({
  type: actionLabels.GET_SUBSCRIPTION_CHARGE_FAIL,
  payload,
});

// // action for cancel subscription
export const cancelSubscriptionStart = () => ({
  type: actionLabels.CANCEL_SUBSCRIPTION_START,
});
export const cancelSubscription = payload => ({
  type: actionLabels.CANCEL_SUBSCRIPTION,
  payload,
});
export const cancelSubscriptionSuccess = payload => ({
  type: actionLabels.CANCEL_SUBSCRIPTION_SUCCESS,
  payload,
});
export const cancelSubscriptionFail = payload => ({
  type: actionLabels.CANCEL_SUBSCRIPTION_FAIL,
  payload,
});

// // action for reactive subscription
export const reactiveSubscriptionStart = () => ({
  type: actionLabels.REACTIVE_SUBSCRIPTION_START,
});
export const reactiveSubscription = payload => ({
  type: actionLabels.REACTIVE_SUBSCRIPTION,
  payload,
});
export const reactiveSubscriptionSuccess = payload => ({
  type: actionLabels.REACTIVE_SUBSCRIPTION_SUCCESS,
  payload,
});
export const reactiveSubscriptionFail = payload => ({
  type: actionLabels.REACTIVE_SUBSCRIPTION_FAIL,
  payload,
});
