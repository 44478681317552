import * as actionLabels from '../../../actionLabels';

export const initialState = {
  isLoading: {
    getSite: false,
    addSite: false,
    deleteSite: false,
    enableDisableSite: false,
  },
  ViewSiteData: null,
  siteData: null,
  siteViewLayout: 'tabular',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // // set site view layout reducer
    case actionLabels.SET_SITE_VIEW_LAYOUT: {
      return {
        siteViewLayout: payload,
      };
    }

    // // reducer for add sites
    case actionLabels.VIEW_SITE_DATA:
      return {
        ...state,
        ViewSiteData: payload,
      };
    case actionLabels.ADD_SITE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addSite: true,
        },
      };
    case actionLabels.ADD_SITE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addSite: false,
        },
      };
    case actionLabels.ADD_SITE_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addSite: false,
        },
      };

    // // reducer for delete sites
    case actionLabels.DELETE_SITE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteSite: true,
        },
      };
    case actionLabels.DELETE_SITE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteSite: false,
        },
      };
    case actionLabels.DELETE_SITE_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteSite: false,
        },
      };

    // // reducer for enable and disable sites
    case actionLabels.ENABLE_DISABLE_SITE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          enableDisableSite: true,
        },
      };
    case actionLabels.ENABLE_DISABLE_SITE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          enableDisableSite: false,
        },
      };
    case actionLabels.ENABLE_DISABLE_SITE_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          enableDisableSite: false,
        },
      };

    default:
      return state;
  }
};
