import React from 'react';

const ChipQuantitySvg = ({ color }) => (
  <svg
    width="20"
    height="20"
    style={{ color }}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icons / Quantity">
      <circle id="Ellipse 2518" stroke={color} cx="5" cy="14" r="2" fill="#949494" />
      <circle id="Ellipse 2519" stroke={color} cx="15" cy="14" r="2" fill="#949494" />
      <circle id="Ellipse 2520" stroke={color} cx="10" cy="6" r="2" fill="#949494" />
    </g>
  </svg>
);

export default ChipQuantitySvg;
