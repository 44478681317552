import React from 'react';

const ChipstyleSvg = ({ color }) => (
  <svg
    width="20"
    style={{ color }}
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7301_1134)">
      <path
        d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
        stroke-width="1.4"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83398 9.99967C5.83398 11.1047 6.27297 12.1646 7.05437 12.946C7.83577 13.7274 8.89558 14.1663 10.0007 14.1663C11.1057 14.1663 12.1655 13.7274 12.9469 12.946C13.7283 12.1646 14.1673 11.1047 14.1673 9.99967C14.1673 8.89461 13.7283 7.8348 12.9469 7.0534C12.1655 6.27199 11.1057 5.83301 10.0007 5.83301C8.89558 5.83301 7.83577 6.27199 7.05437 7.0534C6.27297 7.8348 5.83398 8.89461 5.83398 9.99967Z"
        stroke-width="1.4"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 2.5V5.83333"
        stroke-width="1.4"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 14.167V17.5003"
        stroke-width="1.4"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 10H5.83333"
        stroke-width="1.4"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.166 10H17.4993"
        stroke-width="1.4"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.3039 4.69434L12.9473 7.051"
        stroke-width="1.4"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.05393 12.9492L4.69727 15.3059"
        stroke-width="1.4"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.69727 4.69434L7.05393 7.051"
        stroke-width="1.4"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9473 12.9492L15.3039 15.3059"
        stroke-width="1.4"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7301_1134">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ChipstyleSvg;
