import axios from 'axios';
import { GlobalLogout } from 'utils/helpers';

const axiosMain = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setBaseUrl = baseUrl => {
  axiosMain.defaults.baseURL = baseUrl;
};

axiosMain.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 403 || error.response.status === 401) {
      const isToken = new URLSearchParams(window.location.search).get('isIframe');
      if (!isToken) {
        GlobalLogout(localStorage.getItem('theme'), true);
      }
      console.log(error);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export default axiosMain;
