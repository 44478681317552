import { put, takeLatest } from 'redux-saga/effects';
import { handleToastfunction } from 'utils/helpers';
import {
  getlogopresignedUrlsuccess,
  getlogopresignedUrlfail,
  setuploadlogoUrlfail,
  setuploadlogoUrlsuccess,
  getListLogosuccess,
  getListLogofail,
  logoDeletesuccess,
  logoDeletefail,
  getListLogo,
  setCurrentSelectedLogo,
  setChipDesignfail,
  getChipDesignsuccess,
  viewChipsActionSuccess,
  getChipDesignfail,
  chipdesignDeletesuccess,
  chipdesignDeletefail,
} from 'store/actions';
import { REACT_APP_END_POINT_USER_URL } from 'utils/envConfig';
import * as actionLabels from '../../actionLabels/index';
import apiHandler from '../../../utils/apiHandler';

export function* getlogopresignedUrlSaga(action) {
  const { payload } = action;
  const { fileName, onpresignedsuccess, chipId, ext } = payload;
  try {
    yield apiHandler({
      endpoint: `/user/v1/chip/get-signed-url?fileName=${fileName}&ext=${ext}&type=chip_design&chipId=${chipId}`,
      successHandler: yield function* (response) {
        onpresignedsuccess(response.data);
        yield put(getlogopresignedUrlsuccess({ data: response.data }));
      },
      failHandler: yield function* (response) {
        yield put(getlogopresignedUrlfail(response));
        handleToastfunction({ status: 'error', title: response });
      },
      failHandlerType: 'CUSTOM',
      apiType: 'get',
      baseUrl: REACT_APP_END_POINT_USER_URL,
    });
  } catch (err) {
    console.log(err);
  }
}
export function* setLogoUrlSaga(action) {
  const { payload } = action;
  yield apiHandler({
    endpoint: `/user/v1/chip/upload-logo`,
    successHandler: yield function* (response) {
      yield put(setuploadlogoUrlsuccess({ data: response?.data }));
      yield put(getListLogo());
      payload.handleSucess();
      yield put(setCurrentSelectedLogo(response?.data));
    },
    failHandler: yield function* (response) {
      yield put(setuploadlogoUrlfail(response));
      handleToastfunction({ status: 'error', title: response });
      payload.handleError();
    },
    failHandlerType: 'CUSTOM',
    apiType: 'post',
    baseUrl: REACT_APP_END_POINT_USER_URL,
    payload,
  });
}
export function* uploadLogonamesaga(action) {
  const { payload } = action;
  yield apiHandler({
    endpoint: `/user/v1/chip/update-logo`,
    successHandler: yield function* () {
      handleToastfunction({ status: 'success', title: 'Name updated successfully' });
      payload.handleSucess();
      yield put(getListLogo());
    },
    failHandler: yield function* (response) {
      yield put(setuploadlogoUrlfail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    apiType: 'put',
    baseUrl: REACT_APP_END_POINT_USER_URL,
    payload,
  });
}
export function* getListmylogosaga() {
  try {
    yield apiHandler({
      endpoint: `user/v1/chip/list-logo?skip=0&limit=100`,
      successHandler: yield function* (response) {
        yield put(getListLogosuccess({ data: response?.data }));
      },
      failHandler: yield function* (response) {
        yield put(getListLogofail(response));
        handleToastfunction({ status: 'error', title: response });
      },
      failHandlerType: 'CUSTOM',
      apiType: 'get',
      baseUrl: REACT_APP_END_POINT_USER_URL,
    });
  } catch (err) {
    console.log(err);
  }
}
export function* deleteLogoSaga(action) {
  const { payload } = action;
  try {
    yield apiHandler({
      endpoint: `/user/v1/chip/remove-logo?logoId=${payload?.logoid}`,
      successHandler: yield function* (response) {
        payload.handlesuccess('success', '');
        yield put(logoDeletesuccess({ data: response, logoid: payload.logoid }));
      },
      failHandler: yield function* (response) {
        payload.handlesuccess('error', response);
        yield put(logoDeletefail(response));
      },
      failHandlerType: 'CUSTOM',
      apiType: 'delete',
      baseUrl: REACT_APP_END_POINT_USER_URL,
    });
  } catch (err) {
    console.log(err);
  }
}
export function* setChipdesignSaga(action) {
  const { payload } = action;
  try {
    yield apiHandler({
      endpoint: `/user/v1/chip/create-chip-design`,
      successHandler: yield function* (response) {
        yield payload.handlesuccess('success', '', response.data);
      },
      failHandler: yield function* (response) {
        payload.handlesuccess('error', response);
        yield put(setChipDesignfail(response));
      },
      failHandlerType: 'CUSTOM',
      apiType: 'post',
      baseUrl: REACT_APP_END_POINT_USER_URL,
      payload,
    });
  } catch (err) {
    console.log(err);
  }
}
export function* GetChipdesignSaga(action) {
  const { payload } = action;
  try {
    yield apiHandler({
      endpoint: `/user/v1/chip/list-chip-design?limit=${payload.limit}&skip=${payload.skip}&chipId=${payload?.chipId}`,
      successHandler: yield function* (response) {
        yield put(getChipDesignsuccess({ data: response?.data }));
      },
      failHandler: yield function* (response) {
        yield put(getChipDesignfail(response));
      },
      failHandlerType: 'CUSTOM',
      apiType: 'get',
      baseUrl: REACT_APP_END_POINT_USER_URL,
    });
  } catch (err) {
    console.log(err);
  }
}
export function* deleteChipSaga(action) {
  const { payload } = action;
  try {
    yield apiHandler({
      endpoint: `/user/v1/chip/delete-chip-design?chipDesignId=${payload?.chipDesignId}`,
      successHandler: yield function* (response) {
        payload.handlesuccess('success', '');
        yield put(
          chipdesignDeletesuccess({ data: response?.data, chipDesignId: payload.chipDesignId }),
        );
      },
      failHandler: yield function* (response) {
        yield put(chipdesignDeletefail(response));
      },
      failHandlerType: 'CUSTOM',
      apiType: 'delete',
      baseUrl: REACT_APP_END_POINT_USER_URL,
    });
  } catch (err) {
    console.log(err);
  }
}

export function* viewChipsSaga(action) {
  const { payload } = action;
  try {
    yield apiHandler({
      endpoint: `/user/v1/site/connected_chip/${payload?.siteId}`,
      successHandler: yield function* (response) {
        yield put(viewChipsActionSuccess({ data: response?.data }));
      },
      failHandler: yield function* (response) {
        yield put(chipdesignDeletefail(response));
      },
      failHandlerType: 'CUSTOM',
      apiType: 'get',
      baseUrl: REACT_APP_END_POINT_USER_URL,
    });
  } catch (err) {
    console.log(err);
  }
}

function* watcher() {
  yield takeLatest(actionLabels.SET_LOGO_URL, setLogoUrlSaga);
  yield takeLatest(actionLabels.UPDATE_LOGO_NAME, uploadLogonamesaga);
  yield takeLatest(actionLabels.GET_LOGO_PRESIGNED_URL, getlogopresignedUrlSaga);
  yield takeLatest(actionLabels.GET_LIST_MYLOGO, getListmylogosaga);
  yield takeLatest(actionLabels.LOGO_DELETE, deleteLogoSaga);
  yield takeLatest(actionLabels.DELETE_CHIP_DESIGN, deleteChipSaga);
  yield takeLatest(actionLabels.SET_CHIP_DESIGN, setChipdesignSaga);
  yield takeLatest(actionLabels.VIEW_CHIPS, viewChipsSaga);
  yield takeLatest(actionLabels.GET_CHIP_DESIGN, GetChipdesignSaga);
}
export default watcher;
