import { put, takeLatest } from 'redux-saga/effects';
import { REACT_APP_END_POINT_USER_URL, REACT_APP_END_POINT_STRIPE_URL } from 'utils/envConfig';

// // static import
import { handleToastfunction } from 'utils/helpers';
import {
  addCardDetailsStart,
  addCardDetailsSuccess,
  addCardDetailsFail,
  getCardDetailsStart,
  getCardDetailsSuccess,
  getCardDetailsFail,
  deleteCardDetailsStart,
  deleteCardDetailsSuccess,
  deleteCardDetailsFail,
  paymentInitiatesStart,
  // paymentInitiatesSuccess,
  paymentInitiatesFail,
  createTempOrderStart,
  createTempOrderSuccess,
  createTempOrderFail,
} from '../../actions/index';

import apiHandler from '../../../utils/apiHandler';
import * as actionLabels from '../../actionLabels';

// // saga for add card details
export function* addCardDetailsSaga({ payload }) {
  yield put(addCardDetailsStart());
  const { cardDetails, onSuccessHandler, onError } = payload;
  yield apiHandler({
    endpoint: `payments/v1/card`,
    successHandler: yield function* () {
      yield put(addCardDetailsSuccess());
      // toast.success(response?.data?.message);
      yield onSuccessHandler();
    },
    failHandler: yield function* (response) {
      yield put(addCardDetailsFail());
      yield onError(response);
    },
    failHandlerType: 'CUSTOM',
    baseUrl: REACT_APP_END_POINT_STRIPE_URL,
    apiType: 'post',
    payload: cardDetails,
  });
}

// // saga for get card details
export function* getCardDetailsSaga() {
  yield put(getCardDetailsStart());
  yield apiHandler({
    endpoint: `payments/v1/card`,
    successHandler: yield function* (response) {
      yield put(getCardDetailsSuccess(response?.data?.cards?.data));
    },
    failHandler: yield function* (response) {
      yield put(getCardDetailsFail());
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: REACT_APP_END_POINT_STRIPE_URL,
    apiType: 'get',
  });
}

// // saga for delete card details
export function* deleteCardDetailsSaga({ payload }) {
  const { apiPayload, onSuccessHandler } = payload;
  yield put(deleteCardDetailsStart());
  yield apiHandler({
    endpoint: `payments/v1/card`,
    successHandler: yield function* (response) {
      yield put(deleteCardDetailsSuccess());
      yield onSuccessHandler();
      handleToastfunction({ status: 'success', title: response?.data?.message });
    },
    failHandler: yield function* (response) {
      yield put(deleteCardDetailsFail());
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: REACT_APP_END_POINT_STRIPE_URL,
    payload: apiPayload,
    apiType: 'put',
  });
}

// // saga for payment initiates
export function* paymentInitiatesSaga({ payload }) {
  const { apiPayload, onSuccessHandler, onErorHandler } = payload;
  yield put(paymentInitiatesStart());
  yield apiHandler({
    endpoint: `payments/v1/create`,
    successHandler: yield function* (data) {
      yield onSuccessHandler(data);
      // toast.success('Payment has ben initiated');
    },
    failHandler: yield function* (response) {
      yield put(paymentInitiatesFail());
      onErorHandler(response);
    },
    failHandlerType: 'CUSTOM',
    baseUrl: REACT_APP_END_POINT_STRIPE_URL,
    payload: apiPayload,
    apiType: 'post',
  });
}
export function* createTempOrderSaga({ payload }) {
  const { temporderdata, onSuccessHandler, promocodeId, onErorHandler } = payload;
  yield put(createTempOrderStart());
  yield apiHandler({
    endpoint: `user/v1/order/temp_order`,
    successHandler: yield function* (response) {
      sessionStorage.setItem('chipTempOrderId', JSON.stringify(response?.data?.chipOrderId));
      yield put(createTempOrderSuccess(response?.data));
      yield onSuccessHandler();
    },
    failHandler: yield function* (response) {
      yield put(createTempOrderFail());
      yield onErorHandler(response);
      yield handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: REACT_APP_END_POINT_USER_URL,
    payload: { payload: temporderdata, promocodeId },
    apiType: 'post',
  });
}
function* watcher() {
  yield takeLatest(actionLabels.ADD_CARD_DETAILS, addCardDetailsSaga);
  yield takeLatest(actionLabels.GET_CARD_DETAILS, getCardDetailsSaga);
  yield takeLatest(actionLabels.DELETE_CARD_DETAILS, deleteCardDetailsSaga);
  yield takeLatest(actionLabels.PAYMENT_INITIATES, paymentInitiatesSaga);
  yield takeLatest(actionLabels.CREATE_TEMP_ORDER, createTempOrderSaga);
}
export default watcher;
