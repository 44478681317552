import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLoading: {
    getVisitorsList: false,
    getVisitorsDetails: false,
    getVisitorsSiteList: false,
  },
  isWidgetOpen: false,
  visitorsListData: null,
  visitorsDetailsData: null,
  visitorsSiteListData: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // // reducer for get visitor list
    case actionLabels.GET_ALL_VISITORS_LIST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getVisitorsList: true,
        },
        visitorsListData: null,
      };
    case actionLabels?.SET_WIDGET_INFOOPEN:
      return {
        ...state,
        isWidgetOpen: payload,
      };
    case actionLabels.GET_ALL_VISITORS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getVisitorsList: false,
        },
        visitorsListData: payload,
      };
    case actionLabels.GET_ALL_VISITORS_LIST_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getVisitorsList: false,
        },
        visitorsListData: null,
      };

    // // reducer for get visitor details
    case actionLabels.GET_VISITORS_DETAILS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getVisitorsDetails: true,
        },
        visitorsDetailsData: null,
      };
    case actionLabels.GET_VISITORS_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getVisitorsDetails: false,
        },
        visitorsDetailsData: payload,
      };
    case actionLabels.GET_VISITORS_DETAILS_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getVisitorsDetails: false,
        },
        visitorsDetailsData: null,
      };

    // // reducer for get visitors site list
    case actionLabels.GET_VISITORS_SITE_LIST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getVisitorsSiteList: true,
        },
        visitorsSiteListData: null,
      };
    case actionLabels.GET_VISITORS_SITE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getVisitorsSiteList: false,
        },
        visitorsSiteListData: payload,
      };
    case actionLabels.GET_VISITORS_SITE_LIST_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getVisitorsSiteList: false,
        },
        visitorsSiteListData: null,
      };

    default:
      return state;
  }
};
