import React from 'react';

const ThreeDViewer = ({ url = '' }) => (
  <model-viewer
    src={url}
    alt="GLB Model"
    camera-controls
    camera-orbit="0deg 30deg 300%"
    min-camera-orbit="auto auto 100%"
    max-camera-orbit="auto auto 500%"

    key={url}
    style={{ height: '100%', width: '100%' }}
  />
);

export default React.memo(ThreeDViewer);
