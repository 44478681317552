import { put, takeLatest } from 'redux-saga/effects';
import { REACT_APP_END_POINT_USER_URL, REACT_APP_END_POINT_STRIPE_URL } from 'utils/envConfig';
import { handleToastfunction } from 'utils/helpers';
import {
  getSubscriptionChargeStart,
  getSubscriptionChargeSuccess,
  getSubscriptionChargeFail,
  cancelSubscriptionStart,
  cancelSubscriptionSuccess,
  cancelSubscriptionFail,
  reactiveSubscriptionStart,
  reactiveSubscriptionSuccess,
  reactiveSubscriptionFail,
} from '../../actions';
import apiHandler from '../../../utils/apiHandler';
import * as actionLabels from '../../actionLabels';

export function* getSubscriptionChargeSaga({ payload }) {
  const { onSuccessHandler, apiPayload } = payload;
  yield put(getSubscriptionChargeStart());
  yield apiHandler({
    endpoint: `payments/v1/calculateSubscriptionCharge`,
    successHandler: yield function* (response) {
      yield put(getSubscriptionChargeSuccess(response?.data));
      yield onSuccessHandler();
    },
    failHandler: yield function* (response) {
      yield put(getSubscriptionChargeFail());
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    payload: { ...apiPayload },
    baseUrl: REACT_APP_END_POINT_STRIPE_URL,
    apiType: 'post',
  });
}

export function* cancelSubscriptionSaga({ payload }) {
  const { siteDisable, onSuccessHandler } = payload;
  yield put(cancelSubscriptionStart());
  yield apiHandler({
    endpoint: `user/v1/subscription`,
    successHandler: yield function* () {
      yield put(cancelSubscriptionSuccess());
      // toast.success(response.message);
      yield onSuccessHandler();
    },
    failHandler: yield function* (response) {
      yield put(cancelSubscriptionFail());
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: REACT_APP_END_POINT_USER_URL,
    apiType: 'put',
    payload: { siteDisable },
  });
}

export function* reactiveSubscriptionSaga({ payload }) {
  const { onSuccessHandler } = payload;
  yield put(reactiveSubscriptionStart());
  yield apiHandler({
    endpoint: `user/v1/subscription/reactive`,
    successHandler: yield function* (response) {
      yield put(reactiveSubscriptionSuccess());
      handleToastfunction({ status: 'success', title: response.message || 'success' });
      yield onSuccessHandler();
    },
    failHandler: yield function* (response) {
      yield put(reactiveSubscriptionFail());
      handleToastfunction({ status: 'error', title: response });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: REACT_APP_END_POINT_USER_URL,
    apiType: 'put',
    // payload: { apiPayload },
  });
}

function* watcher() {
  yield takeLatest(actionLabels.GET_SUBSCRIPTION_CHARGE, getSubscriptionChargeSaga);
  yield takeLatest(actionLabels.CANCEL_SUBSCRIPTION, cancelSubscriptionSaga);
  yield takeLatest(actionLabels.REACTIVE_SUBSCRIPTION, reactiveSubscriptionSaga);
}
export default watcher;
