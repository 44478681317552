/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import '../style/slider.scss';
import use3Dmodelcontext from 'Context/3DModelContext/use3Dmodelcontext';
import { useSelector } from 'react-redux';
import { Slider } from '@mui/material';
import styled from '@emotion/styled';

const ScaleSlider = ({ chipkey }) => {
  const { setScaleFactor, ScaleFactor } = use3Dmodelcontext();
  const { chipState } = useSelector(state => state.chips);
  const handlecomplete = value => {
    localStorage.setItem(
      'chipState',
      JSON.stringify({
        ...chipState,
        packValue: {
          ...chipState.packValue,
          [`${chipkey}`]: { ...chipState?.packValue[chipkey], scalefactor: ScaleFactor },
        },
      }),
    );
    // dispatch(getChipState());
  };
  const PrettoSlider = styled(Slider)({
    color: '#000',
    height: 8,
    width: 300,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid blue',
    },
  });

  return (
    <div className="scaleslider">
      <div>
        <p className="mb-0 text-center mt-3">Slide to change the scale of the logo</p>
        {/* <Slider
          min={0}
          max={2}
          step={0.1}
          tooltip={false}
          value={ScaleFactor}
          onChange={v => setScaleFactor(v)}
          onChangeComplete={handlecomplete}
        /> */}
        <Slider
          // valueLabelDisplay="auto"
          min={0}
          max={2}
          sx={{
            color: '#000',
            height: 8,
            width: 300,
            '& .MuiSlider-track': {
              border: 'none',
            },
            '& .MuiSlider-thumb': {
              height: 24,
              width: 24,
              backgroundColor: '#fff',
              border: '2px solid blue',
            },
          }}
          step={0.1}
          value={ScaleFactor}
          onChange={(e, v) => setScaleFactor(v)}
          onChangeComplete={handlecomplete}
        />
      </div>
    </div>
  );
};

export default ScaleSlider;
