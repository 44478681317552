import { put, takeLatest } from 'redux-saga/effects';
import { REACT_APP_END_POINT_AUTHURL, REACT_APP_END_POINT_USER_URL } from 'utils/envConfig';
import { handleToastfunction } from 'utils/helpers';
import {
  loginFail,
  loginStart,
  otpVerifySuccess,
  getpresignedUrlsuccess,
  getpresignedUrlfail,
  resetAuthenticatorsuccess,
  forgotAuthenticatorsuccess,
  forgotAuthenticatorfail,
  resetAuthenticatorfail,
  updateUserprofileSuccess,
  updateusersubAction,
} from '../../actions';
import apiHandler from '../../../utils/apiHandler';
import * as actionLabels from '../../actionLabels';

export function* otpVerifySaga() {
  // yield put(loginStart());
  yield put(otpVerifySuccess());
}

export function* authenticationValidatorSaga() {
  yield put(loginStart());
  const token = yield localStorage.getItem('authToken');
  if (!token) {
    yield put(loginFail(''));
    // yield put(logout()); // logout action
  } else {
    // yield put(loginSuccess({ token }));
  }
}

export function* getpresignedUrlSaga(action) {
  const { payload } = action;
  try {
    yield apiHandler({
      endpoint: `user/v1/profile/get-signed-url?fileName=${payload.fileName}&extension=${payload.extension}`,
      successHandler: yield function* (response) {
        console.log(response);
        payload.onpresignedsuccess(response.data.signedUrl);
        yield put(getpresignedUrlsuccess({ data: response.data }));
      },
      failHandler: getpresignedUrlfail,
      failHandlerType: 'CUSTOM',
      apiType: 'get',
      baseUrl: REACT_APP_END_POINT_USER_URL,
    });
  } catch (err) {
    console.log(err);
  }
}

export function* resetAuthenticatorSaga(action) {
  const { payload } = action;
  // second call
  try {
    yield apiHandler({
      endpoint: `/api/v1/code-verification/verify`,
      successHandler: yield function* (response) {
        payload.handleSuccess('success', '');
        yield put(resetAuthenticatorsuccess({ data: response.data }));
      },
      failHandler: yield function* (error) {
        payload.handleSuccess('error', error);
        yield put(resetAuthenticatorfail(error));
      },
      payload: {
        code: payload.emailOTP,
        _codeVerification: payload.email,
      },
      apiType: 'post',
      failHandlerType: 'CUSTOM',
      baseUrl: REACT_APP_END_POINT_AUTHURL,
    });
  } catch (err) {
    console.log(err);
  }
}
export function* forgotAuthenticatorSaga(action) {
  // first call
  const { payload } = action;
  try {
    yield apiHandler({
      endpoint: `/api/v1/code-verification/request`,
      successHandler: yield function* (response) {
        sessionStorage.setItem('userid', response?.data?.items[0]?.codeVerification?._id);
        yield put(forgotAuthenticatorsuccess({ data: response.data }));
      },
      failHandler: yield function* (error) {
        yield put(forgotAuthenticatorfail(error));
        handleToastfunction({ status: 'error', title: error });
      },
      payload: {
        email: payload.email,
        purpose: 'REMOVE_2FA_REQUEST',
        via: 'code',
      },
      apiType: 'post',
      failHandlerType: 'CUSTOM',
      baseUrl: REACT_APP_END_POINT_AUTHURL,
    });
  } catch (err) {
    console.log(err);
  }
}
export function* updateprofilesaga(action) {
  // first call
  const { payload } = action;
  try {
    yield apiHandler({
      endpoint: `user/v1/profile/update-profile`,
      successHandler: yield function* (response) {
        payload.handleSucces('success');
        yield put(updateUserprofileSuccess({ data: response.data }));
      },
      failHandler: yield function* (error) {
        yield put(forgotAuthenticatorfail(error));
      },
      payload,
      apiType: 'put',
      failHandlerType: 'CUSTOM',
      baseUrl: REACT_APP_END_POINT_USER_URL,
    });
  } catch (err) {
    console.log(err);
  }
}

export function* setUserdataSaga(action) {
  try {
    const encrypteddata = JSON.parse(localStorage.getItem('userData'));
    //  crypto.subtle.importKey('jwk', JSON.parse(sessionStorage.getItem('userId')), {
    //   name: 'AES-GCM',
    // }, true, ['encrypt', 'decrypt']).then( (key) => {
    //   decryptData(key, base64ToArrayBuffer(encrypteddata?.encryptedData), stringToUint8Array(encrypteddata.iv)).then( (decodedata)=> {
    //     action.payload.handleSuccess(JSON.parse(decodedata))
    //   }).catch((error) => {
    //     console.log(error);
    //   });
    // }).catch((erro) => {
    //   console.log(erro);
    // });
    yield action.payload.handleSuccess(encrypteddata);
  } catch (error) {
    console.log(error);
  }
}

export function* getsubsaga() {
  try {
    yield apiHandler({
      endpoint: '/user/v1/profile/subscription-details',
      successHandler: yield function* (response) {
        localStorage.setItem(
          'userData',
          JSON.stringify({
            ...JSON.parse(localStorage.getItem('userData')),
            _id: response?.data?.userDetails?.id,
          }),
        );
        yield put(updateusersubAction({ data: response }));
      },
      failHandler: yield function* (error) {
        yield put(forgotAuthenticatorfail(error));
      },
      apiType: 'get',
      failHandlerType: 'CUSTOM',
      baseUrl: REACT_APP_END_POINT_USER_URL,
    });
  } catch (error) {
    console.log(error);
  }
}

function* watcher() {
  yield takeLatest(actionLabels.OTP_VERIFY, otpVerifySaga);
  yield takeLatest(actionLabels.AUTHENTICATION_VALIDATOR, authenticationValidatorSaga);
  yield takeLatest(actionLabels.GET_PRESIGNED_URL, getpresignedUrlSaga);
  yield takeLatest(actionLabels.RESET_AUTHENTICATOR, resetAuthenticatorSaga);
  yield takeLatest(actionLabels.SET_USER_DATA, setUserdataSaga);
  yield takeLatest(actionLabels.FORGOT_AUTHENTICATOR, forgotAuthenticatorSaga);
  yield takeLatest(actionLabels.UPDATE_USER_PROFILE, updateprofilesaga);
  yield takeLatest(actionLabels.GET_USER_SUBS, getsubsaga);
}
export default watcher;
