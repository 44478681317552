import React from 'react';
import './Spinner.scss';
import { BLACK_LOADER_GIF, GREY_BG_LOADER, WHITE_LOADER_GIF } from 'assets/images';
import { useSelector } from 'react-redux';
import { REACT_APP_SLLABSIMAGEURL_STATIC } from 'utils/envConfig';

const Loader = props => {
  const { islayoutloader } = props;
  const { Theme } = useSelector(state => state.auth);
  return (
    <div className={`loader ${islayoutloader ? 'islayoutloader' : ''} `}>
      <div className="loaderwrapper">
        <img
          src={`${REACT_APP_SLLABSIMAGEURL_STATIC}${GREY_BG_LOADER}`}
          alt="test"
          className="greyportion inline-block"
        />
        <img
          src={
            Theme === 'dark'
              ? `${REACT_APP_SLLABSIMAGEURL_STATIC}${WHITE_LOADER_GIF}`
              : `${REACT_APP_SLLABSIMAGEURL_STATIC}${BLACK_LOADER_GIF}`
          }
          alt="test"
          className="topportion inline-block"
        />
      </div>
    </div>
  );
};

export default Loader;
