/* eslint-disable no-unused-vars */
/* eslint-disable no-multi-assign */
import React, { useEffect, useRef, useState } from 'react';
import { OrbitControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { useSelector, useDispatch } from 'react-redux';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { easing } from 'maath';
import use3Dmodelcontext from 'Context/3DModelContext/use3Dmodelcontext';
import { setCameraposition } from 'store/actions';

const THREEdModelExportPoc = ({ selectedModal }) => {
  const { chipColor, rotation } = useSelector(state => state.chips);
  const { modeltexture, ScaleFactor } = use3Dmodelcontext();
  const { materials, scene } = useGLTF(selectedModal);
  const dispatch = useDispatch();
  const logoTexture = modeltexture?.logoDecal
    ? useLoader(THREE.TextureLoader, modeltexture?.logoDecal)
    : '';
  useFrame((state, delta) => easing.dampC(materials.chip.color, chipColor, 0.25, delta));
  const { camera } = useThree();
  const stateString = JSON.stringify({ ...use3Dmodelcontext(), chipColor });
  useEffect(() => {
    if (logoTexture) {
      const originalWidth = logoTexture.image.width; // Width of the original texture
      const originalHeight = logoTexture.image.height; // Height of the original texture
      const originalAspectRatio = originalWidth / originalHeight;
      const scale = {
        x: ScaleFactor, // Adjust as needed
        y: ScaleFactor / originalAspectRatio, // Calculate y-scale to maintain aspect ratio
      };
      logoTexture.wrapS = THREE.ClampToEdgeWrapping;
      logoTexture.wrapT = THREE.ClampToEdgeWrapping;
      logoTexture.repeat.set(1 / scale.x, 1 / scale.y);
      const offsetX = 0.5 - 0.5 / scale.x;
      const offsetY = 0.5 - 0.5 / scale.y;
      logoTexture.offset.set(offsetX, offsetY);
      logoTexture.flipY = true;
      logoTexture.anisotropy = 0;
      logoTexture.magFilter = THREE.NearestFilter;
      logoTexture.minFilter = THREE.NearestFilter;
      logoTexture.needsUpdate = true;
    }
  }, [logoTexture, ScaleFactor]);

  useEffect(() => {
    if (materials) {
      if (!logoTexture) {
        materials.logo.visible = false;
      } else {
        materials.logo.visible = true;
        materials.logo.map = logoTexture;
        materials.logo.map.encoding = THREE.sRGBEncoding;
        materials.logo.transparent = true;
        materials.logo.side = THREE.DoubleSide;
        materials.logo.opacity = 1;
        materials.chip.castShadow = true;
        materials.chip.receiveShadow = true;
        materials.logo.roughness = 0.2; // Adjust the roughness property
        materials.logo.metalness = 0.8;
        materials.logo.needsUpdate = true;
      }
    }
  }, [logoTexture, materials, selectedModal]);
  const modelRef = useRef();
  const handleControlsChange = () => {
    const { x, y, z } = camera.position;
    dispatch(setCameraposition({ x, y, z }));
  };
  return (
    <>
      <OrbitControls
        enablePan={false}
        onChange={handleControlsChange}
        enableZoom
        enableDamping
        dampingFactor={0.2}
        rotateSpeed={0.5}
      />
      <primitive
        scale={[0.03, 0.03, 0.03]}
        rotation={[0, 0, 0]}
        key={stateString}
        object={scene.clone()}
        ref={modelRef}
      />
    </>
  );
};

export const DefaultModel = ({ selectedModal, isDragging }) => {
  const { rotation } = useSelector(state => state.chips);
  const { scene } = useGLTF(selectedModal);
  const dispatch = useDispatch();
  const { camera } = useThree();
  // Create a ref to access the model's position
  const modelRef = useRef();

  const handleControlsChange = () => {
    const { x, y, z } = camera.position;
    dispatch(setCameraposition({ x, y, z }));
  };
  return (
    <>
      <OrbitControls
        enablePan={false}
        onChange={handleControlsChange}
        enableZoom
        enableDamping
        dampingFactor={0.2}
        rotateSpeed={0.5}
      />{' '}
      <primitive
        ref={modelRef}
        scale={[0.03, 0.03, 0.03]}
        rotation={[rotation.x, rotation.y, rotation.z]}
        object={scene}
      />
    </>
  );
};

export default React.memo(THREEdModelExportPoc);
