import * as actionLabels from '../../actionLabels';

// // action for get all cms
export const getCmsDataStart = () => ({
  type: actionLabels.GET_CMS_DATA_START,
});
export const getCmsData = payload => ({
  type: actionLabels.GET_CMS_DATA,
  payload,
});
export const getCmsDataSuccess = payload => ({
  type: actionLabels.GET_CMS_DATA_SUCCESS,
  payload,
});
export const getCmsDataFail = payload => ({
  type: actionLabels.GET_CMS_DATA_FAIL,
  payload,
});

// // action for social media link
export const getSocialMediaLinkStart = () => ({
  type: actionLabels.GET_SOCIAL_MEDIA_LINK_START,
});
export const getSocialMediaLink = payload => ({
  type: actionLabels.GET_SOCIAL_MEDIA_LINK,
  payload,
});
export const getSocialMediaLinkSuccess = payload => ({
  type: actionLabels.GET_SOCIAL_MEDIA_LINK_SUCCESS,
  payload,
});
export const getSocialMediaLinkFail = payload => ({
  type: actionLabels.GET_SOCIAL_MEDIA_LINK_FAIL,
  payload,
});
