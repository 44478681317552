import * as actionLabels from '../../../actionLabels';

// // action for get overall country geography google analysis lst
export const getOverallGeographyCountryGAListStart = () => ({
  type: actionLabels.GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST_START,
});
export const getOverallGeographyCountryGAList = payload => ({
  type: actionLabels.GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST,
  payload,
});
export const getOverallGeographyCountryGAListSuccess = payload => ({
  type: actionLabels.GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST_SUCCESS,
  payload,
});
export const getOverallGeographyCountryGAListFail = payload => ({
  type: actionLabels.GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST_FAIL,
  payload,
});

// // action for get overall region geography google analysis lst
export const getOverallRegionGeographyGAListStart = () => ({
  type: actionLabels.GET_REGION_OVERALL_GEOGRAPHY_GA_LIST_START,
});
export const getOverallRegionGeographyGAList = payload => ({
  type: actionLabels.GET_REGION_OVERALL_GEOGRAPHY_GA_LIST,
  payload,
});
export const getOverallRegionGeographyGAListSuccess = payload => ({
  type: actionLabels.GET_REGION_OVERALL_GEOGRAPHY_GA_LIST_SUCCESS,
  payload,
});
export const getOverallRegionGeographyGAListFail = payload => ({
  type: actionLabels.GET_REGION_OVERALL_GEOGRAPHY_GA_LIST_FAIL,
  payload,
});

// // action for google analysis geography country map
export const getOverallGaCountryGeographyMapStart = () => ({
  type: actionLabels.GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP_START,
});
export const getOverallGaCountryGeographyMap = payload => ({
  type: actionLabels.GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP,
  payload,
});
export const getOverallGaCountryGeographyMapSuccess = payload => ({
  type: actionLabels.GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP_SUCCESS,
  payload,
});
export const getOverallGaCountryGeographyMapFail = payload => ({
  type: actionLabels.GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP_FALL,
  payload,
});
