import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

export const { REACT_APP_END_POINT_USER_URL } = process.env;
export const { REACT_APP_END_POINT_AUTHURL } = process.env;
export const { REACT_APP_END_POINT_STRIPE_URL } = process.env;
export const { REACT_APP_SLLABSIMAGEURL } = process.env;
export const { REACT_APP_SLLABSIMAGEURL_STATIC } = process.env;
export const { REACT_APP_SLLABSIMAGEUSERURL } = process.env;
export const { REACT_APP_AWS_USER_POOL } = process.env;
export const { REACT_APP_AWS_USER_POOL_WEB_ID } = process.env;
export const { REACT_APP_AWS_COGNITO_REGION } = process.env;
export const { REACT_APP_EDITORURL } = process.env;
export const { REACT_APP_END_POINT_ADMIN_ACCESS_CODE } = process.env;
export const { REACT_APP_ZENDESK } = process.env;
export const CHIP_DEFAULTCOLOR = 'rgb(17, 17, 17)';
export const CAMX = -1.7136548176685036e-8;
export const CAMY = 1.0386575092130337;
export const CAMZ = 0.0000010385161337995335;
export const { REACT_APP_GOOGLEMAPS_API } = process.env;
export const { REACT_APP_LOGIN_VIDEO } = process.env;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MESUREMENTID,
};
// 0.00000580082018568249 0.724881413481479 0.02559879740557711

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth();

export { storage, auth };
