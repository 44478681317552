import moment from 'moment';
import { formateDate } from 'utils/helpers';
import * as actionLabels from '../../../actionLabels';

export const initialState = {
  isLoading: {
    getTrafficGa: false,
    getTrafficLastMonthGa: false,
    getCountryGeographyList: false,
    getRegionGeographyList: false,
    getCountryGeographyMap: true,
  },
  trafficGaData: null,
  trafficGaLastMonthData: null,
  countryGeographyData: null,
  regionGeographyData: null,
  countryGeographyMapData: null,
  globalDatefilter: {
    value: 'allTime',
    label: 'All Time',
    date: {
      startDate: moment().subtract(8, 'years').startOf('year').toISOString(),
      endDate: formateDate(new Date(), 'tonight'),
    },
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // // reducer for get traffic GA data
    case actionLabels.SET_GLOBAL_DATE_FILTER:
      return {
        ...state,
        globalDatefilter: payload,
      };
    case actionLabels.GET_TRAFFIC_GA_DATA:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getTrafficGa: true,
        },
        trafficGaData: null,
      };
    case actionLabels.GET_TRAFFIC_GA_DATA_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getTrafficGa: false,
        },
        trafficGaData: payload,
      };
    case actionLabels.GET_TRAFFIC_GA_DATA_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getTrafficGa: false,
        },
        trafficGaData: null,
      };

    // // reducer for get traffic last month's GA data
    case actionLabels.GET_TRAFFIC_LAST_MONTH_GA_DATA:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getTrafficLastMonthGa: true,
        },
        trafficGaLastMonthData: null,
      };
    case actionLabels.GET_TRAFFIC_LAST_MONTH_GA_DATA_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getTrafficLastMonthGa: false,
        },
        trafficGaLastMonthData: payload,
      };
    case actionLabels.GET_TRAFFIC_LAST_MONTH_GA_DATA_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getTrafficLastMonthGa: false,
        },
        trafficGaLastMonthData: null,
      };

    // // reducer for get country geography GA list
    case actionLabels.GET_GEOGRAPHY_COUNTRY_GA_LIST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCountryGeographyList: true,
        },
        countryGeographyData: null,
      };
    case actionLabels.GET_GEOGRAPHY_COUNTRY_GA_LIST_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCountryGeographyList: false,
        },
        countryGeographyData: payload,
      };
    case actionLabels.GET_GEOGRAPHY_COUNTRY_GA_LIST_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCountryGeographyList: false,
        },
        countryGeographyData: null,
      };

    // // reducer for get region geography GA list
    case actionLabels.GET_REGION_GEOGRAPHY_GA_LIST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getRegionGeographyList: true,
        },
        regionGeographyData: null,
      };
    case actionLabels.GET_REGION_GEOGRAPHY_GA_LIST_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getRegionGeographyList: false,
        },
        regionGeographyData: payload,
      };
    case actionLabels.GET_REGION_GEOGRAPHY_GA_LIST_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getRegionGeographyList: false,
        },
        regionGeographyData: null,
      };

    // // reducer for get google analysis geography country map
    case actionLabels.GET_GA_GEOGRAPHY_COUNTRY_MAP:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCountryGeographyMap: true,
        },
        countryGeographyMapData: null,
      };
    case actionLabels.GET_GA_GEOGRAPHY_COUNTRY_MAP_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCountryGeographyMap: false,
        },
        countryGeographyMapData: payload,
      };
    case actionLabels.GET_GA_GEOGRAPHY_COUNTRY_MAP_FALL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCountryGeographyMap: false,
        },
        countryGeographyMapData: null,
      };

    default:
      return state;
  }
};
