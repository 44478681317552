import * as actionLabels from '../../actionLabels';

export const loginStart = () => ({
  type: actionLabels.LOGIN_START,
});

export const login = payload => ({
  type: actionLabels.LOGIN_SAGA,
  payload,
});
export const setUserForOtpVerify = payload => ({
  type: actionLabels.SET_USER_OTP_VERIFY,
  payload,
});
export const setUserData = payload => ({
  type: actionLabels.SET_USER_DATA,
  payload,
});
export const setUserDataSuccess = payload => ({
  type: actionLabels.SET_USER_DATA_SUCCESS,
  payload,
});

export const getUserSubscriptionDetails = payload => ({
  type: actionLabels.GET_USER_SUBS,
  payload,
});

export const loginSuccess = payload => ({
  type: actionLabels.LOGIN_SUCCESS,
  payload,
});

export const updateusersubAction = payload => ({
  type: actionLabels.UPDATE_USER_SUB,
  payload,
});

export const loginFail = payload => ({
  type: actionLabels.LOGIN_FAIL,
  payload,
});

export const signupSuccess = payload => ({
  type: actionLabels.SIGNUP_SAGA_SUCCESS,
  payload,
});

export const signupFail = payload => ({
  type: actionLabels.SIGNUP_SAGA_FAIL,
  payload,
});

export const authenticationValidator = () => ({
  type: actionLabels.AUTHENTICATION_VALIDATOR,
});

export const clearAuth = () => ({
  type: actionLabels.CLEAR_AUTH,
});

export const otpVerify = payload => ({
  type: actionLabels.OTP_VERIFY,
  payload,
});

export const otpVerifySuccess = () => ({
  type: actionLabels.OTP_VERIFY_SUCCESS,
});

export const getpresignedUrl = payload => ({
  type: actionLabels.GET_PRESIGNED_URL,
  payload,
});

export const getpresignedUrlsuccess = payload => ({
  type: actionLabels.GET_PRESIGNED_URL_SUCCESS,
  payload,
});

export const getpresignedUrlfail = payload => ({
  type: actionLabels.GET_PRESIGNED_URL_FAIL,
  payload,
});

export const setTheme = payload => ({
  type: actionLabels.SET_THEME,
  payload,
});

export const resetAuthenticator = payload => ({
  type: actionLabels.RESET_AUTHENTICATOR,
  payload,
});

export const resetAuthenticatorsuccess = payload => ({
  type: actionLabels.RESET_AUTHENTICATOR_SUCCESS,
  payload,
});

export const resetAuthenticatorfail = payload => ({
  type: actionLabels.RESET_AUTHENTICATOR_FAIL,
  payload,
});

export const forgotAuthenticator = payload => ({
  type: actionLabels.FORGOT_AUTHENTICATOR,
  payload,
});
export const forgotAuthenticatorsuccess = payload => ({
  type: actionLabels.FORGOT_AUTHENTICATOR_SUCCESS,
  payload,
});
export const forgotAuthenticatorfail = payload => ({
  type: actionLabels.FORGOT_AUTHENTICATOR_FAIL,
  payload,
});
export const updateUserprofile = payload => ({
  type: actionLabels.UPDATE_USER_PROFILE,
  payload,
});
export const updateUserprofileSuccess = payload => ({
  type: actionLabels.UPDATE_USER_PROFILE_SUCCESS,
  payload,
});

export const resetAuthenticatorValue = payload => ({
  type: actionLabels.RESETAUTHENTICAR_VALUE,
  payload,
});
