/* eslint-disable prefer-template */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable  guard-for-in */
/* eslint-disable no-unused-vars */
/* eslint-disable  no-restricted-syntax */
import moment from 'moment';
import ToastComponent from 'components/toastComponent';
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';
import { logoutUser } from 'apiservices/api';
import { toasterclose } from 'assets/images';
import { setTheme } from 'store/actions';
import { REACT_APP_SLLABSIMAGEURL_STATIC } from './envConfig';

export const downloadCanvasToImage = callback => {
  // Create an image element from the dataURL
  const canvas = document.querySelector('canvas');
  const dataURL = canvas?.toDataURL();
  const originalImage = new Image();
  originalImage.src = dataURL;
  // Wait for the image to load
  originalImage.onload = function () {
    // Create a temporary canvas to analyze the image
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');

    console.log(originalImage);
    // Set the canvas dimensions to match the image
    tempCanvas.width = originalImage.width;
    tempCanvas.height = originalImage.height;

    // Draw the original image onto the temporary canvas
    tempCtx.drawImage(originalImage, 0, 0);

    // Get the image data
    const imageData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
    const { data } = imageData;

    // Find the bounding box of non-transparent pixels
    let minX = tempCanvas.width;
    let minY = tempCanvas.height;
    let maxX = 0;
    let maxY = 0;

    for (let y = 0; y < tempCanvas.height; y++) {
      for (let x = 0; x < tempCanvas.width; x++) {
        const alpha = data[(y * tempCanvas.width + x) * 4 + 3];

        if (alpha > 0) {
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, y);
        }
      }
    }

    // Calculate the dimensions of the cropped region
    const cropWidth = maxX - minX + 1;
    const cropHeight = maxY - minY + 1;

    // Create a canvas for the cropped and background-removed image
    const resultCanvas = document.createElement('canvas');
    resultCanvas.width = cropWidth;
    resultCanvas.height = cropHeight;
    const ctx = resultCanvas.getContext('2d');

    // Draw the cropped content onto the new canvas
    ctx.drawImage(tempCanvas, minX, minY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

    // Convert the canvas content to a data URL (PNG format)
    const croppedAndBackgroundRemovedDataURL = resultCanvas?.toDataURL('image/png');

    // Pass the data URI to the callback function
    callback(croppedAndBackgroundRemovedDataURL.split(',')[1]);
  };
};
export function formatTimeinseconds(seconds) {
  if (seconds < 60) {
    return Math.round(seconds) + ' sec.';
  }
  const minutes = seconds / 60;
  return minutes.toFixed(1) + ' min.';
}

export const formateDate = (date, timeday) => {
  // Get the components of the date
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so add 1
  const day = date.getDate().toString().padStart(2, '0');
  return timeday === 'tonight'
    ? `${year}-${month}-${day}T23:59:59.000Z`
    : `${year}-${month}-${day}T00:00:00.000Z`;
};
export async function addPaddingToImage(imageBuffer, padding) {
  const image = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Load the image from the buffer
  image.src = `data:image/png;base64,${imageBuffer.toString('base64')}`;
  await new Promise(resolve => (image.onload = resolve));

  // Calculate the new dimensions
  const newWidth = image.width + 2 * padding;
  const newHeight = image.height + 2 * padding;

  canvas.width = newWidth;
  canvas.height = newHeight;

  // Draw the white padding
  ctx.fillStyle = 'rgba(255, 255, 255,0)';
  ctx.fillRect(0, 0, newWidth, newHeight);

  // Draw the image with padding
  ctx.drawImage(image, padding, padding);

  const croppedAndBackgroundRemovedDataURl = canvas.toDataURL('image/png');
  return croppedAndBackgroundRemovedDataURl.split(',')[1];
}
export const reader = file =>
  new Promise(async resolve => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const imageBuffer = Buffer.from(fileReader.result.split(',')[1], 'base64');
      const imageWithPadding = await addPaddingToImage(imageBuffer, 5);
      const dataURL = `data:image/png;base64,${imageWithPadding}`;
      resolve(dataURL);
    };
    fileReader.readAsDataURL(file);
  });
export const handelGAData = response => {
  const result = response?.data?.rows.map(row => {
    const countryObject = {};
    row.dimensionValues.forEach((dimensionValue, index) => {
      const dimensionName = response?.data?.dimensionHeaders[index]?.name;
      countryObject[dimensionName] = dimensionValue?.value;
    });

    row.metricValues.forEach((metricValue, index) => {
      const metricName = response?.data?.metricHeaders[index]?.name;
      countryObject[metricName] = metricValue?.value;
    });

    return countryObject;
  });
  const finalData = { list: result, count: response?.data?.rowCount };
  return finalData;
};
export const handelGAMapData = response => {
  const convertedData = {};
  response?.data.rows.forEach(row => {
    const countryIdIndex = response?.data.dimensionHeaders.findIndex(
      header => header.name === 'countryId',
    );

    row.metricValues.forEach((metric, index) => {
      const metricHeader = response?.data.metricHeaders[index];
      const metricName = metricHeader.name;
      if (countryIdIndex !== -1) {
        const countryId = row.dimensionValues[countryIdIndex].value;
        const metricValue = metric.value;

        convertedData[countryId] = {
          [metricName]:
            metricName === 'userEngagementDuration'
              ? moment.duration(metricValue, 'seconds').format('HH[hr]:mm[m]:ss[s]')
              : metricValue,
        };
      }
    });
  });
  return convertedData;
};

export const handelOverallGAData = response => {
  const aggregatedData = {};

  response.forEach(report => {
    report.rows.forEach(row => {
      const dimensions = {};
      row.dimensionValues.forEach(
        (dimension, index) => (dimensions[report.dimensionHeaders[index].name] = dimension.value),
      );

      const metrics = {};
      row.metricValues.forEach(
        (metric, index) => (metrics[report.metricHeaders[index].name] = Number(metric.value)),
      );

      const key = JSON.stringify(dimensions);

      if (!aggregatedData[key]) {
        aggregatedData[key] = metrics;
      } else {
        for (const metricName in metrics) {
          if (aggregatedData[key][metricName]) {
            aggregatedData[key][metricName] += metrics[metricName];
          } else {
            aggregatedData[key][metricName] = metrics[metricName];
          }
        }
      }
    });
  });

  const resultArray = [];

  for (const key in aggregatedData) {
    const resultObject = JSON.parse(key);
    for (const metricName in aggregatedData[key]) {
      resultObject[metricName] = aggregatedData[key][metricName];
    }
    resultArray.push(resultObject);
  }
  const totalCount = response.map(data => data.rowCount).reduce((acc, cur) => acc + cur);
  const finalData = { list: resultArray, count: totalCount };
  return finalData;
};

export const handelOverallCountryMapData = response => {
  const aggregatedData = {};

  response.forEach(dataset => {
    dataset.rows.forEach(row => {
      const countryIdIndex = dataset.dimensionHeaders.findIndex(
        header => header.name === 'countryId',
      );
      if (countryIdIndex !== -1) {
        const countryCode = row.dimensionValues[countryIdIndex].value;

        if (!aggregatedData[countryCode]) {
          aggregatedData[countryCode] = {};
        }

        row.metricValues.forEach((metric, index) => {
          const metricName = dataset.metricHeaders[index].name;
          const metricValue = parseFloat(metric.value);

          if (!aggregatedData[countryCode][metricName]) {
            aggregatedData[countryCode][metricName] =
              metricName === 'userEngagementDuration'
                ? moment.duration(metricValue, 'seconds').format('HH[hr]:mm[m]:ss[s]')
                : metricValue;
          } else {
            aggregatedData[countryCode][metricName] +=
              metricName === 'userEngagementDuration'
                ? moment.duration(metricValue, 'seconds').format('HH[hr]:mm[m]:ss[s]')
                : metricValue;
          }
        });
      }
    });
  });
  return aggregatedData;
};

export const concatString = (characters, string) => {
  if (string?.length >= characters) {
    return `${string.substring(0, characters)}...`;
  }
  return string;
};

export const convertddmmyyy = date =>
  new Date(date).toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

export const convertcurrency = amt => {
  const formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amt);
  return formatted;
};
export const getthePriceofRangeChip = (quantity, range, price) => {
  let _price = price;
  let minprice = { minquant: Number.MAX_VALUE, minobj: {} };
  for (let i = 0; i < range?.length; i++) {
    const element = range[i];
    if (!quantity) {
      if (element.startQuantity <= minprice?.minquant) {
        minprice = { minquant: element?.startQuantity, minobj: element };
      }
    }
    if (quantity > 0 && quantity <= element?.endQuantity && quantity >= element?.startQuantity) {
      _price = element?.pricePerChip;
      break;
    }
  }
  if (!quantity && !range.length) {
    return _price;
  }
  return !quantity ? minprice?.minobj?.pricePerChip : _price;
};
export const GlobalLogout = async (Theme, check) => {
  try {
    if (!check) {
      await logoutUser({
        signoutType: 'ALL_SESSIONS',
        sessionIdentifier: localStorage.getItem('sessionidentifier'),
      });
    }
  } finally {
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('userTheme', Theme);
    localStorage.setItem('theme', 'dark');
    window.history.pushState('', '', `/signin`);
    window.location.reload();
  }
};
export const monthObj = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
export const converttoMMDDYY = (dateinp, type, plusone) => {
  const date = new Date(dateinp);
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  const _date = date.getUTCDate();
  const day = _date < 10 ? `0${_date}` : _date;
  if (type) {
    if (plusone) {
      // const currentDate = moment();
      // currentDate.add(1, 'days');
      return `${moment(dateinp).add(1, 'days').format('Do')} ${
        monthObj[date.getMonth() + 1]
      }, ${year}`;
    }
    return `${moment(day).format('Do')} ${monthObj[date.getMonth() + 1]}, ${year}`;
  }
  return `${month}-${day}-${year}`;
};

export const handelLocalDateTime = dateStr => {
  const date = new Date(dateStr);
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = convertddmmyyy(date);
  return formattedDate;
};
const CloseButton = ({ closeToast }) => (
  <div className="align-items-start justify-content-start wrapper">
    <img
      src={`${REACT_APP_SLLABSIMAGEURL_STATIC}${toasterclose}`}
      alt="test"
      onClick={closeToast}
      className="cp toastcloseimg"
    />
  </div>
);
export const handleToastfunction = ({ status, title, duration, subTitle, action = false }) => {
  toast(<ToastComponent status={status} title={title} subTitle={subTitle} action={action} />, {
    position: 'top-right',
    autoClose: duration || 3000,
    hideProgressBar: true,
    closeOnClick: true,
    closeButton: <CloseButton />,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};

export const ChangeThemeLight = dispatch => {
  localStorage.setItem('userTheme', 'light');
  localStorage.setItem('theme', 'light');
  dispatch(setTheme(localStorage.getItem('theme')));
};

export const ChangeThemeDark = dispatch => {
  localStorage.setItem('userTheme', 'dark');
  localStorage.setItem('theme', 'dark');
  dispatch(setTheme(localStorage.getItem('theme')));
};
