// // // get subscription charge label
export const GET_SUBSCRIPTION_CHARGE_START = 'GET_SUBSCRIPTION_CHARGE_START';
export const GET_SUBSCRIPTION_CHARGE = 'GET_SUBSCRIPTION_CHARGE';
export const GET_SUBSCRIPTION_CHARGE_SUCCESS = 'GET_SUBSCRIPTION_CHARGE_SUCCESS';
export const GET_SUBSCRIPTION_CHARGE_FAIL = 'GET_SUBSCRIPTION_CHARGE_FAIL';

// // // cancel subscription action label
export const CANCEL_SUBSCRIPTION_START = 'CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';

// // // reactive subscription action label
export const REACTIVE_SUBSCRIPTION_START = 'REACTIVE_SUBSCRIPTION_START';
export const REACTIVE_SUBSCRIPTION = 'REACTIVE_SUBSCRIPTION';
export const REACTIVE_SUBSCRIPTION_SUCCESS = 'REACTIVE_SUBSCRIPTION_SUCCESS';
export const REACTIVE_SUBSCRIPTION_FAIL = 'REACTIVE_SUBSCRIPTION_FAIL';

export const SET_WIDGET_INFOOPEN = 'SET_WIDGET_INFOOPEN';
