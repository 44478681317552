import {
  ADD_CARD_DETAILS_START,
  ADD_CARD_DETAILS,
  ADD_CARD_DETAILS_SUCCESS,
  ADD_CARD_DETAILS_FAIL,
  GET_CARD_DETAILS_START,
  GET_CARD_DETAILS,
  GET_CARD_DETAILS_SUCCESS,
  GET_CARD_DETAILS_FAIL,
  DELETE_CARD_DETAILS_START,
  DELETE_CARD_DETAILS,
  SET_DUMMY_SUBDETAIL,
  DELETE_CARD_DETAILS_SUCCESS,
  DELETE_CARD_DETAILS_FAIL,
  PAYMENT_INITIATES_START,
  PAYMENT_INITIATES,
  PAYMENT_INITIATES_SUCCESS,
  PAYMENT_INITIATES_FAIL,
  CREATE_TEMP_ORDER_START,
  CREATE_TEMP_ORDER,
  CREATE_TEMP_ORDER_SUCCESS,
  CREATE_TEMP_ORDER_FAIL,
  SET_CARD_TOTAL,
  STOP_LOADING_PAYMENT,
  SET_CHIP_TOTAL,
  SET_SUB_TOTAL,
  SET_SAVED_CARD,
  SET_PRORATED_TOTAL,
  PAYMENT_INITIATES_FALSE,
  PUBLISHING_LOADING,
  PUBLISHING_LOADING_FALSE,
  SETCARTACTIVETEST,
  PAYMENT_PROCESS_ONGOING,
  SET_ORDERSMODAL_OPEN,
  SET_FINAL_PRORATED,
  SET_FINAL_SUBTOTAL,
} from '../../actionLabels';

// // action for add card details
export const addCardDetailsStart = () => ({
  type: ADD_CARD_DETAILS_START,
});
export const setOrderModalsOpen = payload => ({
  type: SET_ORDERSMODAL_OPEN,
  payload,
});

export const addCardDetails = payload => ({
  type: ADD_CARD_DETAILS,
  payload,
});
export const paymentStopsloading = payload => ({
  type: STOP_LOADING_PAYMENT,
  payload,
});

export const addCardDetailsSuccess = payload => ({
  type: ADD_CARD_DETAILS_SUCCESS,
  payload,
});
export const addCardDetailsFail = payload => ({
  type: ADD_CARD_DETAILS_FAIL,
  payload,
});

// // action for get card details
export const getCardDetailsStart = () => ({
  type: GET_CARD_DETAILS_START,
});
export const getCardDetails = payload => ({
  type: GET_CARD_DETAILS,
  payload,
});
export const getCardDetailsSuccess = payload => ({
  type: GET_CARD_DETAILS_SUCCESS,
  payload,
});
export const getCardDetailsFail = payload => ({
  type: GET_CARD_DETAILS_FAIL,
  payload,
});

// // action for delete card details
export const deleteCardDetailsStart = () => ({
  type: DELETE_CARD_DETAILS_START,
});
export const deleteCardDetails = payload => ({
  type: DELETE_CARD_DETAILS,
  payload,
});
export const deleteCardDetailsSuccess = payload => ({
  type: DELETE_CARD_DETAILS_SUCCESS,
  payload,
});
export const deleteCardDetailsFail = payload => ({
  type: DELETE_CARD_DETAILS_FAIL,
  payload,
});

// // action for payment initiates
export const paymentInitiatesStart = () => ({
  type: PAYMENT_INITIATES_START,
});
export const paymentInitiates = payload => ({
  type: PAYMENT_INITIATES,
  payload,
});
export const paymentInitiatesSuccess = payload => ({
  type: PAYMENT_INITIATES_SUCCESS,
  payload,
});
export const paymentInitiatesFail = payload => ({
  type: PAYMENT_INITIATES_FAIL,
  payload,
});
export const paymentInitiatesfalse = payload => ({
  type: PAYMENT_INITIATES_FALSE,
  payload,
});
export const setIsPaymentProgress = payload => ({
  type: PAYMENT_PROCESS_ONGOING,
  payload,
});

// // action for temp order
export const createTempOrderStart = () => ({
  type: CREATE_TEMP_ORDER_START,
});
export const createTempOrder = payload => ({
  type: CREATE_TEMP_ORDER,
  payload,
});
export const createTempOrderSuccess = payload => ({
  type: CREATE_TEMP_ORDER_SUCCESS,
  payload,
});
export const createTempOrderFail = payload => ({
  type: CREATE_TEMP_ORDER_FAIL,
  payload,
});
export const setDummySubInfo = payload => ({
  type: SET_DUMMY_SUBDETAIL,
  payload,
});

export const setFinalProratedAmount = payload => ({
  type: SET_FINAL_PRORATED,
  payload,
});
export const setSubTotalAmount = payload => ({
  type: SET_FINAL_SUBTOTAL,
  payload,
});

export const setCardTotal = payload => ({
  type: SET_CARD_TOTAL,
  payload,
});
export const setPublishingloader = payload => ({
  type: PUBLISHING_LOADING,
  payload,
});
export const setActivateprogress = payload => ({
  type: SETCARTACTIVETEST,
  payload,
});

export const setPublishingloaderfalse = payload => ({
  type: PUBLISHING_LOADING_FALSE,
  payload,
});

export const setsubprice = payload => ({
  type: SET_SUB_TOTAL,
  payload,
});

export const setchipprice = payload => ({
  type: SET_CHIP_TOTAL,
  payload,
});

export const setPaymentThrouhSavedCard = payload => ({
  type: SET_SAVED_CARD,
  payload,
});
export const setProratedTotal = payload => ({
  type: SET_PRORATED_TOTAL,
  payload,
});
