import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REACT_APP_END_POINT_USER_URL, REACT_APP_ZENDESK } from 'utils/envConfig';
import { handleToastfunction } from 'utils/helpers';
import {
  applyPromoCodeFail,
  applyPromoCodeSuccess,
  getArticlessuccess,
  setIsManualPayment,
  setPromocodeValue,
} from '../../actions/index';
import * as actionLabels from '../../actionLabels';
import apiHandler from '../../../utils/apiHandler';

export function* applyPromocodeSaga(action) {
  try {
    yield put(setPromocodeValue(action.payload.code));
    yield apiHandler({
      endpoint: `user/v1/promocode/apply`,
      successHandler: yield function* (response) {
        handleToastfunction({
          status: 'success',
          title: `Applied Promocode for discount of ${response?.data?.promoDiscount.toFixed(2)}%`,
        });
        yield put(
          setIsManualPayment(parseFloat(action.payload.amount, 10) - response?.data?.discount),
        );
        yield put(applyPromoCodeSuccess({ data: response?.data }));
      },
      failHandler: yield function* (response) {
        handleToastfunction({ status: 'error', title: response });
        yield put(applyPromoCodeFail(response));
        yield put(setPromocodeValue(''));
      },
      failHandlerType: 'CUSTOM',
      apiType: 'post',
      payload: { ...action.payload },
      baseUrl: REACT_APP_END_POINT_USER_URL,
    });
  } catch (err) {
    console.log(err);
  }
}
const getdata = async () => {
  const { data } = await axios.get(`${REACT_APP_ZENDESK}help_center/en-us/articles.json`);
  return data;
};
export function* getArticleSaga() {
  try {
    const data = yield call(getdata);
    yield put(getArticlessuccess(data));
  } catch (err) {
    console.log(err);
  }
}

function* watcher() {
  yield takeLatest(actionLabels.APPLY_PROMO_CODE_START, applyPromocodeSaga);
  yield takeLatest(actionLabels.GET_ARTICLES, getArticleSaga);
}
export default watcher;
