import * as actionLabels from '../../../actionLabels';

// // set site view layout action
export const setSiteViewLayout = payload => ({
  type: actionLabels.SET_SITE_VIEW_LAYOUT,
  payload,
});

// // action for add sites
export const addSiteStart = () => ({
  type: actionLabels.ADD_SITE_START,
});
export const addSite = payload => ({
  type: actionLabels.ADD_SITE,
  payload,
});
export const addSiteSuccess = payload => ({
  type: actionLabels.ADD_SITE_SUCCESS,
  payload,
});
export const addSiteFail = payload => ({
  type: actionLabels.ADD_SITE_FAIL,
  payload,
});

// // action for delete sites
export const deleteSiteStart = () => ({
  type: actionLabels.DELETE_SITE_START,
});
export const deleteSite = payload => ({
  type: actionLabels.DELETE_SITE,
  payload,
});
export const deleteSiteSuccess = payload => ({
  type: actionLabels.DELETE_SITE_SUCCESS,
  payload,
});
export const deleteSiteFail = payload => ({
  type: actionLabels.DELETE_SITE_FAIL,
  payload,
});

// // action for enable and disable sites
export const enableDisableSiteStart = () => ({
  type: actionLabels.ENABLE_DISABLE_SITE_START,
});
export const enableDisableSite = payload => ({
  type: actionLabels.ENABLE_DISABLE_SITE,
  payload,
});
export const enableDisableSiteSuccess = payload => ({
  type: actionLabels.ENABLE_DISABLE_SITE_SUCCESS,
  payload,
});
export const enableDisableSiteFail = payload => ({
  type: actionLabels.ENABLE_DISABLE_SITE_FAIL,
  payload,
});
export const setViewSiteData = (payload) => ({
  type: actionLabels.VIEW_SITE_DATA,
  payload
})
