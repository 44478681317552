import React from 'react';
import { useSelector } from 'react-redux'; // Importing useSelector hook
import { smallSlLabLogodark, smallSlLabLogo } from 'assets/images'; // Importing images
import { REACT_APP_SLLABSIMAGEURL_STATIC } from 'utils/envConfig'; // Importing environment configuration
import './restrict.scss'; // Importing styles

const RestrictUser = () => {
  const { Theme } = useSelector(state => state.auth); // Getting theme from Redux store

  return (
    <div className="wrapper restrickwrapper">
      {' '}
      {/* Main wrapper */}
      <div className="rerstrictinfo wrapper flex-column gap-3">
        {' '}
        {/* Restrict info wrapper */}
        {/* Rendering image based on theme */}
        <img
          className="restrictimg"
          alt="restrictimg"
          src={
            Theme === 'dark'
              ? `${REACT_APP_SLLABSIMAGEURL_STATIC}${smallSlLabLogodark}` // Dark theme image
              : `${REACT_APP_SLLABSIMAGEURL_STATIC}${smallSlLabLogo}` // Light theme image
          }
        />
        {/* Info paragraph */}
        <p className="restrictpara">
          To access Continuity with all its features,
          <br /> please switch to a computer.
        </p>
      </div>
    </div>
  );
};

export default RestrictUser;
