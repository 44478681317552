export const RESET_APP = 'RESET_APP';
export const LOGIN_SAGA = 'LOGIN_SAGA';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SAGA = 'SIGNUP_SAGA';
export const SIGNUP_SAGA_SUCCESS = 'SIGNUP_SAGA_SUCCESS';
export const SIGNUP_SAGA_FAIL = 'SIGNUP_SAGA_FAIL';
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const OTP_VERIFY = 'OTP_VERIFY';
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS';
export const AUTHENTICATION_VALIDATOR = 'AUTHENTICATION_VALIDATOR';
export const SET_USER_OTP_VERIFY = 'SET_USER_OTP_VERIFY';
export const GET_PRESIGNED_URL = 'GET_PRESIGNED_URL';
export const GET_PRESIGNED_URL_SUCCESS = 'GET_PRESIGNED_URL_SUCCESS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_DATA_SUCCESS = 'SET_USER_DATA_SUCCESS';
export const GET_PRESIGNED_URL_FAIL = 'GET_PRESIGNED_URL_FAIL';
export const RESET_AUTHENTICATOR = 'RESET_AUTHENTICATOR';
export const RESET_AUTHENTICATOR_SUCCESS = 'RESET_AUTHENTICATOR_SUCCESS';
export const RESET_AUTHENTICATOR_FAIL = 'RESET_AUTHENTICATOR_FAIL';
export const FORGOT_AUTHENTICATOR = 'FORGOT_AUTHENTICATOR';
export const FORGOT_AUTHENTICATOR_SUCCESS = 'FORGOT_AUTHENTICATOR_SUCCESS';
export const FORGOT_AUTHENTICATOR_FAIL = 'FORGOT_AUTHENTICATOR_FAIL';
export const SET_THEME = 'SET_THEME';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_SUB = 'UPDATE_USER_SUB';
export const GET_USER_SUBS = 'GET_USER_SUBS';
export const GET_USER_SUBS_SUCCESS = 'GET_USER_SUBS_SUCCESS';
export const GET_USER_SUBS_FAIL = 'GET_USER_SUBS_FAIL';
export const RESETAUTHENTICAR_VALUE = 'RESETAUTHENTICAR_VALUE';
