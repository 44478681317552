import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLogin: false,
  isLoading: false,
  articles: null,
  userData: null,
  promocodedata: null,
  promocodevalue: '',
  authToken: '',
  errorMsg: '',
  fcmToken: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.APPLY_PROMO_CODE_START:
      return { ...state, isLoading: true, isLogin: false };
    case actionLabels.LOGIN_SUCCESS: {
      return {
        ...state,
        isLogin: true,
        isLoading: false,
        authToken: payload.token,
        // fcmToken: payload.deviceToken,
        userData: payload,
        errorMsg: '',
      };
    }
    case actionLabels.SET_PROMOCODE_VALUE:
      return { ...state, promocodevalue: payload };
    case actionLabels.REMOVE_PROMOCODE:
      return { ...state, promocodedata: null, promocodevalue: null };
    case actionLabels.GET_ARTICLES_SUCCESS: {
      return { ...state, articles: payload };
    }
    case actionLabels.APPLY_PROMO_CODE_SUCCESS: {
      return { ...state, isLoading: false, promocodedata: payload };
    }
    case actionLabels.APPLY_PROMO_CODE_FAIL: {
      return { ...state, isLoading: false, errorMsg: '' };
    }
    default:
      return state;
  }
};
