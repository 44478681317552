import { put, takeLatest } from 'redux-saga/effects';
import { handleToastfunction } from 'utils/helpers';
import {
  deleteSiteStart,
  deleteSiteSuccess,
  deleteSiteFail,
  addSiteStart,
  addSiteSuccess,
  addSiteFail,
  enableDisableSiteStart,
  enableDisableSiteSuccess,
  enableDisableSiteFail,
} from '../../../actions';
import apiHandler from '../../../../utils/apiHandler';
import * as actionLabels from '../../../actionLabels';

export function* addSiteSaga({ payload }) {
  yield put(addSiteStart());
  const { values, onSuccessHandler } = payload;
  yield apiHandler({
    endpoint: `user/v1/site`,
    successHandler: yield function* (response) {
      yield put(addSiteSuccess(response));
      handleToastfunction({ status: 'success', title: response?.message || 'success' });
      yield onSuccessHandler(response);
    },
    failHandler: yield function* (response) {
      yield put(addSiteFail());
      handleToastfunction({ status: 'error', title: response || 'error' });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    apiType: 'post',
    payload: values,
  });
}

export function* deleteSiteSaga({ payload }) {
  const { currentId, onSuccessHandler } = payload;
  yield put(deleteSiteStart());
  yield apiHandler({
    endpoint: `user/v1/site/${currentId}`,
    successHandler: yield function* (response) {
      yield put(deleteSiteSuccess());
      handleToastfunction({ status: 'success', title: response?.message || 'success' });
      yield onSuccessHandler();
    },
    failHandler: yield function* (response) {
      yield put(deleteSiteFail());
      handleToastfunction({ status: 'error', title: response || 'error' });
    },
    failHandlerType: 'CUSTOM',
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    apiType: 'delete',
  });
}

export function* enableDisableSiteSaga({ payload }) {
  const { currentId, onSuccessHandler } = payload;
  yield put(enableDisableSiteStart());
  yield apiHandler({
    endpoint: `user/v1/site/disable_enable/${currentId}`,
    successHandler: yield function* (response) {
      yield put(enableDisableSiteSuccess());
      handleToastfunction({ status: 'success', title: response?.message || 'success' });
      yield onSuccessHandler();
    },
    failHandler: yield function* (response) {
      yield put(enableDisableSiteFail());
      handleToastfunction({ status: 'error', title: response || 'error' });
    },
    baseUrl: process.env.REACT_APP_END_POINT_USER_URL,
    failHandlerType: 'CUSTOM',
    apiType: 'put',
  });
}

function* watcher() {
  yield takeLatest(actionLabels.ADD_SITE, addSiteSaga);
  yield takeLatest(actionLabels.DELETE_SITE, deleteSiteSaga);
  yield takeLatest(actionLabels.ENABLE_DISABLE_SITE, enableDisableSiteSaga);
}
export default watcher;
