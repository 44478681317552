import axios from 'axios';
import {
  REACT_APP_END_POINT_USER_URL,
  REACT_APP_END_POINT_STRIPE_URL,
  REACT_APP_END_POINT_ADMIN_ACCESS_CODE,
  REACT_APP_END_POINT_AUTHURL,
} from 'utils/envConfig';
import axiosMain, { setBaseUrl } from 'http/axios/axios_main';
import generateURL from 'utils/generateUrl';

export const getDashboardSites = async payload => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  const { filterURL, searchURL } = payload;
  return axiosMain.get(
    `user/v1/dashboard/site-list?sortOrder=${
      filterURL === '&sort=siteName' ? 'ASC' : 'DESC'
    }&limit=16${filterURL || ''}${searchURL || ''}`,
  );
};
export const getMiocrosites = async payload => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  const { limit, skip, status = 'all' } = payload;
  return axiosMain.get(
    `user/v1/dashboard/site-list?sortOrder=DESC&limit=${limit}&skip=${skip}&status=${status}`,
  );
};
export const getChipsPackage = async () => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  return axiosMain.get(`user/v1/chip/list-chip-package?skip=0&limit=10`);
};

export const getSubscriptionuserdata = async () => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  return axiosMain.get('user/v1/profile/subscription-details');
};

export const getSubscriptionsdata = async () => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  return axiosMain.get(`user/v1/subscription`);
};

export const getUserProjects = async payload => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  const { filterUrl = '', searchUrl = '', limit = '5', skip = '0' } = payload;
  return axiosMain.get(
    `user/v1/site?sortOrder=DESC&limit=${limit}&skip=${skip}${filterUrl}${searchUrl}`,
  );
};

export const getMyListLogo = async () => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  return axiosMain.get('user/v1/chip/list-logo?skip=0&limit=10');
};

export const listMyCards = async () => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  return axiosMain.get('payments/v1/card');
};

export const deleteCard = async id => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  return axiosMain.put('/payments/v1/card', { cardId: id });
};

export const makedefaultCard = async id => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  return axiosMain.put('/payments/v1/addDefaultCard', { cardId: id });
};

export const addCarddetailmutation = async carddetail => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  return axiosMain.post('payments/v1/card', carddetail);
};

export const publishSite = async ({ siteid, versionNumber }) => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  return axiosMain.put('user/v1/site/publish', {
    versionNumber,
    siteId: siteid,
  });
};

export const viewChipsList = async ({ siteId }) => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  return axiosMain.get(`user/v1/site/connected_chip/${siteId}`);
};

export const getProratedFee = async ({ subscriptionId }) => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  return axiosMain.post('payments/v1/calculateSiteCharge', {
    subscriptionId,
  });
};

export const getBenifits = async ({ versionNumber, siteId, subscriptionId }) => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  return axiosMain.get(
    `user/v1/site/subscription_site_widgets/${versionNumber}/${siteId}/${subscriptionId}`,
  );
};

export const postChipsQuestions = async val => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  return axiosMain.post(`user/v1/question/save_question`, val);
};

export const getAllordersList = async payload => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  const { limit = '5', skip = '0' } = payload;
  return axiosMain.get(`/payments/v1/order?limit=${limit}&skip=${skip}`);
};

export const getOrderDetail = async id => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  // const { filterUrl = '', searchUrl = '', limit = '5', skip = '0' } = payload;
  return axiosMain.get(`/payments/v1/order/${id}`);
};

export const getTemplateList = async () => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  // const { filterUrl = '', searchUrl = '', limit = '5', skip = '0' } = payload;
  return axiosMain.get(`user/v1/site/template`);
};

export const updateBrand = async (val, token) => {
  setBaseUrl(REACT_APP_END_POINT_USER_URL);
  return axiosMain.put(`user/v1/profile/update-profile`, val, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getSignUpSetting = async () => {
  setBaseUrl(REACT_APP_END_POINT_ADMIN_ACCESS_CODE);
  return axiosMain.get(`auth/v1/getSettings`);
};

export const sendOtptoMobile = async mob => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.post(`/api/v1/code-verification/request`, mob);
};

export const verifyOtptoMobile = async data => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.post(`/api/v1/code-verification/verify`, data);
};

export const manualPaymentfunction = async payload => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  return axiosMain.post(`/payments/v1/create_manual_order`, payload);
};

export const availabilityCheckpreSignup = async data => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.get(
    `api/v1/auth/signup.availability-check?${generateURL(data)?.replace('&', '')}`,
  );
};

export const createUserAction = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.post(
    payload?.isgoogle ? '/api/v1/auth/social-signup' : `/api/v1/auth/signup`,
    payload,
  );
};

export const otpSignupRequest = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.post(`/api/v1/code-verification/request`, payload);
};

export const verifyotpSignupRequest = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.post(`/api/v1/code-verification/verify`, payload);
};

export const LoginUserAction = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.post(`/api/v1/auth/signin`, payload);
};

export const setMFArequest = async payload =>
  axios.patch(`${REACT_APP_END_POINT_AUTHURL}api/v1/me/settings`, payload, {
    headers: {
      Authorization: `Bearer ${
        localStorage.getItem('signintoken') || localStorage.getItem('authToken')
      }`,
    },
  });

export const setMFArequestOtp = async payload =>
  axios.patch(`${REACT_APP_END_POINT_AUTHURL}api/v1/me/settings`, payload, {
    headers: {
      Authorization: `Bearer ${
        localStorage.getItem('signintoken') || localStorage.getItem('authToken')
      }`,
    },
  });
export const setVerify2faOtpAction = async payload =>
  axios.post(`${REACT_APP_END_POINT_AUTHURL}api/v1/auth/authenticator-otp-verification`, payload, {
    // headers: {
    //   Authorization: `Bearer ${localStorage.getItem('signintoken')}`,
    // },
  });

export const verifyEmailAction = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.post(`/api/v1/code-verification/request`, payload);
};

export const resetPasswordAction = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.post(`/api/v1/auth/reset.forgot-password`, payload);
};

export const verifyforgotPasswordOtp = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.post(`/api/v1/code-verification/verify`, payload);
};

export const changePasswordAction = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.patch(`/api/v1/me/password`, payload);
};

export const updateUserAction = async payload =>
  axios.patch(`${REACT_APP_END_POINT_AUTHURL}api/v1/me/`, payload, {
    headers: {
      Authorization: `Bearer ${
        localStorage.getItem('signintoken') || localStorage.getItem('authToken')
      }`,
    },
  });

export const setBrandnameAction = async payload =>
  axios.patch(
    `${REACT_APP_END_POINT_AUTHURL}api/v1/me/set-brand-name`,
    { brandName: payload.brandName },
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    },
  );

export const resetMFAmutateAction = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.patch(`/api/v1/me/settings`, payload);
};

export const logoutUser = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.post('/api/v1/auth/signout', payload);
};
// address
export const listAddressQuery = async () => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  return axiosMain.get('/payments/v1/address/listAddress');
};

export const addAddressMutation = async addressDetails => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  return axiosMain.post('/payments/v1/address/saveAddress', addressDetails);
};

export const updateAddressMutation = async ({ id, addressDetails }) => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  return axiosMain.put(`/payments/v1/address/updateAddress/${id}`, addressDetails);
};

export const deleteAddressMutation = async id => {
  setBaseUrl(REACT_APP_END_POINT_STRIPE_URL);
  return axiosMain.delete(`/payments/v1/address/deleteAddress/${id}`);
};

export const getUserSocial = async () => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.get('/api/v1/me/');
};

export const removeSocialSignup = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.patch('/api/v1/me/remove-social-signup', payload);
};

export const AddNewPasswordAction = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.patch('/api/v1/me/set-password', payload);
};

export const connectGoogleAccountAction = async payload => {
  setBaseUrl(REACT_APP_END_POINT_AUTHURL);
  return axiosMain.patch('/api/v1/me/set-social-signup', payload);
};
