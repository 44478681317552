import {
  House,
  MegaphoneSimple,
  Users,
  CurrencyCircleDollar,
  Package,
} from '@phosphor-icons/react';

export const dataItems = [
  {
    name: 'Dashboard',
    link: '/dashboard',
    type: 'image',
    logo: <House weight="fill" color="inherit" size={16} />,
  },
  {
    name: 'Campaigns',
    link: '/site-management/sites',
    type: 'image',
    logo: <MegaphoneSimple weight="fill" color="inherit" size={16} />,
    otherActiveRoutes: ['buy', 'viewchip', 'chip-solutions'],
  },
  {
    name: 'Audience',
    link: '/visitor-management',
    type: 'image',
    logo: <Users weight="fill" color="inherit" size={16} />,
  },
  {
    name: 'Subscription',
    link: '/subscription',
    type: 'image',
    logo: <CurrencyCircleDollar weight="fill" color="inherit" size={16} />,
  },
  {
    name: 'Order History',
    link: '/myorders',
    type: 'image',
    logo: <Package weight="fill" color="inherit" size={16} />,
  },
];
