// // get geography country google analysis data action label
export const GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST_START =
  'GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST_START';
export const GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST = 'GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST';
export const GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST_SUCCESS =
  'GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST_SUCCESS';
export const GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST_FAIL =
  'GET_COUNTRY_OVERALL_GEOGRAPHY_GA_LIST_FAIL';

// // get geography region google analysis data action label
export const GET_REGION_OVERALL_GEOGRAPHY_GA_LIST_START =
  'GET_REGION_OVERALL_GEOGRAPHY_GA_LIST_START';
export const GET_REGION_OVERALL_GEOGRAPHY_GA_LIST = 'GET_REGION_OVERALL_GEOGRAPHY_GA_LIST';
export const GET_REGION_OVERALL_GEOGRAPHY_GA_LIST_SUCCESS =
  'GET_REGION_OVERALL_GEOGRAPHY_GA_LIST_SUCCESS';
export const GET_REGION_OVERALL_GEOGRAPHY_GA_LIST_FAIL =
  'GET_REGION_OVERALL_GEOGRAPHY_GA_LIST_FAIL';

// // action label google analysis geography country map
export const GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP_START =
  'GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP_START';
export const GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP = 'GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP';
export const GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP_SUCCESS =
  'GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP_SUCCESS';
export const GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP_FALL =
  'GET_OVERALL_GA_GEOGRAPHY_COUNTRY_MAP_FALL';
