// // add card details action label
export const ADD_CARD_DETAILS_START = 'ADD_CARD_DETAILS_START';
export const ADD_CARD_DETAILS = 'ADD_CARD_DETAILS';
export const ADD_CARD_DETAILS_SUCCESS = 'ADD_CARD_DETAILS_SUCCESS';
export const ADD_CARD_DETAILS_FAIL = 'ADD_CARD_DETAILS_FAIL';

// // get card action label
export const GET_CARD_DETAILS_START = 'GET_CARD_DETAILS_START';
export const GET_CARD_DETAILS = 'GET_CARD_DETAILS';
export const GET_CARD_DETAILS_SUCCESS = 'GET_CARD_DETAILS_SUCCESS';
export const GET_CARD_DETAILS_FAIL = 'GET_CARD_DETAILS_FAIL';

// // get card action label
export const DELETE_CARD_DETAILS_START = 'DELETE_CARD_DETAILS_START';
export const DELETE_CARD_DETAILS = 'DELETE_CARD_DETAILS';
export const DELETE_CARD_DETAILS_SUCCESS = 'DELETE_CARD_DETAILS_SUCCESS';
export const DELETE_CARD_DETAILS_FAIL = 'DELETE_CARD_DETAILS_FAIL';

// // card payment initiates action label
export const PAYMENT_INITIATES_START = 'PAYMENT_INITIATES_START';
export const PAYMENT_INITIATES = 'PAYMENT_INITIATES';
export const PAYMENT_INITIATES_SUCCESS = 'PAYMENT_INITIATES_SUCCESS';
export const PAYMENT_INITIATES_FAIL = 'PAYMENT_INITIATES_FAIL';

export const SET_FINAL_PRORATED = 'SET_FINAL_PRORATED';
export const SET_DUMMY_SUBDETAIL = 'SET_DUMMY_SUBDETAIL';
export const SET_FINAL_SUBTOTAL = 'SET_FINAL_SUBTOTAL';
// // temp order create  payment initiates action label
export const CREATE_TEMP_ORDER_START = 'CREATE_TEMP_ORDER_START';
export const CREATE_TEMP_ORDER = 'CREATE_TEMP_ORDER';
export const CREATE_TEMP_ORDER_SUCCESS = 'CREATE_TEMP_ORDER_SUCCESS';
export const CREATE_TEMP_ORDER_FAIL = 'CREATE_TEMP_ORDER_FAIL';

export const SET_CARD_TOTAL = 'SET_CARD_TOTAL';
export const STOP_LOADING_PAYMENT = 'STOP_LOADING_PAYMENT';
export const SET_SUB_TOTAL = 'SET_SUB_TOTAL';
export const SET_CHIP_TOTAL = 'SET_CHIP_TOTAL';

export const SET_SAVED_CARD = 'SET_SAVED_CARD';
export const SET_PRORATED_TOTAL = 'SET_PRORATED_TOTAL';
export const PAYMENT_INITIATES_FALSE = 'PAYMENT_INITIATES_FALSE';

// publish loading

export const PUBLISHING_LOADING = 'PUBLISHING_LOADING';
export const PUBLISHING_LOADING_FALSE = 'PUBLISHING_LOADING_FALSE';
export const SETCARTACTIVETEST = 'SETCARTACTIVETEST';

export const PAYMENT_PROCESS_ONGOING = 'PAYMENT_PROCESS_ONGOING';
export const SET_ORDERSMODAL_OPEN = 'SET_ORDERSMODAL_OPEN';
